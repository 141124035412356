.footer-container {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #4b3f67;
  color: #fff;
}

.footer-container .left-block {
  text-align: left;
}

.footer-container ul.footer-menu {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}

.footer-container .footer-menu li {
  margin-bottom: 8px;
}

.footer-container .footer-menu li:last-child {
  margin-bottom: 0;
}

.footer-container .footer-menu li button,
.footer-container .footer-menu li a {
  background: none;
  border: none;
  color: #ffffff;
  cursor: pointer;
  display: block;
  font-family: "Gotham";
  font-style: normal;
  font-size: 15px !important;
  font-weight: 400;
  line-height: 19px;
  padding: 5px 0 !important;
  text-decoration: none;
}

.footer-container .right-block {
  text-align: right;
}

/* .footer-container .right-block img.merz-logo, */
.footer-container .right-block img.acorda-logo {
  min-width: 165px;
}

.footer-container .right-block .share-icon {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 100px;
  display: flex;
  justify-content: flex-end;
}

.footer-container .right-block .share-icon li {
  display: inline-block;
  /* margin-right: 6px; */
}

.footer-container .right-block .share-icon li:last-child {
  margin-right: 0;
}

.footer-container .saperation {
  border-top: 1px solid #fff;
  margin-top: 30px;
  margin-bottom: 30px;
}

.footer-container .footer-bottom {
  position: relative;
}

.footer-container .footer-bottom p {
  margin-bottom: 0;
  font-style: normal;
  font-weight: 325;
  font-size: 12px;
  line-height: 110%;
  color: #ffffff;
  text-align: left;
  font-family: "Gotham-light";
}

.footer-container .footer-bottom .footer-bootom-text {
  padding-right: 10%;
}

.footer-container .footer-bottom .footer-bootom-text p.footer-code {
  line-height: 14px;
  letter-spacing: 0.01em;
  position: absolute;
  bottom: 0;
  right: 0;
}

.footer-container .footer-ext-logo {
  margin-right: 8px;
}

.error-msg {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  margin-top: 5px;
  color: #780000;
}

.thankyou-modal {
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 40px;
  padding-right: 40px;
}

.thankyou-modal h1 {
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 42px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 0px;
  color: #7c6991;
}

.thankyou-modal p {
  margin-top: 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 0px;
  text-align: center;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #4e4e4e;
}

.thankyou-modal button {
  background: #7c6991;
  border-radius: 100px;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  align-items: center;
  text-transform: uppercase;
  color: #ffffff;
  padding: 15px 40px 15px 40px;
  border: none;
  margin-top: 24px;
}

.thankyou-modal button:hover {
  background: #685778;
}

.modal-body .para-dark-font {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #4e4e4e;
  margin-bottom: 0;
}

.modal-body .mail-modal {
  padding: 24px;
}

.modal-body .para-normal-font {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #4e4e4e;
}

.modal-body .social-media-modal {
  padding: 34px 24px;
}

.modal-button-margin {
  margin-left: 35px;
  margin-top: 0px;
}

@media screen and (max-width: 767px) {
  .footer-container .row {
    flex-direction: column-reverse;
  }

  .footer-container .left-block,
  .footer-container .right-block {
    text-align: left;
  }

  .footer-container .right-block img.acorda-logo {
    max-width: 225px;
  }

  .footer-container .right-block .share-icon {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 31px;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 30px;
  }

  .footer-container .footer-menu li {
    margin-bottom: 0px;
  }

  .footer-container .footer-bottom .footer-bootom-text {
    padding-right: 0;
  }

  .footer-container .footer-bottom .footer-bootom-text p.footer-code {
    position: static;
    margin-top: 30px;
    text-align: right;
  }

  .thankyou-modal {
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 25px;
    padding-right: 25px;
  }

  .modal-buttons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    /* gap: 35px; */
  }

  .modal-button {
    width: 100%;
  }

  .modal-button-margin {
    margin-left: 0px;
    margin-top: 35px;
  }
}

.modal-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #7c6991;
}

.modal-heading-left {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: left;
  color: #7c6991;
}

.social-media-modal {
  padding-top: 50px;
  padding-bottom: 50px;
}

.acorda-modal {
  padding-top: 60px;
  padding-bottom: 60px;
}

.mail-modal {
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 40px;
  padding-right: 40px;
  text-align-last: left;
}

.email-input {
  padding: 12px 16px 12px 16px;
  border: 1px solid #7c6991;
  border-radius: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  width: 100%;
  margin-top: 5px;
  color: rgba(78, 78, 78, 0.6);
}

.error-input {
  padding: 12px 16px 12px 16px;
  border: 1px solid #780000 !important;
  border-radius: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  width: 100%;
  margin-top: 5px;
  color: #780000;
}

.error-input::placeholder {
  color: #780000;
}

.mail-text {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #4e4e4e;
  text-align: left;
  margin-top: 5px;
}

.mail-info-text {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #4e4e4e;
  text-align: left;
  margin-top: 15px;
}

.mail-info-text a {
  color: #4e4e4e !important;
}

.modal-button {
  background: #7c6991;
  border-radius: 100px;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #ffffff !important;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 40px;
  padding-right: 40px;
  text-decoration: none !important;
  border: none;
  justify-content: center;
}

.modal-button:hover {
  background-color: #685778;
}

.mt-08 {
  margin-top: 8px;
}

.ml-06 {
  margin-left: 6px;
}

.mt-10 {
  margin-top: 100px;
}

.mt-035 {
  margin-top: 35px;
}

.modal-buttons {
  display: flex;
  justify-content: center;
}