.site-map-page{

}
.site-map-page .hero-section{
padding: 83px 0px 83px 0px;
}

.site-map-page .heading{
    font-style: normal;
    font-weight: 300;
    font-size: 51px;
    line-height: 60px;
    color: #FFFFFF;
    text-align: left;
}

.site-map-page .site-map-cont {
    display: flex;
    gap: 76px;
    text-align: center;
}

.site-map-page .spacing{
    padding-bottom: 106px;
    margin-bottom: 77px;
    margin-top: 70px;
    border-bottom: 4px solid #EAE7F1;
}

.site-map-page .width {
    max-width: 369px;
    width: 100%;
    text-align: left;
}

.site-map-page .content {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    text-decoration-line: underline;
    text-decoration-skip-ink: none !important;
    color: #4B3F67 !important;
}

.site-map-page .margin{
    margin-top: 20px;
}

.site-map-page .site-link::marker {
    color: #4E4E4E;
}

@media (max-width:767px) {
    .site-map-page .site-map-cont {
        display: flex;
        gap: 0px;
        flex-wrap: wrap;
        text-align: center;
    }
}
