.navigation-bar {
    background: #685778;
    background-size: contain;
    height: 184px;
}

.navigation-bar .row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-width: 1500px;
    margin: auto !important;
    height: 100%;
}

.navigation-bar a {
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 124%;
    display: block;
    text-align: center;
    margin: auto;
    color: #fff;
    text-decoration: none;
}

.navigation-bar a:hover {
    font-weight: 700;
    color: #EAE7F1;
}

.homepage .navigation-bar a {
    max-width: 300px;
}

.what-are-off-periods .navigation-bar {
    margin-bottom: 50px;
}

.inbrija-stories-page .navigation-bar .col-md-6:first-child a {
    max-width: 280px;
}

.inbrija-stories-page .navigation-bar .col-md-6:last-child a {
    max-width: 300px;
}

.tools-and-resources .navigation-bar .col-md-6:first-child a {
    max-width: 400px;
}

.tools-and-resources .navigation-bar .col-md-6:last-child a {
    max-width: 300px;
}

.how-to-use-page .navigation-bar .col-md-6:first-child a {
    max-width: 300px;
}

.how-to-use-page .navigation-bar .col-md-6:last-child a {
    max-width: 340px;
}

.saving-and-support-page .navigation-bar .col-md-6:first-child a {
    max-width: 350px;
}

.people-on-inbrija .navigation-bar .col-md-6:last-child a {
    max-width: 350px;
}

@media (max-width:767px) {
    .navigation-bar {
        background: none;
        height: auto;

    }

    .navigation-bar .row .col-md-6:first-child {
        background: #4B3F67;
        height: 132px;
    }

    .navigation-bar .row .col-md-6:last-child {
        background: #685778;
        height: 132px;
    }

    .navigation-bar a {
        font-weight: 600;
        font-size: 24px;
        line-height: 30px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .what-are-off-periods .navigation-bar {
        margin: 0;
    }
}