@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

.App {
  text-align: center;
  font-family: "Raleway";
}

body {
  font-family: "Raleway";
  /* top: 0 !important; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.banner {
  background: linear-gradient(147.08deg, #090913 0%, #1b222a 100%);
}

a,
button {
  cursor: pointer !important;
}

.inf-spanish {
  width: calc(100% - 200px - 10px);
}

.inf-spanish-btn {
  cursor: pointer;
  font-weight: 700;
  display: inline-block;
  background: #7b6991;
  color: #fff;
  padding: 18px 84px;
  font-size: 20px;
  text-decoration: none;
  border-radius: 2px;
  margin-top: 24px;
  outline: 0;
  border: 0;
  transition: all 0.2s ease;
  text-decoration: none !important;
}

@font-face {
  font-family: "Cigarettes and Coffee";
  font-style: normal;
  font-weight: normal;
  src: local("Cigarettes and Coffee"), url("../public/font/Cigarettes and Coffee.ttf") format("truetype");
}

@font-face {
  font-family: "Gotham";
  font-style: normal;
  font-weight: normal;
  src: local("Gotham"), url("../public/font/Gotham-Medium.otf") format("woff");
}

@font-face {
  font-family: "Gotham-light";
  font-style: normal;
  font-weight: normal;
  src: local("Gotham"), url("../public/font/Gotham-Light.otf") format("woff");
}

@font-face {
  font-family: "Helvetica-Light";
  font-style: normal;
  font-weight: normal;
  src: url("../public/font/Helvetica-Light-Condensed.otf") format("opentype");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  src: local("Roboto"), url("../public/font/Roboto-Regular.ttf") format("truetype");
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.br-mobile {
  display: none;
}

.hidden-mobile {
  display: block;
}

.hidden-desktop {
  display: none;
}

.nowrap {
  white-space: nowrap;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.bold {
  font-weight: bold;
}

.p-0 {
  padding: 0px;
}

.flex-justify-left {
  display: flex;
}

.flex-justify-center {
  display: flex;
  justify-content: space-between;
}

.flex-justify-spacebetween {
  display: flex;
  justify-content: space-between;
}

.pointer {
  cursor: pointer;
}

.center {
  text-align: center;
  text-align: -webkit-center;
}

.left-navigation-bar {
  background: #4b3f67;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 124%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  color: #fff;
  height: 184px;
}

.modal-close {
  align-self: end;
  cursor: pointer;
  position: absolute;
  right: -15px;
  top: -15px;
  z-index: 99;
}

.menu-bar-sticky-desktop {
  display: block;
}

.menu-bar-sticky-mobile {
  display: none;
}

.about-inb-modal {
  width: none !important;
}

.about-inbrija-padding {
  padding: 0px !important;
}

.video-modal-padding {
  padding-left: 55px !important;
  padding-right: 55px !important;
  padding-top: 40px !important;
}

.external-modal-padding {
  padding-left: 55px !important;
  padding-right: 55px !important;
}

.external-modal-width {
  max-width: 780px !important;
  width: 100% !important;
}

.superscript {
  vertical-align: super;
  font-size: smaller;
}

.right-navigation-bar {
  background: #685778;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 124%;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  height: 184px;
}

.nav-bar-links {
  text-decoration: none;
  text-align: center;
  color: #fff;
  max-width: 450px;
  width: 100%;
}

.nav-bar-links:hover {
  color: #fff;
  max-width: 100%;
}

.ddg-ques {
  color: rgb(0.24313725490196078, 0.08627450980392157, 0.2784313725490196);
  font-family: "Helvetica-Light" !important;
  font-size: 21px;
  letter-spacing: 0.02em;
  text-align: left;
  line-height: 30px;
  width: 800px;
}

.flex {
  display: flex;
}

.date-inputs {
  display: flex;
  justify-content: center;
}

.date-inputs .about-input-2 {
  width: 60px !important;
}

.mobile-header-sticky {
  z-index: 0 !important;
}

@media screen and (max-width: 767px) {
  .nav-bar-links {
    text-decoration: none;
    width: 100%;
    text-align: center;
    color: #fff;
  }

  .left-navigation-bar {
    background: #4b3f67;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 124%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    color: #fff;
    height: 132px;
  }

  .right-navigation-bar {
    background: #685778;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 124%;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
    height: 132px;
  }
}

@media screen and (max-width: 575px) {
  .navigation-bar {
    display: block;
    flex-wrap: nowrap;
  }

  input[type="date"]:before {
    font-size: 14px;
  }
}

.br-desktop {
  display: block;
}

.br-overscreen {
  display: block;
}

@media (max-width: 768px) {
  .menu-bar-sticky-desktop {
    display: none;
  }

  .menu-bar-sticky-mobile {
    display: block;
  }
}

@media (max-width: 767px) {
  .br-desktop {
    display: none;
  }

  .br-mobile {
    display: block;
  }

  .hidden-mobile {
    display: none;
  }

  .hidden-desktop {
    display: block;
  }
}

.pl-0 {
  padding-left: 0px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.max-width-1344 {
  max-width: 1344px !important;
  width: 100% !important;
}

.mt-30 {
  margin-top: 30px !important;
}

input[type="checkbox"]:checked {
  accent-color: #7c6991;
}

input[type="radio"]:checked {
  accent-color: #7c6991;
}

@media (min-width: 1400px) {
  .container-1344 {
    max-width: 1344px !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
    margin: auto;
  }

  .container-1500 {
    max-width: 1500px !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
    margin: auto;
    display: block;
    width: 100%;
  }

  .container {
    max-width: 1130px !important;
    padding-right: 20px !important;
    padding-left: 20px !important;
    margin: 0;
    display: block;
  }

  .container-1440 {
    max-width: 1440px !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
    margin: auto;
    display: block;
  }
}

@media (min-width: 1200px) and (max-width: 1440px) {
  .container-1440 {
    max-width: 100% !important;
    padding-right: 20px !important;
    padding-left: 20px !important;
    display: block;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .container {
    max-width: 1130px !important;
    padding-right: 20px !important;
    padding-left: 20px !important;
    margin: 0;
    display: block;
  }

  .container-1500 {
    max-width: 100% !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
    margin: auto;
    display: block;
  }

  .container-1344 {
    max-width: 100% !important;
    padding-right: 20px !important;
    padding-left: 20px !important;
    display: block;
  }

  .br-overscreen {
    display: none;
  }
}

@media (max-width: 1199px) {
  .container,
  .container-1344 {
    max-width: 1130px !important;
    padding-right: 20px !important;
    padding-left: 20px !important;
    margin: 0 auto;
    width: 100%;
    display: block;
  }

  .container-1500 {
    max-width: 100% !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
    margin: auto;
    display: block;
  }

  .container-1440 {
    max-width: 100% !important;
    padding-right: 20px !important;
    padding-left: 20px !important;
    display: block;
  }

  #onetrust-banner-sdk .ot-sdk-row,
  #onetrust-pc-sdk .ot-sdk-row,
  #ot-sdk-cookie-policy .ot-sdk-row {
    margin: 0 !important;
    max-width: none !important;
    display: block !important;
  }

  .br-overscreen {
    display: none;
  }
}

.show_desktop {
  display: block;
}

.show_mobile {
  display: none;
}

li.show_desktop {
  display: list-item;
}

@media (max-width: 900px) and (min-width: 767px) {
  .company-logo {
    max-width: 200px !important;
    max-height: 102px !important;
    height: 100%;
  }
}

@media (max-width: 767px) {
  .show_desktop {
    display: none;
  }

  .show_mobile {
    display: block;
  }

  li.show_mobile {
    display: list-item;
  }

  li.show_desktop {
    display: none;
  }

  .video-modal {
    width: 250px !important;
    max-width: auto !important;
    align-self: center;
  }

  .video-modal .video-width {
    max-width: 250px !important;
    width: 100% !important;
    margin: auto !important;
  }

  .video-modal .video-modal-alignment {
    place-self: center !important;
    padding: auto !important;
  }
}

.video-modal {
  width: 100% !important;
  max-width: 923.82px !important;
  align-self: center;
  margin: auto !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.video-modal .video-width {
  max-width: 800px !important;
  width: 100% !important;
  margin: auto !important;
}

.video-modal .video-modal-alignment {
  align-self: center !important;
  padding: 40px 0px 34px 0px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.video-modal .video-instructions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.video-modal .video-instructions a {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-decoration: underline !important;
  color: #4b3f67 !important;
  text-decoration-skip-ink: none !important;
  margin-top: 8px;
}

.brush-style.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  display: block;
  height: 54px;
}

.brush-style-2.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  background-image: url("/public/assets/images/sticky-header-background.png") !important;
  z-index: 1001;
  background-size: round !important;
  display: block;
  height: 70px;
  padding-top: 13px;
}

.brush-style-mob {
  background-repeat: round;
}

.acorda-link-modal {
  width: 100% !important;
  max-width: 727px !important;
  text-align: center;
  margin: auto !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.email-modal {
  width: 100% !important;
  max-width: 608px !important;
  text-align: left;
  margin: auto !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.brush-style {
  position: relative;
  z-index: 9;
}

.submenu-style .active-menu,
.submenu-style .non-active-menu {
  cursor: pointer;
}

.submenu-style .active-menu {
  font-weight: 700;
  color: #262c34 !important;
}

.submenu-style {
  height: 53.93px;
  color: #000 !important;
  text-align: left;
  max-width: 1230px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  /* gap: 40px; */
  padding-top: 11px;
}

.submenu-style-not-active {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #262c34 !important;
  cursor: pointer;
  margin-right: 40px;
}

.submenu-style-not-active:hover {
  font-weight: 600;
}

.submenu-style-active {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #000000 !important;
  cursor: pointer;
  margin-right: 40px;
}

.modal-content {
  border-radius: 17px !important;
  border: none !important;
}

.modal-close {
  right: -11px;
  top: -11px;
}

.text-decoration-none {
  text-decoration: none !important;
}

.svg-mail {
  /* padding-right: 8px; */
  height: 28px;
  width: 28px;
  top: -1px;
  position: relative;
}

input[type="date"]::-webkit-datetime-edit-text {
  -webkit-appearance: none;
  display: none;
}

input[type="date"]::-webkit-datetime-edit-month-field {
  -webkit-appearance: none;
  display: none;
}

input[type="date"]::-webkit-datetime-edit-day-field {
  -webkit-appearance: none;
  display: none;
}

input[type="date"]::-webkit-datetime-edit-year-field {
  -webkit-appearance: none;
  display: none;
}

input[type="date"]:before {
  content: attr(placeholder) !important;
  margin-right: 0.5em;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  font-feature-settings: "pnum" on, "lnum" on;
  color: rgba(78, 78, 78, 0.4);
  padding: 12px, 12px, 10px, 12px;
}

input[type="date"]:focus:before {
  content: "" !important;
}

#ot-sdk-btn.ot-sdk-show-settings,
#ot-sdk-btn.optanon-show-settings {
  color: #68b631;
  border: none !important;
  height: auto;
  padding: 5px 0px !important;
  cursor: pointer;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 15px !important;
  line-height: 19px !important;
  color: #ffffff !important;
  text-decoration: none !important;
  cursor: pointer !important;
  display: block !important;
  font-family: "Gotham" !important;
}

#ot-sdk-btn.ot-sdk-show-settings,
#ot-sdk-btn.optanon-show-settings:hover {
  border: none !important;
  height: auto;
  background: none !important;
  padding: 5px 0px !important;
  cursor: pointer;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 15px !important;
  line-height: 19px !important;
  color: #ffffff !important;
  text-decoration: none !important;
  cursor: pointer !important;
  display: block !important;
  font-family: "Gotham" !important;
}

.video-image-badge {
  position: absolute;
  margin-right: 0;
  bottom: 22px;
  display: flex;
  align-items: center;
}

.video-image-badge span {
  background-image: url("/public/assets/images/Play Button.webp");
  background-repeat: round;
  background-size: cover;
  width: 100%;
  padding-left: 30px;
  padding-right: 70px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: 12px;
  margin-bottom: 12px;
  margin-left: 0px;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  font-size: 16px;
  letter-spacing: -0.02em;
  color: #000000;
  line-height: 19px;
  z-index: 0;
}

.video-image-badge .video-button {
  background-size: contain;
  background-position-y: center;
  background-image: url("/public/assets/images/normal-state.webp");
  height: 120px;
  width: 120px;
  background-repeat: no-repeat;
  position: relative;
  background-position-x: center;
  top: 0px;
  right: -34px;
  z-index: 1000;
}

.video-image-badge .video-button-2 {
  background-size: contain;
  background-position-y: center;
  background-image: url("/public/assets/images/normal-state.webp");
  height: 80px;
  width: 80px;
  background-repeat: no-repeat;
  position: relative;
  background-position-x: center;
  top: 0px;
  right: -10px;
  z-index: 1000;
}

.video-image-badge .video-button-2:hover {
  background-size: contain;
  background-position-y: center;
  background-image: url("/public/assets/images/hover-state.webp");
  height: 80px;
  width: 80px;
  background-repeat: no-repeat;
  position: relative;
  background-position-x: center;
  top: 0px;
  right: -10px;
  z-index: 1000;
}

.video-image-badge .video-button:hover {
  background-size: contain;
  background-position-y: center;
  background-image: url("/public/assets/images/hover-state.webp");
  height: 120px;
  width: 120px;
  background-repeat: no-repeat;
  position: relative;
  background-position-x: center;
  top: 0px;
  right: -34px;
  z-index: 1000;
}

.webm-video {
  max-width: 431px;
  width: 100%;
}

.footer-modal-buttons {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}

.thankyou-contact-number {
  white-space: nowrap;
}

.thankyou-espanol {
  margin-top: 78px;
  border-bottom: 4px solid #eae7f1;
  padding-bottom: 100px;
  margin-bottom: 60px;
}

.thankyou-espanol h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 42px;
  text-align: center;
  color: #4b3f67;
}

.thankyou-espanol h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #7c6991;
}

.thankyou-espanol p {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  margin-top: 20px;
  text-align: center;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #4e4e4e;
}

.thankyou-espanol p span {
  font-weight: 700;
  color: #7c6991 !important;
  text-decoration: underline;
  text-decoration-skip-ink: none;
}

.thankyou-espanol p span a {
  font-weight: 700;
  color: #7c6991 !important;
  text-decoration: underline;
  text-decoration-skip-ink: none;
}

.company-logo {
  max-width: 250px;
  max-height: 102px;
  height: 100%;
}

.company-logo-foundation {
  max-width: 397px;
  width: 100%;
  max-height: 102px;
  height: 100%;
}

.call-icon-img {
  max-width: 120px;
  width: 100%;
  min-height: 100px;
  height: 100%;
  margin-right: 30px;
}

.knowledge-img {
  max-width: 174.78px;
  width: 100%;
  max-height: 176.06px;
  height: 100%;
}

.menu-img {
  max-width: 32px;
  width: 100%;
  max-height: 18px;
  height: 100%;
}

.peter-thumbnail-img {
  max-width: 342px;
  width: auto;
  max-height: 162px;
  height: 100%;
}

.download-circle-1 {
  fill: none;
  stroke: #4b3f57;
  stroke-width: 2.25;
  stroke-miterlimit: 10;
}

.download-circle-2 {
  fill: none;
  stroke: #4b3f57;
  stroke-width: 2.25;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.no-hero-section-brush {
  z-index: 0;
}

@media (max-width: 990px) {
  .brush-style {
    background-image: url("/public/assets/images/Brush Style Breaker.webp") !important;
    background-size: cover !important;
    background-position: 75% center !important;
    height: 50px !important;
  }

  .brush-style.sticky {
    display: none;
  }

  .inf-spanish {
    width: 100%;
  }
}

@media (max-width: 880px) {
  .how-to-use-brush {
    height: 96px !important;
    padding-top: 23px !important;
  }
}

@media (max-width: 1220px) {
  .submenu-style {
    padding: 11px 20px 0;
  }
}

.check.error {
  outline: 2px solid #780000;
}

.radio-css.error {
  outline: auto 2px #780000;
}

@media (max-width: 1500px) {
  #onetrust-banner-sdk .ot-sdk-columns,
  #onetrust-banner-sdk:not(.ot-iab-2) #onetrust-button-group-parent {
    width: calc(100% - 50px -50%) !important;
  }
}

@media (max-width: 767px) {
  #onetrust-banner-sdk:not(.ot-iab-2) #onetrust-group-container {
    max-width: calc(100% - 54px);
    width: 100%;
    float: none !important;
  }

  #onetrust-banner-sdk.ot-buttons-fw:not(.ot-iab-2) #onetrust-button-group-parent {
    max-width: 100% !important;
    width: 100% !important;
  }

  #onetrust-consent-sdk #onetrust-policy-text {
    text-align: left !important;
  }
}

.ddg_para_1 {
  width: 800px;
  line-height: 38px;
  margin-bottom: 0px;
  color: #3e1546;
}

.ddg_para_2 {
  width: 690px;
  line-height: 38px;
  position: relative;
  color: #3e1546;
}

.ddg_para_1 .answer1 {
  min-width: 160px;
  position: relative;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  width: 160px;
  border-bottom: 1px solid #3e1546;
  line-height: 16px;
  top: -1px;
}

.ddg_para_1 .answer1 .answer1label {
  font-weight: 600 !important;
  position: absolute;
  left: 0px;
  right: 0px;
  font-size: 11px;
  width: 160px;
  top: 17px;
  line-height: 16px;
}

.ddg_para_1 .answer2 {
  min-width: 375px;
  position: relative;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  width: 265px;
  border-bottom: 1px solid #3e1546;
  line-height: 16px;
  top: 3px;
}

.ddg_para_1 .answer2 .answer2label {
  font-weight: 600;
  position: absolute;
  left: 0px;
  right: 0px;
  font-size: 11px;
  width: 350px;
  top: 1px;
  line-height: 16px;
}

.ddg_para_2 .answer3 {
  min-width: 160px;
  position: relative;
  text-align: center;
  text-decoration: none;
  border-bottom: 1px solid #3e1546;
  line-height: 14px;
  top: -2px;
  padding-left: 1px;
  padding-right: 1px;
}

.ddg_para_2 .answer3 .answer3label {
  font-weight: 600 !important;
  position: absolute;
  left: 0px;
  right: 0px;
  font-size: 11px;
  width: 100%;
  top: 24px;
  line-height: 16px;
  min-width: 160px;
}

.ddg_para_2 .answer3 .answer3labellength120 {
  font-weight: 600 !important;
  position: absolute;
  left: -300px;
  right: 0px;
  font-size: 11px;
  width: 100%;
  top: 60px;
  line-height: 16px;
  min-width: 160px;
}

.ddg_para_2 .answer3 .answer3labellength70 {
  font-weight: 600 !important;
  position: absolute;
  left: -430px;
  right: 0px;
  font-size: 11px;
  width: 100%;
  top: 60px;
  line-height: 16px;
  min-width: 160px;
}

.ddg_para_2 .answer3 .answer3labellength35 {
  font-weight: 600 !important;
  position: absolute;
  left: -20px;
  right: 0px;
  font-size: 11px;
  width: 100%;
  top: 24px;
  line-height: 16px;
  min-width: 160px;
}

.ddg_para_2 .answer3 .answer3left {
  font-weight: 600 !important;
  position: absolute;
  left: 0px;
  right: 0px;
  font-size: 11px;
  width: 100%;
  top: 24px;
  line-height: 16px;
  min-width: 160px;
}

.ddg_para_2 .margin-saperation {
  margin-left: 3px;
}

.ddg_para_2 .answer4 {
  min-width: 160px;
  position: relative;
  text-align: center;
  text-decoration: none;
  display: inline;
  border-bottom: 1px solid #3e1546;
  line-height: 14px;
  top: -2px;
}

.ddg_para_2 .answer4 .answer4label {
  font-weight: 600;
  position: absolute;
  left: 0px;
  right: 0px;
  font-size: 11px;
  width: 100%;
  top: 24px;
  line-height: 16px;
  min-width: 160px;
}

.ddg_para_2 .answer4 .answer4case1 {
  top: 24px !important;
  left: -3px !important;
}

.ddg_para_2 .answer4 .answer4case2 {
  top: 24px !important;
  left: 50px !important;
}

.ddg_para_2 .answer4 .answer4case3 {
  top: 24px;
  left: 140px;
}

.ddg_para_2 .answer4 .answer4case4 {
  top: 24px !important;
  left: 40px !important;
}

.ddg_para_2 .answer4 .answer4case5 {
  top: 24px !important;
  left: -20px !important;
}

.ddg_para_2 .answer4 .answer4case8 {
  top: 61px !important;
  left: -350px !important;
}

.ddg_para_2 .answer4 .answer4case6 {
  top: 61px !important;
  left: -400px !important;
}

.ddg_para_2 .answer4 .answer4case7 {
  top: 24px !important;
  left: 0px !important;
}

.ddg_para_2 .answer4 .answer4case9 {
  top: 61px !important;
  left: -216px !important;
}

.ddg_para_2 .answer4 .answer4case10 {
  top: 61px !important;
  left: 78px !important;
}

.ddg_para_2 .answer4 .answer4labelshort {
  position: absolute;
  left: -600px;
  right: auto;
  font-size: 11px;
  width: 100%;
  top: 65px;
  line-height: 16px;
  min-width: 160px;
}

.ddg_page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  overflow: hidden;
  width: 100%;
}

.ddg_page .content {
  opacity: 0;
  height: auto;
  width: 800px;
}

.ddg_loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* cookie banner*/

#onetrust-banner-sdk .ot-sdk-row,
#onetrust-pc-sdk .ot-sdk-row,
#ot-sdk-cookie-policy .ot-sdk-row {
  margin: 0 !important;
  max-width: none !important;
  display: block !important;
}

#ot-sdk-btn.ot-sdk-show-settings,
#ot-sdk-btn.optanon-show-settings {
  color: #68b631;
  border: none !important;
  height: auto;
  padding: 5px 0px !important;
  cursor: pointer;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 15px !important;
  line-height: 19px !important;
  color: #ffffff !important;
  text-decoration: none !important;
  cursor: pointer !important;
  display: block !important;
  font-family: "Gotham" !important;
}

#ot-sdk-btn.ot-sdk-show-settings,
#ot-sdk-btn.optanon-show-settings:hover {
  border: none !important;
  height: auto;
  background: none !important;
  padding: 5px 0px !important;
  cursor: pointer;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 15px !important;
  line-height: 19px !important;
  color: #ffffff !important;
  text-decoration: none !important;
  cursor: pointer !important;
  display: block !important;
  font-family: "Gotham" !important;
}

#onetrust-banner-sdk #onetrust-policy-text {
  text-align: left !important;
}

#onetrust-consent-sdk #onetrust-banner-sdk a[href],
#onetrust-consent-sdk #onetrust-banner-sdk a[href] font,
#onetrust-consent-sdk #onetrust-banner-sdk .ot-link-btn {
  text-decoration-skip-ink: none !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 140% !important;
  letter-spacing: -0.01em !important;
  color: #ffffff !important;
}

#onetrust-consent-sdk #onetrust-policy-title,
#onetrust-consent-sdk #onetrust-policy-text {
  font-size: 12px !important;
  line-height: 16px !important;
}

#onetrust-banner-sdk .ot-sdk-columns,
#onetrust-banner-sdk:not(.ot-iab-2) #onetrust-button-group-parent {
  width: calc(100% - 50px -50%) !important;
}

#onetrust-banner-sdk #onetrust-policy {
  margin-top: 1em !important;
}

#onetrust-banner-sdk #onetrust-close-btn-container {
  /* top: 2.2em !important; */
  right: 15px !important;
}

#onetrust-consent-sdk #onetrust-pc-btn-handler p,
#onetrust-consent-sdk #onetrust-pc-btn-handler.cookie-setting-link {
  font-size: 14px;
}

body #onetrust-banner-sdk #onetrust-policy {
  margin-left: 1em;
  text-align: left !important;
}

#onetrust-banner-sdk #onetrust-button-group button#onetrust-accept-btn-handler {
  padding: 12px 20px !important;
}

#onetrust-banner-sdk:not(.ot-iab-2) #onetrust-group-container {
  margin-left: 0px;
}

#onetrust-banner-sdk:not(.ot-iab-2) #onetrust-button-group-parent {
  margin-right: 0px !important;
}

#onetrust-consent-sdk {
  background-color: #4b3f67 !important;
}

#onetrust-consent-sdk #onetrust-banner-sdk {
  background-color: #4b3f67 !important;
  color: #fff !important;
}

#onetrust-policy-text a {
  font-weight: 700 !important;
}

#onetrust-banner-sdk .ot-sdk-container {
  max-width: 1340px !important;
}

#onetrust-banner-sdk:not(.ot-iab-2) #onetrust-group-container {
  margin-left: 14px;
}

#onetrust-banner-sdk:not(.ot-iab-2) #onetrust-button-group-parent {
  margin-right: 14px !important;
}

#onetrust-consent-sdk #onetrust-policy-text {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 140% !important;
  letter-spacing: -0.01em !important;
  color: #ffffff !important;
}

#onetrust-consent-sdk #onetrust-policy-text a {
  font-style: normal !important;
  font-weight: 400 !important;
  font-family: "Roboto" !important;
  font-size: 16px !important;
  line-height: 140% !important;
  letter-spacing: -0.01em !important;
  color: #ffffff !important;
}

#onetrust-banner-sdk #onetrust-pc-btn-handler.cookie-setting-link {
  background-color: #4b3f67 !important;
  border: none;
  text-decoration: underline;
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 120% !important;
  text-align: center !important;
  letter-spacing: -0.01em !important;
  text-decoration-line: underline !important;
  background-color: #4b3f67 !important;
  color: #ffffff !important;
  margin-top: -5px !important;
}

#onetrust-banner-sdk #onetrust-button-group {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  column-gap: 20;
}

#onetrust-banner-sdk #onetrust-button-group button#onetrust-accept-btn-handler {
  background: #ffffff !important;
  border-radius: 100px !important;
  color: #3e1647 !important;
  border: none !important;
  padding: 12px 12px !important;
}

#onetrust-banner-sdk #onetrust-close-btn-container {
  background: #5e3068 !important;
  border-radius: 100% !important;
  height: 40px !important;
  width: 40px !important;
}

#onetrust-banner-sdk #onetrust-close-btn-container .banner-close-button {
  left: -2px !important;
  top: -2px !important;
}

#onetrust-consent-sdk #onetrust-pc-btn-handler,
#onetrust-consent-sdk #onetrust-pc-btn-handler.cookie-setting-link {
  margin-right: 20px !important;
}

#onetrust-consent-sdk #onetrust-accept-btn-handler,
#onetrust-banner-sdk #onetrust-reject-all-handler {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  border-radius: 100px;
}

#onetrust-banner-sdk #onetrust-accept-btn-handler {
  background-color: #fff !important;
  color: #000 !important;
  border-radius: 100px !important;
  padding: 20px 32px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  letter-spacing: -0.02em !important;
  text-transform: uppercase !important;
  color: #3e1647 !important;
  min-width: 0px !important;
  align-items: center !important;
  justify-content: center !important;
  font-family: "Roboto" !important;
}

#onetrust-banner-sdk.ot-buttons-fw:not(.ot-iab-2) #onetrust-button-group-parent {
  padding-left: 0% !important;
  padding-right: 0% !important;
}

#onetrust-banner-sdk div {
  background-color: #4b3f67 !important;
  color: #fff !important;
  overflow: hidden !important;
  text-align: center !important;
}

#onetrust-banner-sdk #onetrust-close-btn-container {
  background: #7c6991 !important;
  height: 40px !important;
  width: 40px !important;
  border-radius: 102.56409454345703px !important;
}

#onetrust-banner-sdk #onetrust-close-btn-container .banner-close-button {
  left: -2px !important;
  top: -2px !important;
}

@media (max-width: 1500px) {
  #onetrust-banner-sdk .ot-sdk-columns,
  #onetrust-banner-sdk:not(.ot-iab-2) #onetrust-button-group-parent {
    width: calc(100% - 50px -50%) !important;
  }
}

@media only screen and (min-width: 890px) {
  #onetrust-banner-sdk.ot-buttons-fw:not(.ot-iab-2) #onetrust-button-group button {
    width: 35% !important;
    margin-bottom: 5px;
    margin-top: 5px;
  }
}

@media only screen and (max-width: 890px) {
  #onetrust-banner-sdk.ot-buttons-fw:not(.ot-iab-2) #onetrust-button-group button {
    width: 100% !important;
    margin-bottom: 5px;
    margin-top: 5px;
  }
}

.disable-link-click {
  pointer-events: none !important;
}

.ddg-modal-class {
  position: relative;
  z-index: 100000;
}
