.brochure-container {
  background: #4B3F67;
  color: #fff;
  width: 100%;
  border-radius: 8px;
  max-width: calc(100%/2 - 20px);
  position: relative;
  margin-bottom: 5px;
  min-height: 209px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  margin-left: 20px;
  margin-top: 20px;
}
.brochure-container .brochure-image{
  max-width: 43%;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 8px;
}
.brochure-container .brochure-image .brochure-image-img{
    background-position: right center !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    width: 100%;
    height: 100%;
    border-radius: 8px;
}
.brochure-container .brochure-image .brochure-image-img img{border-radius: 0px 8px 8px 0;}
.brochure-container .image-overlay-container {
  position: relative;
  width: 100%;
}

.brochure-container .image-overlay {
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  color: #fff;
  padding: 0 0 0 32px;
  text-align: left;
  max-width: 57%;
  width: 100%;
}
.brochure-container .image-overlay .brochure-heading-content{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  height: 100%;
}
.brochure-container .image-overlay .brochure-button{
  background: #FFFFFF !important;
  border-radius: 50px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  padding: 8px 24px;
  text-decoration: none !important;
  color: #4B3F67;
  border: none;
  box-shadow: 0px 60px 24px rgba(23, 19, 32, 0.01), 0px 34px 20px rgba(23, 19, 32, 0.05), 0px 15px 15px rgba(23, 19, 32, 0.09), 0px 4px 8px rgba(23, 19, 32, 0.1), 0px 0px 0px rgba(23, 19, 32, 0.1);
  position: absolute;
  right: 23px;
  bottom: 27px;
  display: flex;
  align-items: center;
  min-width: 148px;
  justify-content: center;
  cursor: pointer;
}

.brochure-container .image-overlay .ddg-button{
  background: #FFFFFF !important;
  border-radius: 50px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  padding: 8px 24px;
  text-decoration: none !important;
  color: #4B3F67;
  border: none;
  box-shadow: 0px 60px 24px rgba(23, 19, 32, 0.01), 0px 34px 20px rgba(23, 19, 32, 0.05), 0px 15px 15px rgba(23, 19, 32, 0.09), 0px 4px 8px rgba(23, 19, 32, 0.1), 0px 0px 0px rgba(23, 19, 32, 0.1);
  position: absolute;
  right: 23px;
  bottom: 27px;
  display: flex;
  align-items: center;
  min-width: 148px;
  justify-content: center;
  cursor: pointer;
}

.brochure-container .image-overlay .helpful-hints-button{
  background: #FFFFFF !important;
  border-radius: 50px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  padding: 8px 24px;
  text-decoration: none !important;
  color: #4B3F67;
  border: none;
  box-shadow: 0px 60px 24px rgba(23, 19, 32, 0.01), 0px 34px 20px rgba(23, 19, 32, 0.05), 0px 15px 15px rgba(23, 19, 32, 0.09), 0px 4px 8px rgba(23, 19, 32, 0.1), 0px 0px 0px rgba(23, 19, 32, 0.1);
  right: 23px;
  bottom: 27px;
  display: flex;
  align-items: center;
  min-width: 148px;
  justify-content: center;
  cursor: pointer;
}

.brochure-container .image-overlay .absolute{
position: absolute;
}

.brochure-container .image-overlay .static{
  position: static;
  margin-top: 10px;
  }

.brochure-container .image-overlay .brochure-button:hover{
  background: #EAE7F1 !important;
}
.brochure-container .image-overlay .brochure-button svg{
  margin-right: 8px;
}

.brochure-container .image-overlay .ddg-button:hover{
  background: #EAE7F1 !important;
}
.brochure-container .image-overlay .helpful-hints-button:hover{
  background: #EAE7F1 !important;
}
.brochure-container .image-overlay .ddg-button svg{
  margin-right: 8px;
}
.brochure-container .image-overlay .helpful-hints-button svg{
  margin-right: 8px;
}



.brochure-container .image-overlay .brochure-button-inbrija{
  background: #FFFFFF !important;
  border-radius: 50px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  padding: 8px 24px;
  text-decoration: none !important;
  color: #4B3F67;
  border: none;
  box-shadow: 0px 60px 24px rgba(23, 19, 32, 0.01), 0px 34px 20px rgba(23, 19, 32, 0.05), 0px 15px 15px rgba(23, 19, 32, 0.09), 0px 4px 8px rgba(23, 19, 32, 0.1), 0px 0px 0px rgba(23, 19, 32, 0.1);
  position: static;
  right: 23px;
  bottom: 27px;
  display: flex;
  align-items: center;
  min-width: 148px;
  justify-content: center;
  cursor: pointer;
  margin-top: 10px;
}

.brochure-container .image-overlay .brochure-button-inbrija:hover{
  background: #EAE7F1 !important;
}

.brochure-container .image-overlay .brochure-button-inbrija svg{
  margin-right: 8px;
}

.brochure-container .brochure-heading{
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #FFFFFF;
  margin-bottom: 8px;
  width: 100%;
}
.brochure-container .brochure-heading sup{
  top: -5px;
}
.brochure-container .brochure-content{
font-weight: 400;
font-size: 16px;
line-height: 20px;
letter-spacing: -0.02em;
color: #FFFFFF;
width: 90%;
}

.brochure-container .text-decoration-none{
  text-decoration: none !important;
}

@media (max-width:1024px) {
  .brochure-container {
    max-width: 100%;
    margin-left: 0px;
  }
  .brochure-container .image-overlay{
    padding-left: 32px !important;
  }
}
@media (max-width:767px){
  .brochure-container .image-overlay{
    max-width: 59%;
    width: 100%;
  }
  .brochure-container .brochure-image{
    max-width: 41%;
    width: 100%;
  }
  .brochure-container .image-overlay .static{
    position: static;
    margin-top: 24px;
    }
}
@media (max-width:540px){
  .brochure-container {
    max-width: 100%;
  }
  .brochure-container .image-overlay{
    padding: 0 0 0 0 !important;
    max-width: 100%;
    position: relative;
  }
  .brochure-container .image-overlay .brochure-heading-content{
    padding: 26px 0 26px 32px ;
    width: 60%;
  }
  .brochure-container .brochure-heading{
    font-size: 18px;
    line-height: 24px;
  }

  .brochure-container .brochure-content{
    font-size: 14px;
    line-height: 20px;
  }
  .brochure-container .image-overlay .brochure-button{
    right: 9px;
    bottom: 20px;
    min-width:132px
  }
  .brochure-container .image-overlay .ddg-button{
    right: 9px;
    bottom: 20px;
    min-width:132px
  }
  .brochure-container .image-overlay .helpful-hints-button{
    right: 9px;
    bottom: 20px;
    min-width:132px
  }
  .brochure-container .brochure-image{
    max-width: 100%;
    position: absolute;
  }
}

@media (max-width:430px){
  .brochure-container .image-overlay .brochure-heading-content{
    padding: 18px 0 18px 24px;
  }
  .brochure-container .image-overlay .brochure-button{
    font-size: 13px;
    padding: 8px 18px
  }
  .brochure-container .image-overlay .ddg-button{
    font-size: 13px;
    padding: 8px 18px
  }
  .brochure-container .image-overlay .helpful-hints-button{
    font-size: 13px;
    padding: 8px 18px
  }
}

@media (max-width:400px){
  
  .brochure-container .image-overlay .brochure-heading-content{
    padding: 18px 0 18px 18px;
  }
  .brochure-container .image-overlay .brochure-button{
    font-size: 12px;
    padding: 8px 14px
  }
  .brochure-container .image-overlay .ddg-button{
    font-size: 12px;
    padding: 8px 14px
  }
  .brochure-container .image-overlay .helpful-hints-button{
    font-size: 12px;
    padding: 8px 14px
  }
  .brochure-container .brochure-image .brochure-image-img{
    background-position-x: 120% !important;
  }
  .brochure-container .brochure-heading{
    font-size: 16px;
    line-height: 20px;
  }
  .brochure-container .image-overlay .brochure-heading-content{
    width: 57%;
  }
}
@media (max-width:360px){
  .brochure-container .image-overlay .brochure-heading-content{
    width: 65%;
  }
  .brochure-container .image-overlay .brochure-button{
    bottom: 12px;
  }
  .brochure-container .image-overlay .ddg-button{
    bottom: 12px;
  }
  .brochure-container .image-overlay .helpful-hints-button{
    bottom: 12px;
  }  
}