.how-to-use-page .flex-start.menu-section .dropdown:nth-child(4) .menu {
    font-weight: 600;
    color: #fff
}

.how-to-use-page .flex-start.menu-section .dropdown:nth-child(4) .menu:after {
    display: block;
}

.how-to-use-page .hero-section {
    margin-top: 30px;
    min-height: 428px;
    max-width: 1323px;
    display: flex;
    width: 100%;
}

 .how-to-use-cine{
    max-width: 780px;
    width: max-content;
}
.how-to-use-page .hero-section .hero-section-image {
    max-width: 582px;
    width: 100%;
    text-align: left;
    position: relative;
    padding-bottom: 10px;
}

.how-to-use-page .hero-section .hero-section-image .actor_portrayal {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    letter-spacing: -0.02em;
    position: absolute;
    right: -22%;
    bottom: 33px;
}

.how-to-use-page .hero-section .hero-section-content {
    max-width: calc(100% - 582px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-content: center;
    padding-right: 20px;
    padding-bottom: 20px;
}

.how-to-use-page .hero-section .hero-section-content h1 {
    font-weight: 300;
    font-size: 51px;
    line-height: 60px;
    color: #FFFFFF;
    text-align: left;
}

.how-to-use-page .hero-section .hero-section-content h1 span {
    font-family: 'Cigarettes and Coffee';
    line-height: 51px;
    color: #FFB423;
}

.how-to-use-page .hero-section .hero-section-content h4 {
    font-style: normal;
    font-weight: 300;
    font-size: 26px;
    line-height: 124%;
    color: #FFFFFF;
    text-align: left;
}

.how-to-use-page .use-ibrija-menu {
    color: #000 !important;
}

.how-to-use-page .when-to-use-inbrija {
    background: #000000;
}

.how-to-use-page .inbrija-fight {
    padding-top: 79px;
    padding-bottom: 34px;
}

.how-to-use-page .inbrija-fight h1 {
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
    margin-bottom: 0px;
    color: #7C6991;
}

.how-to-use-page .inbrija-fight h6 {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    margin-top: 18px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #747474;
    text-align: center;
    margin-bottom: 0px;
}

.how-to-use-page .inbrija-fight p {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    margin-top: 52px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #747474;
    text-align: center;
    margin-bottom: 0px;
}

.how-to-use-page .inbrija-fight p a {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-decoration: underline;
    margin-top: 52px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #747474;
    text-align: center;
    margin-bottom: 0px;
}

.how-to-use-page .when-to-use-inbrija .when-to-use-inbrija-container {
    max-width: 1278px;
    padding: 0px 20px;
    margin: auto;
}

.how-to-use-page .when-to-use-inbrija .when-to-use-inbrija-container .when-to-use-left-side {
    max-width: 515px;
    margin: auto;
    margin-left: 0;
}

.how-to-use-page .when-to-use-inbrija .when-to-use-inbrija-container .when-to-use-left-side h4 {
    font-weight: 400;
    font-size: 24.3292px;
    line-height: 29px;
    text-align: center;
    letter-spacing: 0.23em;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-bottom: 13px;
    position: relative;
    padding-left: 28px;
    display: inline-flex;
}

.how-to-use-page .when-to-use-inbrija .when-to-use-inbrija-container .when-to-use-left-side h4 img {
    position: relative;
    top: -10px;
    left: -10px;
}

.how-to-use-page .when-to-use-inbrija .when-to-use-inbrija-container .when-to-use-left-side h2 {
    font-family: 'Cigarettes and Coffee';
    font-weight: 400;
    font-size: 82px;
    line-height: 82px;
    letter-spacing: 0.02em;
    color: #FFB423;
    margin-bottom: 0;
}

.how-to-use-page .when-to-use-inbrija .when-to-use-inbrija-container .when-to-use-left-side h3 {
    font-weight: 400;
    font-size: 56.3996px;
    line-height: 66px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-bottom: 20px;
    margin-top: -20px;
    position: relative;
}

.how-to-use-page .when-to-use-inbrija .when-to-use-inbrija-container .when-to-use-left-side h3 span {
    display: inline-block;
}

.how-to-use-page .when-to-use-inbrija .when-to-use-inbrija-container .when-to-use-left-side h3 img {
    position: relative;
    top: -15px;
}

.how-to-use-page .when-to-use-inbrija .when-to-use-inbrija-container .when-to-use-left-side a {
    padding: 14.5px 24px;
    background: transparent;
    border: 1px solid #F9C606;
    border-radius: 100px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: #FFFFFF !important;
    text-decoration: none !important;
    display: inline-block;
}

.how-to-use-page .when-to-use-inbrija .when-to-use-inbrija-container .when-to-use-left-side a.show_mobile {
    display: none;
}

.how-to-use-page .when-to-use-inbrija .when-to-use-inbrija-container .when-to-use-right-side {
    max-width: 632px;
    margin: auto;
    margin-right: 0;
}

.how-to-use-page .when-to-use-inbrija .when-to-use-inbrija-container .when-to-use-right-side p {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #FFFFFF;
}

.how-to-use-page .when-to-use-inbrija .when-to-use-inbrija-container .when-to-use-right-side p:last-child {
    margin-bottom: 0;
}

.how-to-use-page .how-to-use-inbrija {
    padding: 71px 20px 49px;
    max-width: 950px;
    width: 100%;
    margin: auto;
}

.how-to-use-page .how-to-use-inbrija h3 {
    font-weight: 400;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
    color: #4B3F67;
    margin-bottom: 12px;
}

.how-to-use-page .how-to-use-inbrija p {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #747474;
}

.how-to-use-page .how-to-use-inbrija a {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    text-decoration-line: underline;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #7C6991;
}

.how-to-use-page .demo-video {
    width: 941px;
    max-width: 100%;
    margin: auto;
    margin-bottom: 71px;
}

.how-to-use-page .demo-video video {
    max-width: 100%;
}

.how-to-use-page .demo-video .transcript {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
}

.how-to-use-page .demo-video .transcript a {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-decoration-line: underline;
    color: #4B3F67 !important;
}

.how-to-use-page .nurse-reference {
    padding: 71px 20px 115px;
    max-width: 1274px;
    margin: auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.how-to-use-page .nurse-reference .nurse-reference-left {
    width: 585px;
    margin: auto;
    margin-left: 0;
    background: url("/public/assets/images/question-bg.webp") no-repeat center center;
    background-size: 100% 100%;

}

.how-to-use-page .nurse-reference .nurse-reference-left .nurse-reference-left-content {
    margin: auto;
    padding-left: 58px;
    padding-top: 34px;
}

.how-to-use-page .nurse-reference .nurse-reference-left h3 {
    font-weight: 700;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
    color: #4B3F67;
    margin-bottom: 10px;
}

.how-to-use-page .nurse-reference .nurse-reference-left p {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #4B3F67;
    max-width: 448px;
    margin: auto;
    margin-bottom: 20px;

}

.how-to-use-page .nurse-reference .nurse-reference-left a {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: #FFFFFF !important;
    padding: 14.5px 24px;
    text-decoration: none !important;
    background: #4B3F67;
    border-radius: 100px;
    display: inline-block;
}

.how-to-use-page .nurse-reference .nurse-reference-left a:hover{
    background: #7C6991;
}

.how-to-use-page .nurse-reference .nurse-reference-left a sup {
    font-size: 10px;
    top: -5px;
}

.how-to-use-page .nurse-reference .nurse-reference-middle {
    max-width: calc(100% - 585px - 515px);
    mix-blend-mode: multiply;
    border: 2px solid #EAE7F1;
    height: 256px;
}


.how-to-use-page .nurse-reference-right {
    padding: 0;
    max-width: 567px;
    margin: auto;
    margin-right: 0;
}

.how-to-use-page .nurse-reference-right .nurse-support-contianer-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 0 26px;
}

.how-to-use-page .nurse-reference-right .nurse-support-contianer-wrap .nurse-support-contiainer-wrap-image {
    max-width: 155px;
    width: 100%;
    margin-top: 20px;
}

.how-to-use-page .nurse-reference-right .nurse-support-contianer-wrap .nurse-support-contiainer-wrap-image p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 0pc;
    text-align: center;
    letter-spacing: 0.01em;
    color: #4B3F67;
    margin-top: 4px;
}

.how-to-use-page .nurse-reference-right .nurse-support-contianer-wrap .nurse-support-contiainer-wrap-content {
    max-width: calc(100% - 155px - 26px);
    width: 100%;
    margin-top: 20px;
}

.how-to-use-page .nurse-reference-right h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 40px;
    margin-bottom: 0px;
    color: #4B3F67;
    text-align: left;
}

.how-to-use-page .nurse-reference-right .nurse-support-contianer-wrap .nurse-support-contiainer-wrap-content h3 {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #4B3F67;
    text-align: left;
}

.how-to-use-page .nurse-reference-right .nurse-support-contianer-wrap .call-toll-free {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    gap: 7px;
    width: 100%;
    padding-top: 15px;
}

.how-to-use-page .nurse-reference-right .nurse-support-contianer-wrap .call-toll-free.show_mobile {
    display: none;
}

.how-to-use-page .nurse-reference-right .nurse-support-contianer-wrap .call-toll-free * {
    display: block;
}

.how-to-use-page .nurse-reference-right .nurse-support-contianer-wrap .call-toll-free .content-text {
    font-weight: 700;
    font-size: 23.2771px;
    line-height: 28px;
    color: #4B3F67;
    max-width: calc(100% - 200px - 35px);
    width: 100%;
}

.how-to-use-page .nurse-reference-right .nurse-support-contianer-wrap .call-toll-free .content-number {
    text-align: center;
}

.how-to-use-page .nurse-reference-right .nurse-support-contianer-wrap .call-toll-free .content-number a {
    font-weight: 700;
    font-size: 23.2771px;
    line-height: 28px;
    text-transform: uppercase;
    color: #4B3F67 !important;
    text-decoration: none !important;
    background: url('/public/assets/images/design-button-background.webp') no-repeat center center;
    background-size: cover;
    width: 200px;
    height: 64px;
    margin-top: -25px;
    display: flex;
    justify-content: center;
    padding-top: 23px;

}

.how-to-use-page .nurse-reference-right .nurse-support-contianer-wrap .call-toll-free .content-number a:hover {
    font-weight: 700;
    font-size: 23.2771px;
    line-height: 28px;
    text-transform: uppercase;
    color: #4B3F67 !important;
    text-decoration: none !important;
    background: url('/public/assets/images/hover-image.webp') no-repeat center center;
    width: 200px;
    height: 64px;
    margin-top: -25px;
    display: flex;
    justify-content: center;
    padding-top: 23px;
}

.how-to-use-page .nurse-reference-right .nurse-support-contianer-wrap .call-toll-free .content-number>span {
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    color: #4B3F67;
    margin: 10px auto 0;
    max-width: 150px;
}

.how-to-use-page .nurse-reference-right .nurse-support-contianer-wrap .call-toll-free .content-number>span small{
    font-size: 9px;
    display: inline;
}

.how-to-use-page .use-inbrija-as-needed {
    background: url("/public/assets/images/inhaler-background.webp") repeat center center;
    background-size: cover;
    padding-top: 82px;
}

.how-to-use-page .use-inbrija-as-needed .use-inbrija-as-needed-container {
    max-width: 1260px;
    padding: 0 20px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.how-to-use-page .use-inbrija-as-needed .use-inbrija-as-needed-container .use-inbrija-as-needed-container-image {
    width: 360px;
}

.how-to-use-page .use-inbrija-as-needed .use-inbrija-as-needed-container .use-inbrija-as-needed-container-content {
    max-width: calc(100% - 360px - 150px);
    text-align: left;
    padding: 0px 0px 82px 0px;
}

.how-to-use-page .use-inbrija-as-needed .use-inbrija-as-needed-container .use-inbrija-as-needed-container-content h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 42px;
    color: #000000;
    margin-bottom: 30px;
}

.how-to-use-page .use-inbrija-as-needed .use-inbrija-as-needed-container .use-inbrija-as-needed-container-content ul {
    margin-bottom: 0;
    padding-left: 1.5em;
}

.how-to-use-page .use-inbrija-as-needed .use-inbrija-as-needed-container .use-inbrija-as-needed-container-content li {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 8px;
}

.how-to-use-page .use-inbrija-as-needed .use-inbrija-as-needed-container .use-inbrija-as-needed-container-content li:last-child {
    margin-bottom: 0;
}

.how-to-use-page .use-inbrija-as-needed .use-inbrija-as-needed-container .use-inbrija-as-needed-container-content li b {
    font-weight: 700;
}

.how-to-use-page .helpful-hints {
    background: linear-gradient(147.08deg, #090913 0%, #1B222A 100%);
    padding: 84px 20px 135px;
    position: relative;
}

.how-to-use-page .helpful-hints::before {
    background: url("/public/assets/images/Tennis-Supporting.webp") no-repeat bottom right;
    text-align: left;
    content: '';
    position: absolute;
    right: 1%;
    bottom: 0;
    height: 100%;
    width: 557px;
    background-size: contain;
}

.how-to-use-page .helpful-hints .tennis{
    position: absolute;
    right: 1%;
    bottom: 0;
    height: 100%;
    width: 557px;
    opacity: 0;
}
.how-to-use-page .helpful-hints::after {
    content: 'Actor portrayal.';
    position: absolute;
    bottom: 19px;
    color: #FFFFFF;
    right: 38%;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    display: block;
    letter-spacing: -0.02em;
}

.how-to-use-page .helpful-hints h3 {
    font-weight: 400;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 36px;
}

.how-to-use-page .helpful-hints .helpful-hints-contianer {
    background: #EAE7F1;
    border-radius: 8px;
    padding: 36px 30px 75px 26px;
}

.how-to-use-page .helpful-hints .helpful-hints-contianer>ul {
    margin-bottom: 63px;
    list-style: none;
    padding-left: 0;
}

.how-to-use-page .helpful-hints .helpful-hints-contianer>ul li {
    display: flex;
    /* gap: 16px; */
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 40px;
    flex-wrap: wrap;
}

.how-to-use-page .helpful-hints .helpful-hints-contianer>ul li .content {
    max-width: calc(100% - 16px - 49px);
    margin-top: 12px;
    text-align: left;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #4E4E4E;
    margin-bottom: 12px;
    margin-left: 16px;
}

.how-to-use-page .helpful-hints .helpful-hints-contianer>ul li .content a {
    text-decoration: underline !important;
    color: #4E4E4E !important;
}

.how-to-use-page .helpful-hints .helpful-hints-contianer>ul li .content b {
    font-weight: 700;
}

.how-to-use-page .helpful-hints .helpful-hints-contianer>ul ul {
    list-style-type: none;
    padding-left: 0;
    margin-top: 10px;
    padding-left: 27px;
}

.how-to-use-page .helpful-hints .helpful-hints-contianer>ul ul li {
    display: block;
    margin-bottom: 8px;
    list-style-position: inside;
    text-indent: -0.8em;
}

.how-to-use-page .helpful-hints .helpful-hints-contianer>ul ul ul {
    margin-top: 10px;
    padding-left: 0;
}

.how-to-use-page .helpful-hints .helpful-hints-contianer>ul ul ul li {
    padding-left: 20px;
}

.how-to-use-page .helpful-hints .helpful-hints-contianer>ul ul ul li::before {
    content: '•';
    padding-right: 6px;
}

.how-to-use-page .helpful-hints .helpful-hints-contianer li:last-child {
    margin-bottom: 0;
}

.how-to-use-page .helpful-hints .helpful-hints-contianer .primary-link {
    padding: 14.5px 24px 14.5px 26px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #7C6991;
    border-radius: 100px;
    gap: 10px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
    text-transform: capitalize;
    color: #FFFFFF !important;
    text-decoration: none !important;
    position: relative;
    z-index: 1;
}

.how-to-use-page .helpful-hints .helpful-hints-contianer .primary-link:hover{
    background: #685778;
}

.how-to-use-page .jimmy-video {
    background: linear-gradient(147.08deg, #090913 0%, #1B222A 100%);
    padding: 86px 20px;
}

.how-to-use-page .jimmy-video-container {
    display: flex;
    justify-content: space-between;
    max-width: 1256px;
    width: 100%;
    align-items: center;
    margin: auto;
    gap: 82px;
}

.how-to-use-page .jimmy-video-patient-name {
    position: relative;
}

.how-to-use-page .jimmy-video-patient .video-play-button{
    display: inline;
}

.how-to-use-page .jimmy-video-patient .video-play-button:hover{
}

.how-to-use-page .jimmy-video-patient .video-play-button:hover::after{
    display: inline;
    max-width: 86px;
    width: 100%;
    max-height: 80px;
    height: 100%;
    background:url('/public/assets/images/watch-video-hover.webp');
}

.how-to-use-page .jimmy-video-patient .video-play-button-hover{
    display: none;
}

.how-to-use-page .jimmy-video-patient-name {
    margin-top: 0;
    text-align: left;
}

.how-to-use-page-img>img {
    width: 100%;
}

.how-to-use-page .jimmy-video-container-content {
    max-width: 43.1%;
    width: 100%;
    text-align: left;
}

.how-to-use-page .jimmy-video-container-content .comma {
    max-height: 13px;
    height: 100%;
    position: relative;
    top: -12px;
}

.how-to-use-page .jimmy-video-container-content .comma-down {
    max-height: 13px;
    height: 100%;
    position: relative;
    top: -5px;
}

.how-to-use-page .jimmy-video-container-content h1 {
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 56px;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    color: #FFFFFF;
}

.how-to-use-page .jimmy-video-container-content h1 span {
    font-style: normal;
    font-weight: 400;
    font-family: 'Cigarettes and Coffee';
    font-size: 48px;
    line-height: 56px;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    color: #FFB423;
}

.how-to-use-page .jimmy-video-container-content h2 {
    font-family: 'Cigarettes and Coffee' !important;
    font-weight: 400;
    font-size: 48px;
    line-height: 48px;
    color: #FFB423 !important;
    margin-bottom: 0px;
    margin-top: 12px;
    text-align: center;
    text-transform: uppercase;
}

.how-to-use-page .jimmy-video-container-content h6 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 0px;
    color: #FFFFFF;
}

.how-to-use-page .jimmy-video-container-content h3 {
    font-family: 'Cigarettes and Coffee';
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 124%;
    margin-left: 2px;
    text-align: center;
    color: #FFB423;
    margin-top: 25px;
    margin-bottom: 0;
}

.how-to-use-page .jimmy-video-container-content p {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.01em;
    color: rgba(255, 255, 255, 0.6);
    margin-top: 28px;
}

.how-to-use-page .jimmy-video-container-content .patient-name {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.01em;
    margin-top: 23px;
    color: rgba(255, 255, 255, 0.6);
    width: 100%;
    margin-bottom: 0px;
}


.how-to-use-page .jimmy-video-container-content button {
    max-width: 230px;
    height: 48px;
    width: 100%;
    align-items: center;
    border: 1px solid #F9C606;
    border-radius: 100px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    background: none;
    margin-top: 12px;
}

.how-to-use-page .jimmy-video-container-video-mobile {
    display: none;
}

.how-to-use-page .jimmy-video-patient-name .jimmy-video-patient {
    position: absolute;
    margin-right: 0;
    bottom: 22px;
    display: flex;
    align-items: center;
}

.how-to-use-page .jimmy-video-patient-name .jimmy-video-patient>img {
    position: relative;
}

.how-to-use-page .jimmy-video-patient-name .jimmy-video-patient span {
    background-image: url('/public/assets/images/Play Button.webp');
    background-repeat: round;
    background-size: cover;
    width: 100%;
    padding-left: 30px;
    padding-right: 70px;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-top: 12px;
    margin-bottom: 12px;
    margin-left: 0px;
    font-style: normal;
    font-weight: 600;
    text-align: center;
    font-size: 16px;
    letter-spacing: -0.02em;
    color: #000000;
    line-height: 19px;
    z-index: 0;
}

.how-to-use-page .jimmy-video-patient-name .jimmy-video-patient .video-button{
    background-size: contain;
    background-position-y: center;
    background-image:url('/public/assets/images/normal-state.webp');
    height: 100px;
    width: 100px;
    background-repeat: no-repeat;
    position: relative;
    background-position-x: center;
    top: 0px;
    right: -34px;
    z-index: 1000;
}

.how-to-use-page .jimmy-video-patient-name .jimmy-video-patient .video-button:hover{
    background-size: contain;
    background-position-y: center;
    background-image:url('/public/assets/images/hover-state.webp');
    height: 100px;
    width: 100px;
    background-repeat: no-repeat;
    position: relative;
    background-position-x: center;
    top: 0px;
    right: -34px;
    z-index: 1000;
    
}

.how-to-use-page .jimmy-stories-img {
    width: 100%;
    max-width: 644px;
}

@media (min-width:768px) and (max-width:1023px) {
    .how-to-use-page .use-inbrija-as-needed .use-inbrija-as-needed-container .use-inbrija-as-needed-container-content {
        max-width: calc(100% - 360px - 50px);
    }

}

@media (max-width:991px) {
    .how-to-use-page .hero-section {
        min-height: auto;
    }
    .how-to-use-page .hero-section .hero-section-image {
        max-width: 400px;
    }
    .how-to-use-page .hero-section .hero-section-content {
        max-width: calc(100% - 400px);
    }
    .how-to-use-page .brush-style {
    }

    .how-to-use-page .when-to-use-inbrija .container-1500 {
        padding: 54px 0 66px;
        background: none;
    }

    .how-to-use-page .when-to-use-inbrija .when-to-use-inbrija-container .when-to-use-left-side {
        max-width: 100%;
    }

    .how-to-use-page .when-to-use-inbrija .when-to-use-inbrija-container .when-to-use-left-side h4 {
        font-size: 17.4161px;
        line-height: 20px;
        text-align: center;
        margin-bottom: 10px;
        display: block;
    }

    .how-to-use-page .when-to-use-inbrija .when-to-use-inbrija-container .when-to-use-left-side h2 {
        font-size: 56.9981px;
        line-height: 57px;
        margin-bottom: 8px;
        position: relative;
    }

    .how-to-use-page .when-to-use-inbrija .when-to-use-inbrija-container .when-to-use-left-side h3 {
        font-size: 40.3736px;
        line-height: 47px;
        margin-bottom: 26px;
    }

    .how-to-use-page .when-to-use-inbrija .when-to-use-inbrija-container .when-to-use-left-side h4 img {
        display: none;
    }

    .how-to-use-page .when-to-use-inbrija .when-to-use-inbrija-container .when-to-use-left-side h2 img {
        display: inline-block;
        transform: scale(.75);
        top: -15px;
        position: relative;
        padding-right: 5px;

    }

    .how-to-use-page .when-to-use-inbrija .when-to-use-inbrija-container .when-to-use-left-side h3 img {
        display: inline-block;
        transform: scale(.75);
        top: -10px;
        position: relative;
        padding-left: 5px;
    }

    .how-to-use-page .when-to-use-inbrija .when-to-use-inbrija-container .when-to-use-left-side h3 span {
        display: none;
    }

    .how-to-use-page .when-to-use-inbrija .when-to-use-inbrija-container .when-to-use-left-side h2.show_mobile {
        position: relative;
        transform: scale(.75);
        top: -5px;
    }

    .how-to-use-page .when-to-use-inbrija .when-to-use-inbrija-container .when-to-use-left-side a {
        margin-bottom: 30px;
    }

    .how-to-use-page .when-to-use-inbrija .when-to-use-inbrija-container .when-to-use-left-side a.show_mobile {
        display: inline-block;
        margin-right: 16px;
    }

    .how-to-use-page .when-to-use-inbrija .when-to-use-inbrija-container .when-to-use-right-side {
        max-width: 100%;
    }

    .how-to-use-page .when-to-use-inbrija .when-to-use-inbrija-container .when-to-use-right-side img {
        margin: 0 -20px;
        width: 100%;
        display: block;
    }

    .how-to-use-page .demo-video {
        margin-bottom: 0;
    }

    .how-to-use-page .nurse-reference {
        padding: 51px 0;
    }

    .how-to-use-page .nurse-reference {
        display: block;
    }

    .how-to-use-page .nurse-reference .nurse-reference-left {
        width: 100%;
        background: url('/public/assets/images/just_ask_peter_mobile_bg.webp') no-repeat center center;
        background-size: 100% 100%;
    }

    .how-to-use-page .nurse-reference .nurse-reference-left .nurse-reference-left-content {
        padding-left: 20px;
    }

    .how-to-use-page .nurse-reference .nurse-reference-middle {
        margin: 36px 20px;
        height: 4px;
        background: #EAE7F1;
        max-width: 100%;
    }

    .how-to-use-page .nurse-reference-right {
        max-width: 100%;
        padding: 0 20px;
    }

    .how-to-use-page .nurse-reference-right .nurse-support-contianer-wrap .call-toll-free .content-text {
        width: auto;
    }

    .how-to-use-page .nurse-reference-right .nurse-support-contianer-wrap .call-toll-free.show_desktop {
        display: none;
    }

    .how-to-use-page .nurse-reference-right .nurse-support-contianer-wrap .call-toll-free {
        justify-content: flex-start;
    }

    .how-to-use-page .nurse-reference-right .nurse-support-contianer-wrap .nurse-support-contiainer-wrap-content {
        margin-top: 10px;
    }

    .how-to-use-page .use-inbrija-as-needed .use-inbrija-as-needed-container .use-inbrija-as-needed-container-content h3 {
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 28px;
    }
}

@media (max-width:767px) {
    .how-to-use-page .hero-section {
        margin-top: 71px;
        flex-direction: column-reverse;
        padding: 0;
        flex-wrap: wrap;
        gap: 0px;
    }

    .how-to-use-page .hero-section .hero-section-image {
        max-width: 100%;
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .how-to-use-page .hero-section .hero-section-content {
        max-width: 100%;
        padding: 40px 20px;
        display: block;
    }

    .how-to-use-page .hero-section .hero-section-content h1 {
        font-size: 40px;
        line-height: 47px;
        margin-bottom: 18px;
    }

    .how-to-use-page .hero-section .hero-section-content h1 span {
        line-height: 40px;
    }

    .how-to-use-page .hero-section .hero-section-content h4 {
        font-weight: 300;
        font-size: 22px;
        margin-bottom: 0;
    }

    .how-to-use-page .hero-section .hero-section-image img {
        max-width: 344px;
        margin: auto;
        margin-right: 0;
    }

    .how-to-use-page .hero-section .hero-section-image .actor_portrayal {
        right: auto;
        left: 20px;
    }

    .how-to-use-page .how-to-use-inbrija {
        padding: 41px 20px 21px;
    }

    .how-to-use-page .how-to-use-inbrija h3 {
        font-size: 28px;
        line-height: 32px;
    }

    .how-to-use-page .how-to-use-inbrija p {
        font-size: 16px;
        line-height: 20px;
    }

    .how-to-use-page .demo-video .transcript {
        margin-top: 0;
        padding: 0 10px;
        gap: 10px;
    }

    .how-to-use-page .demo-video .transcript a {
        font-size: 13px;
        line-height: 15px;
    }

    .how-to-use-page .demo-video .transcript a:first-child {
        text-align: left;
    }

    .how-to-use-page .demo-video .transcript a:last-child {
        width: 114px;
        text-align: right;
    }

    .how-to-use-page .nurse-reference .nurse-reference-left h3 {
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 10px;
    }

    .how-to-use-page .nurse-reference .nurse-reference-left p {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 20px;
        padding: 0px 40px;
    }

    .how-to-use-page .nurse-reference-right .nurse-support-contianer-wrap {
        display: block;
    }

    .how-to-use-page .nurse-reference-right .nurse-support-contianer-wrap .nurse-support-contiainer-wrap-content h3 {
        font-size: 16px;
        line-height: 20px;
    }

    .how-to-use-page .nurse-reference-right .nurse-support-contianer-wrap .call-toll-free {
        display: block;
        margin-top: 0;
        padding-top: 0;
    }

    .how-to-use-page .nurse-reference-right .nurse-support-contianer-wrap .call-toll-free.show_mobile {
        display: block;
    }

    .how-to-use-page .nurse-reference-right .nurse-support-contianer-wrap .nurse-support-contiainer-wrap-image {
        margin: auto;
    }

    .how-to-use-page .nurse-reference-right .nurse-support-contianer-wrap .nurse-support-contiainer-wrap-content h3 {
        text-align: center;
        margin-bottom: 13px;
    }

    .how-to-use-page .nurse-reference-right .nurse-support-contianer-wrap .nurse-support-contiainer-wrap-content {
        max-width: 100%;
    }

    .how-to-use-page .nurse-reference-right .nurse-support-contianer-wrap .nurse-support-contiainer-wrap-content {
        margin-top: 15px;
    }

    .how-to-use-page .nurse-reference-right .nurse-support-contianer-wrap .call-toll-free .content-text {
        max-width: 100%;
        font-size: 28px;
        line-height: 32px;
        font-weight: 400;
        margin-bottom: 26px;
    }

    .how-to-use-page .nurse-reference-right .nurse-support-contianer-wrap .call-toll-free .content-number a {
        margin-top: 0;
        font-weight: 700;
        font-size: 23.2771px;
        line-height: 28px;
        margin: auto;
        margin-bottom: 10px;
    }

    .how-to-use-page .use-inbrija-as-needed {
        background: none;
        padding: 0;
        padding-top: 20px;
    }

    .how-to-use-page .use-inbrija-as-needed .use-inbrija-as-needed-container {
        display: block;
        padding: 0;
    }

    .how-to-use-page .use-inbrija-as-needed .use-inbrija-as-needed-container .use-inbrija-as-needed-container-image,
    .how-to-use-page .use-inbrija-as-needed .use-inbrija-as-needed-container .use-inbrija-as-needed-container-content {
        max-width: 100%;
        width: 100%;
    }

    .how-to-use-page .use-inbrija-as-needed .use-inbrija-as-needed-container .use-inbrija-as-needed-container-content {
        padding: 67px 20px 38px;
    }

    .how-to-use-page .use-inbrija-as-needed .use-inbrija-as-needed-container .use-inbrija-as-needed-container-content ul {
        padding-left: 0;
        list-style: none;
    }

    .how-to-use-page .use-inbrija-as-needed .use-inbrija-as-needed-container .use-inbrija-as-needed-container-content ul li {
        padding-left: 1.5em;
        position: relative;
    }

    .how-to-use-page .use-inbrija-as-needed .use-inbrija-as-needed-container .use-inbrija-as-needed-container-content ul li::after {
        content: '•';
        text-align: center;
        position: absolute;
        left: 0;
        width: 25px;
        top: 0;
    }

    .how-to-use-page .helpful-hints {
        padding: 41px 0 135px;
    }

    .how-to-use-page .helpful-hints h3 {
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 21px;
    }

    .how-to-use-page .helpful-hints .helpful-hints-contianer {
        padding: 20px 8px 60px;
    }

    .how-to-use-page .helpful-hints .helpful-hints-contianer>ul li {
        /* gap: 8px; */
    }

    .how-to-use-page .helpful-hints .helpful-hints-contianer>ul li img {
        width: 29px;
    }

    .how-to-use-page .helpful-hints .helpful-hints-contianer>ul li .content {
        font-size: 16px;
        line-height: 24px;
        margin-top: 0px;
        margin-bottom: 4px;
        max-width: calc(100% - 9px - 29px);
        margin-left: 8px;
    }

    .how-to-use-page .helpful-hints .helpful-hints-contianer>ul li {
        margin-bottom: 0px;
    }

    .how-to-use-page .helpful-hints .helpful-hints-contianer>ul {
        margin-bottom: 30px;
    }

    .how-to-use-page .helpful-hints::before {
        height: 300px;
        right: 0;
    }

    .how-to-use-page .helpful-hints .helpful-hints-contianer>ul ul {
        padding-left: 20px;
        list-style-position: inside;
        text-indent: -1em;
    }

    .how-to-use-page .helpful-hints .helpful-hints-contianer>ul li .content .show_mobile {
        display: inline-block;
    }

    .how-to-use-page .helpful-hints .helpful-hints-contianer>ul li .content a {
        text-decoration: none !important;
    }

    .how-to-use-page .helpful-hints .helpful-hints-contianer>ul li .content ul b {
    }

    .how-to-use-page .helpful-hints::after {
        content: 'Actor portrayal.';
        position: absolute;
        bottom: 19px;
        color: #FFFFFF;
        left: 5%;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        text-align: left;
        letter-spacing: -0.02em;
        display: block;
    }
    .how-to-use-page .jimmy-video-container {
        display: flex;
        justify-content: space-between;
        max-width: 1256px;
        width: 100%;
        align-items: center;
        margin: auto;
        gap: 32px;
        flex-direction: column-reverse;
    }
    .how-to-use-page .jimmy-video-container-content {
        max-width: 100%;
        width: 100%;
        text-align: left;
        padding-left: 39px;
        padding-right: 20px;
    }
    .how-to-use-page .jimmy-video-container-content h1 {
        font-style: normal;
        font-weight: 400;
        font-size: 36px;
        line-height: 36px;
        letter-spacing: -0.02em;
        text-transform: uppercase;
        color: #FFFFFF;
    }
    .how-to-use-page .inbrija-fight h1 {
        font-weight: 400;
        font-size: 28px;
        line-height: 32px;
    }
    .how-to-use-page .inbrija-fight {
        padding-left: 25px;
        padding-right: 25px;
    }
}

@media (max-width:454px) {
    .how-to-use-page .when-to-use-inbrija .when-to-use-inbrija-container .when-to-use-left-side {
        padding-bottom: 20px;
    }

    .how-to-use-page .when-to-use-inbrija .when-to-use-inbrija-container .when-to-use-left-side a {
        display: block !important;
        margin: 0px 5px 20px !important;
    }

    .how-to-use-page .when-to-use-inbrija .when-to-use-inbrija-container .when-to-use-left-side a.show_mobile {
        margin-right: 0;
    }

    .how-to-use-page .when-to-use-inbrija .when-to-use-inbrija-container .when-to-use-left-side h2 {
        font-size: 54px;
        line-height: 54px;
    }

    .how-to-use-page .helpful-hints .helpful-hints-contianer .primary-link {
        padding: 14.5px 9px 14.5px 12px;
        gap: 5px;
    }

    .how-to-use-page .helpful-hints::before {
        background: url("/public/assets/images/Tennis-Supporting.webp") no-repeat bottom center;
        height: 320px;
        background-size: cover;
        width: 100%;
    }

    .how-to-use-page .when-to-use-inbrija .when-to-use-inbrija-container .when-to-use-left-side h3 {
        font-size: 34px;
        line-height: 42px;
        margin-top: -15px;
    }
    .how-to-use-page .hero-section .hero-section-image img {
        max-width: 100%;
        margin: auto;
        margin-right: 0;
    }
}