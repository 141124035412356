.people-on-inbrija .flex-start.menu-section .dropdown:nth-child(6) .menu {
    font-weight: 600;
    color: #fff
}

.people-on-inbrija .flex-start.menu-section .dropdown:nth-child(6) .menu:after {
    display: block;
}

.people-on-inbrija .inbrija-stories-header {
    padding-top: 55px;
    padding-bottom: 50px;
    text-align: left;
}

.people-on-inbrija .inbrija-stories-header h1 {
    font-style: normal;
    font-weight: 300 !important;
    font-size: 51px;
    line-height: 60px;
    color: #FFFFFF;
    margin-bottom: 12px;
    max-width: 88.3%;
}

.people-on-inbrija .inbrija-stories-header h1 span {
    font-family: 'Cigarettes and Coffee';
    color: #FFB423;
    font-weight: 400;
    line-height: 51px;
}

.people-on-inbrija .inbrija-stories-header h6 {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    margin-top: 0;
    margin-bottom: 0;
    color: #FFFFFF;
    max-width: 54.5%;
}

.people-on-inbrija .still-have-ques {
    background: url('/public/assets/images/nurse-educator-frame.webp') center no-repeat;
    padding-top: 100px;
    padding-bottom: 60px;
    max-width: 1211px;
    width: 100%;
    margin: 50px auto 36px;
    background-size: contain;
}

.people-on-inbrija .still-have-ques .still-have-ques-text {
    max-width: 55%;
    margin: auto;
    margin-right: 14%;
}

.people-on-inbrija .still-have-ques .still-have-ques-text-actor {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.01em;
    margin-bottom: 13px;
    color: #4B3F67;
}

.people-on-inbrija .still-have-ques h1 {
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
    color: #4B3F67;
    margin-bottom: 15px;
}

.people-on-inbrija .still-have-ques .actor-potyaral {
    position: relative;
    left: -296px;
    text-align-last: left;
    top: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    letter-spacing: -0.02em;
    color: #4B3F67;
}

.people-on-inbrija .call-toll-free {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: #4B3F67;
    max-width: 360px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16.5px;
}

.people-on-inbrija .call-toll-free .primary-button {
    background: #4B3F67;
    border-radius: 100px;
    border: none;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    letter-spacing: -0.02em;
    text-transform: capitalize;
    color: #FFFFFF !important;
    text-decoration: none !important;
    padding: 12.5px 24px 12.5px;
}

.people-on-inbrija .call-toll-free .primary-button:hover{
    background: #7C6991
}

.people-on-inbrija .still-have-ques .small-call {
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    color: #4B3F67;
}

.people-on-inbrija .still-have-ques .small-call small{
    font-size: 10px;
    line-height: 15px;
    text-align: center;
    color: #4B3F67;
}

.people-on-inbrija .notes-section {
    max-width: 783px;
    padding: 0px 20px;
    margin: auto;
    margin-bottom: 70px;
}

.people-on-inbrija .notes-section p {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #4E4E4E;
    margin-bottom: 16px;
}

.people-on-inbrija .notes-section p:last-child {
    margin-bottom: 0;
}

.people-on-inbrija .resources-section {
    width: 100%;
    margin: auto;
    position: relative;
}

.people-on-inbrija .resources-section .resources-container-title {
    background: #fff;
    position: relative;
}

.people-on-inbrija .resources-section .resources-container-title h2.resources-section-heading {
    padding: 0 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
    color: #4B3F67;
    position: relative;
    z-index: 2;
    padding: 0px 20px 130px;
}

.people-on-inbrija .resources-section .resources-container-download {
    background: #EAE7F1;
    margin-top: -103px;
}

.people-on-inbrija .resources-section .resources-container-download .people-brochure {
    max-width: 1352px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0px 20px;
    /* gap: 20px; */
    margin: auto;
}

.people-on-inbrija .brochure-container .image-overlay .brochure-button {
    position: absolute;
    margin-top: 10px;
}

.people-on-inbrija .saving-container {
    padding: 93px 0 73px;
    margin: 0;
}

.people-on-inbrija .saving-container h3 {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 42px;
    color: #4B3F67;
    margin-bottom: 50px;
}

.people-on-inbrija .saving-items {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 40px;
    flex-wrap: wrap;
}

.people-on-inbrija .saving-items .saving-item {
    width: calc(100% / 2 - 20px);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 26px;
    align-items: flex-start;
}

.people-on-inbrija .saving-items .saving-item img {
    max-width: 126px;
    width: 100%;
}

.people-on-inbrija .saving-items .saving-item .saving-text {
    max-width: calc(100% - 126px - 26px);
    width: 100%;
}

.people-on-inbrija .saving-items .saving-item .saving-text p {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
    text-align: left;
    margin-bottom: 10px;
}

.people-on-inbrija .saving-items .saving-item .saving-text p.note {
    margin-bottom: 0;
    font-size: 15px;
    font-style: italic;
    line-height: 18px;
}

.people-on-inbrija .safety-info-offperiods {
    max-width: 901px;
    width: 100%;
    text-align: left;
    margin: 66px auto 56px;
    padding: 0px 20px
}

.people-on-inbrija .safety-info-offperiods h6 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #7C6991;
    margin-bottom: 12px;

}

.people-on-inbrija .safety-info-offperiods h6.text-underline {
    font-size: 18px;
    margin-bottom: 0;
}

.people-on-inbrija .safety-info-offperiods ul {
    padding-left: 1.5em;
    margin-bottom: 0;
    padding-bottom: 4px;
}

.people-on-inbrija .safety-info-offperiods li {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #4E4E4E;
    margin-bottom: 8px;
}

.people-on-inbrija .safety-info-offperiods li::marker {
    color: #7C6991
}

.people-on-inbrija hr {
    border-top: 4px solid #EAE7F1;
    opacity: 1;
}

.people-on-inbrija .org-help-container {
    padding: 74px 0 39px;
}

.people-on-inbrija .org-help-container h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
    color: #4B3F67;
    margin-bottom: 12px;
    max-width: 868px;
    margin: auto;
    margin-bottom: 42px;
}

.people-on-inbrija .org-help-container ul {
    margin-bottom: 0;
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 36px;
}

.people-on-inbrija .org-help-container ul li {
    max-width: 40%;
    width: 100%;
}

.people-on-inbrija .org-help-container ul li:first-child,
.people-on-inbrija .org-help-container ul li:last-child {
    max-width: 30%;
    width: 100%;
}

.people-on-inbrija .tips-container {
    background: linear-gradient(126.47deg, #090913 0%, #1B222A 59.65%);
}

.people-on-inbrija .tips-container .container {
    padding: 92px 0 44px;
    text-align: left;
    background-size: 491px 520px;
    position: relative;
}

.people-on-inbrija .tips-container .container img{
    position: absolute;
    top: 0;
    right: 0;
    max-width: 40%;
}
.people-on-inbrija .tips-container .container h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 42px;
    color: #FFFFFF;
    max-width: 498px;
    margin-bottom: 57px;
}

.people-on-inbrija .tips-container .container h5 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    margin-bottom: 10px;
}

.people-on-inbrija .tips-container .container p {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #FFFFFF;
    margin-bottom: 10px;
}

.people-on-inbrija .tips-container .container p a {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #FFFFFF;
    margin-bottom: 10px;
    text-decoration: underline;
}

.people-on-inbrija .tips-container .container p.note {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: #FFFFFF;
    margin-bottom: 0;

}

.people-on-inbrija .tips-container .max-width-645 {
    max-width: 645px;
    margin-bottom: 32px;
}

.people-on-inbrija .tips-container .max-width-780 {
    max-width: 780px;
    margin-bottom: 32px;
}

.people-on-inbrija .tips-container .max-width-100 {
    margin-bottom: 32px;
}

.people-on-inbrija .tips-container .container ul {
    padding-left: 1.5em;
    margin-bottom: 10px;
}

.people-on-inbrija .tips-container .container ul li {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #FFFFFF;
    margin-bottom: 8px;
}

.people-on-inbrija .peter-sm {
    position: relative;
    font-size: 11px;
    top: -2px;
}
@media (min-width:991px) and (max-width:1200px){
    .people-on-inbrija .brochure-container .brochure-image .brochure-image-img{background-position-x: 120% !important;}
    .people-on-inbrija .brochure-container .image-overlay{padding-left: 20px;}
    .people-on-inbrija .brochure-container .brochure-content{
        width: 100%;
    }
}
@media (max-width:1024px) {
    .people-on-inbrija .brochure-container .brochure-image .brochure-image-img{
      background-position-x:right !important;
      text-align: right;
    }
    .people-on-inbrija .brochure-container .image-overlay .brochure-heading-content{
        width: 100% !important;
    }
  }
@media (min-width:768px) and (max-width:995px) {
    .people-on-inbrija .still-have-ques h1 {
        font-size: 28px;
        line-height: 32px;
    }
    .people-on-inbrija .tips-container .container {
        padding: 92px 0 44px;
        text-align: left;
        background-size: 300px 360px;
    }

    .people-on-inbrija .tips-container .max-width-645 {
        max-width: 555px;
        margin-bottom: 32px;
    }
    .people-on-inbrija .still-have-ques .actor-potyaral {
        position: relative;
        left: -211px;
        text-align-last: left;
        top: -15px;
    }
}

@media (max-width:767px) {
    .people-on-inbrija .inbrija-stories-header {
        padding-top: 44px;
        padding-bottom: 80px;
        text-align: left;
    }

    .people-on-inbrija .inbrija-stories-header h1 {
        font-size: 32px;
        line-height: 38px;
        margin-bottom: 8px;
        max-width: 100%;
    }

    .people-on-inbrija .inbrija-stories-header h1 span {
        line-height: 32px;
    }

    .people-on-inbrija .inbrija-stories-header h6 {
        font-size: 16px;
        line-height: 20px;
        max-width: 100%;
    }

    .people-on-inbrija .still-have-ques {
        margin-top: 20px;
        padding: 0;
        background: none;
    }

    .people-on-inbrija .still-have-ques .still-have-ques-text {
        max-width: calc(100%);
        padding: 0 20px;
        margin: 0;
    }

    .people-on-inbrija .still-have-ques .still-have-ques-text img {
        margin: auto;
        margin-bottom: 15px;
        max-width: 50%;
        width: 100%;
    }

    .people-on-inbrija .still-have-ques h1 {
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 13px;
    }

    .people-on-inbrija .call-toll-free {
        display: block;
        width: 100%;
        text-align: center;
        margin-bottom: 0px;
    }

    .people-on-inbrija .call-toll-free span {
        display: block;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 13px;

    }

    .people-on-inbrija .call-toll-free a {
        display: inline-block;
        width: auto;
        margin-bottom: 13px;
    }

    .people-on-inbrija .still-have-ques .small-call {
        max-width: 140px;
        margin: auto;
    }  

    
    .people-on-inbrija .resources-section {
        padding-top: 45px;
    }

    .people-on-inbrija .resources-section .resources-container-title h2.resources-section-heading {
        font-size: 28px;
        line-height: 32px;
        margin: 0;
        padding: 0;
        margin-bottom: 39px;
    }

    .people-on-inbrija .resources-section .resources-container-download {
        margin-top: 0;
        background: none;
    }

    .people-on-inbrija .brochure-container .brochure-heading {
        font-size: 20px;
        line-height: 24px;
    }

    .people-on-inbrija .brochure-container .image-overlay .brochure-button {
        position: static;
        margin-top: 24px;
    }

    .people-on-inbrija .saving-container {
        margin: 65px 0;
        padding: 33px 0 55px;
        background: #EAE7F1;
    }

    .people-on-inbrija .saving-items .saving-item {
        width: 100%;
        gap: 16px;
    }

    .people-on-inbrija .saving-items .saving-item img {
        width: 99px;
    }

    .people-on-inbrija .saving-items .saving-item .saving-text {
        max-width: calc(100% - 99px - 16px);
    }

    .people-on-inbrija .saving-items .saving-item .saving-text p,
    .people-on-inbrija .saving-items .saving-item .saving-text p.note {
        font-size: 16px;
        line-height: 20px;
    }

    .people-on-inbrija .safety-info-offperiods {
        margin: 65px auto 65px;
    }

    .people-on-inbrija .safety-info-offperiods li b {

    }

    .people-on-inbrija hr {
        max-width: calc(100% - 40px) !important;
    }

    .people-on-inbrija .org-help-container {
        padding: 66px 0 41px;
    }

    .people-on-inbrija .org-help-container h2 {
        font-size: 28px;
        line-height: 32px;
    }

    .people-on-inbrija .org-help-container ul {
        margin-bottom: 25px;
        gap: 25px;
    }

    .people-on-inbrija .org-help-container ul li {
        max-width: 100% !important;
    }

    .people-on-inbrija .saving-container h3 {
        padding: 0 20px;
    }
   
    .people-on-inbrija .tips-container .container {
        background:none;
        padding: 92px 0 44px;
        text-align: left;
        background-size: 300px 360px;
    }
    .people-on-inbrija .tips-container .container img{
        position: static;
        max-width: 250px;
        width: 100%;
        margin: auto;
    }
    .people-on-inbrija .brochure-container .brochure-image{
        display: none
    }
    .people-on-inbrija .brochure-container .image-overlay{
        max-width: 100%;
    }
}