.homepage .hero-section {
    margin-top: 15px;
    max-width: 100%;
    width: 100%;
    max-width: 1290px;
    margin: auto;
    position: relative;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.homepage .hero-section .hero-section-image {
    max-width: 431px;
    width: 100%;
    text-align: center;
    position: relative;
    padding-bottom: 5px;
    right: 0;
    max-height: 475px;
    height: 100%;
}

.homepage .hero-section .hero-section-image .actor_portrayal {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    letter-spacing: -0.02em;
    position: absolute;
    right: 0;
    bottom: 29px;
}

.homepage .hero-section .hero-section-content {
    max-width: 585px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-content: center;
    padding-right: 0px;
    padding-bottom: 20px;
    height: 100%;
    left: 0;
}

.homepage .hero-section .hero-section-content h4 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    margin-bottom: 12px;
}

.homepage .hero-section .hero-section-content h4 span {
    color: #FFB423;
    font-style: italic;
}

.homepage .hero-section .hero-section-content h4 sup {
    font-weight: 700;
    font-size: 13px;
    line-height: 12px;
    top: -6px;
}

.homepage .hero-section .hero-section-content h6 {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #FFFFFF;
    margin-bottom: 32px;
    width: 100%;
    text-align: left;
}

.homepage .hero-section .hero-section-content h1 {
    font-weight: 400;
    font-size: 64px;
    line-height: 75px;
    letter-spacing: -0.02em;
    margin-bottom: 12px;
    text-align: left;
}

.homepage .hero-section .hero-section-content h1 span {
    font-family: 'Cigarettes and Coffee';
    color: #FFB423;
    font-weight: 400;
    line-height: 49px;
}

.homepage .hero-section .hero-section-content a {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: #000000 !important;
    display: inline-block;
    width: auto;
    background: url('/public/assets/images/design-button-background.webp');
    background-size: cover;
    width: 226px;
    height: 50px;
    padding: 14px 0px 17px 0px;
    text-decoration: none !important;
}

.homepage .hero-section .hero-section-content a:hover {
    background: url('/public/assets/images/hover-image.webp');
}

.homepage .rachel-section {
    margin: 51px 0;
}

.homepage .rachel-section .rachel-intro-section h2 {
    font-weight: 400;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
    color: #4B3F67;
    margin-bottom: 17px;
}

.homepage .rachel-section .rachel-intro-section h5 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #4B3F67;
    border-bottom: 4px solid #EAE7F1;
    padding-bottom: 35px;
    margin-bottom: 35px;
}

.homepage .rachel-section .rachel-video-section {
    margin-bottom: 40px;
}

.homepage .rachel-section .rachel-video-section p {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #5A5A5A;
    max-width: 980px;
    margin: 0 auto 35px;
}

.homepage .rachel-section .rachel-video-section video {
    width: 868px;
    max-width: 100%;
}

.homepage .rachel-section .see-more-inbrija-stories-section {
    max-width: 940px;
    width: 100%;
    margin: auto;
    background: url("/public/assets/images/storie-background.webp") no-repeat center center;
    padding: 50px 20px 0 70px;
    background-size: contain;
}

.homepage .rachel-section .see-more-inbrija-stories-section h5 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #4B3F67;
    margin: auto;
    margin-bottom: 12px;
}

.homepage .rachel-section .see-more-inbrija-stories-section a {
    padding: 14.5px 24px;
    background: #4B3F67;
    border-radius: 100px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: #FFFFFF !important;
    cursor: pointer;
    text-decoration: none !important;
    display: inline-block;
    position: relative;
    top: 5px;
}

.homepage .rachel-section .see-more-inbrija-stories-section a:hover {
    background: #7C6991;
}

.homepage .rachel-video-middle-section {
    background: linear-gradient(282.03deg, #12151E 32.29%, #2A313A 112.48%);
    padding: 64px 0 72px;
}

.homepage .rachel-video-middle-section .rachel-video-middle-section-content {
    max-width: 1355px;
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 20px;
    gap: 29px;
}

.homepage .rachel-video-middle-section .rachel-video-middle-section-content .rachel-video-middle-section-content-video {
    max-width: 49.12%;
    width: 100%;
}

.homepage .rachel-video-middle-section .rachel-video-middle-section-content .rachel-video-middle-section-content-text {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;

    max-width: calc(100% - 49.12% - 29px);
    width: 100%;
}

.homepage .rachel-video-middle-section .rachel-video-middle-section-content .rachel-video-middle-section-content-text .rachel-quotes-msg p {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    letter-spacing: -0.02em;
    color: rgba(255, 255, 255, 0.6);
    margin-bottom: 0;
}

.homepage .rachel-video-middle-section .rachel-video-middle-section-content .rachel-video-middle-section-content-text .rachel-quotes-msg p.small {
    font-size: 18px;
    line-height: 24px;
    margin-top: 12px;
}

.homepage .rachel-video-middle-section .rachel-video-middle-section-content .rachel-video-middle-section-content-text .rachel-i-feel-back-on {
    margin-top: 41px;
}

.homepage .rachel-video-middle-section .rachel-video-middle-section-content .rachel-video-middle-section-content-text .rachel-i-feel-back-on p {
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;
    letter-spacing: 0.23em;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-bottom: 21px;
}

.homepage .rachel-video-middle-section .rachel-video-middle-section-content .rachel-video-middle-section-content-text .rachel-i-feel-back-on .rachel-text {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: rgba(255, 255, 255, 0.6);
    margin-bottom: 41px;
}

.homepage .rachel-video-middle-section .rachel-video-middle-section-content .rachel-video-middle-section-content-text .rachel-i-feel-back-on img {
    position: relative;
    top: -20px;
    max-height: 20px;
}

.homepage .rachel-video-middle-section .rachel-video-middle-section-content .rachel-video-middle-section-content-text .rachel-i-feel-back-on p.i-feel-back-on {
    font-weight: 400;
    font-size: 61.2992px;
    line-height: 61px;
    text-transform: uppercase;
    color: #FFB423;
    font-family: 'Cigarettes and Coffee';
    margin-bottom: 8.5px;
    letter-spacing: 0;
}

.homepage .rachel-video-middle-section .rachel-video-middle-section-content .rachel-video-middle-section-content-text .rachel-i-feel-back-on a {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    padding: 12px 24px;
    border: 1px solid #F9C606;
    border-radius: 100px;
    background: transparent;
    text-decoration: none !important;
    display: inline-block;
}

.homepage .rachel-video-middle-section .rachel-video-middle-section-content .rachel-video-middle-section-content-text .rachel-i-feel-back-on a:hover {
    color: #FFB423;
}

.homepage .safety-info-homepage {
    width: 100%;
    text-align: left;
    margin: 40px auto 40px;
}

.homepage .safety-info-homepage h6 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #7C6991;
    margin-bottom: 6px;

}

.homepage .safety-info-homepage h6.text-underline {
    font-size: 18px;
    text-decoration-skip-ink: none !important;
}

.homepage .safety-info-homepage h5 {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #4E4E4E;
    margin-bottom: 15px;
}

.homepage .safety-info-homepage ul {
    padding-left: 1.5em;
    margin-bottom: 0;
    padding-bottom: 8px;
}

.homepage .safety-info-homepage li::marker {
    color: #7C6991
}

.homepage .safety-info-homepage li {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #4E4E4E;
    margin-bottom: 6px;
}

.homepage .swimmer-wrapper {
    background: linear-gradient(282.03deg, #12151E 32.29%, #2A313A 112.48%);
}

.homepage .swimmer-section {
    padding: 87px 0 77px;
    position: relative;
}

.homepage .swimmer-section::after {
    content: '';
    background-image: url('/public/assets/images/INBRIJA_HERO.webp');
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: contain;
    width: calc(100% - 57.57%);
    height: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
}

.homepage .swimmer-section .actor-portayal {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    letter-spacing: -0.02em;
    position: absolute;
    right: 0;
    bottom: 27px;
}

.homepage .swimmer-section .swimmer-section-contnent {
    max-width: 1355px;
    padding: 20px;
    margin: auto;
}

.homepage .swimmer-section .swimmer-section-contnent-text {
    max-width: 57.57%;
    margin: auto;
    margin-right: 0;

}

.homepage .swimmer-section .swimmer-section-contnent h3 {
    font-weight: 400;
    font-size: 36px;
    line-height: 42px;
    color: #FFFFFF;
    margin-bottom: 12px;
    text-align: left;
}

.homepage .swimmer-section .swimmer-section-contnent p {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #FFFFFF;
    margin-bottom: 8px;
    text-align: left;
}

.homepage .swimmer-section .swimmer-section-contnent p:last-child {
    margin-bottom: 0;
}

.homepage .symptom {
    background: linear-gradient(282.03deg, #12151E 32.29%, #2A313A 112.48%);
    display: flex;
}

.homepage .symptom img {
    max-width: 500px;
    display: block;
}

.homepage .symptom .symptom-home {
    min-height: 428px;
    max-width: 100%;
    width: 100%;
    display: flex;
}

.homepage .symptom .symptom-home .symptom-home-img {
    text-align: left;
    position: relative;
}

.homepage .symptom .symptom-home .symptom-home-img img {
    display: block;
    height: 100%;
    max-width: 525px;
    width: 100%;
    max-height: 496px;
}


.homepage .symptom .symptom-home .symptom-home-img .actor_portrayal {
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    text-align: right;
    letter-spacing: -0.02em;
    position: absolute;
    right: -80px;
    display: block;
    left: 0;
    bottom: 15px;
    color: #FFFFFF;
}

.homepage .symptom .symptom-home .content {
    max-width: 818px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-content: center;
    color: #FFFFFF;
    padding-top: 73px;
    padding-left: 92px;
    padding-bottom: 73px;
}

.homepage .symptom .symptom-home .content .highlited-text {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
    text-align: left;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    color: #F9C606;
    margin-bottom: 0px;
}

.homepage .symptom .symptom-home .content h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 42px;
    text-align: left;
    margin-top: 12px;
    color: #FFFFFF;
    margin-bottom: 0px;
}

.homepage .symptom .symptom-home .content p {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: left;
    margin-top: 12px;
    color: #FFFFFF;
    margin-bottom: 0px;
}

.homepage .symptom .symptom-home .content button {
    border: 1px solid #F9C606;
    border-radius: 100px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    padding: 12px 24px;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    margin-top: 32px;
    background: none;
    position: relative;
    z-index: 1000 ;
}

.homepage .symptom .symptom-home .content button:hover {
    color: #FFB423;
}

.homepage .jimmy-video-patient-name {
    position: relative;
}


.homepage .jimmy-video-patient-name .jimmy-video-patient {
    position: absolute;
    margin-right: 0;
    bottom: 22px;
    left: 32px;
}

.homepage .jimmy-video-patient-name .jimmy-video-patient>img {
    position: relative;
}

.homepage .jimmy-video-patient-name .jimmy-video-patient span {
    background-image: url('/public/assets/images/Play Button.webp');
    background-repeat: round;
    background-size: cover;
    width: 100%;
    padding-left: 20px;
    padding-right: 70px;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-top: 12px;
    margin-bottom: 12px;
    margin-left: -12px;
    font-style: normal;
    font-weight: 600;
    text-align: center;
    font-size: 16px;
    letter-spacing: -0.02em;
    color: #000000;
    line-height: 19px;
}

.homepage .jimmy-stories-img {
    width: 100%;
    max-width: 640px;
}

@media (max-width:1150px) and (min-width:1024px) {
    .homepage .symptom-home .symptom-home-img {
        max-width: 450px;
    }

    .homepage .symptom .symptom-home .symptom-home-img img {
        max-width: 100%;
        height: auto;
        display: block;
        height: 100%;
    }

}

@media (max-width:1050px) and (min-width:900px) {
    .homepage .symptom-home {
        min-height: 418px;
    }

    .homepage .symptom .symptom-home {
        margin-top: 15px;
        min-height: 428px;
        max-width: 100%;
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
    }

    .homepage .symptom .symptom-home .symptom-home-img {
        max-width: 100%;
        width: 100%;
        text-align: right;
        position: relative;
    }

    .homepage .symptom .symptom-home .content {
        max-width: 818px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-content: center;
        color: #FFFFFF;
        padding-top: 73px;
        padding-left: 25px;
        padding-right: 25px;
        padding-bottom: 73px;
    }

    .homepage .symptom .symptom-home .symptom-home-img img {
        max-width: 100%;
        height: auto;
    }

    .homepage .symptom {
        background: linear-gradient(282.03deg, #12151E 32.29%, #2A313A 112.48%);
        display: flex;
        flex-wrap: wrap;
    }

}

@media (max-width:1050px) {
    .homepage .symptom .symptom-home .symptom-home-img img {
        max-width: 100%;
        height: auto;
        display: none;
        height: 100%;
    }
    .homepage .symptom .symptom-home .symptom-home-img .actor_portrayal {
        display: none;
    }
}

@media (max-width:899px) and (min-width:768px) {
    .homepage .symptom-home {
        min-height: auto;
    }

    .homepage .symptom .symptom-home {
        margin-top: 15px;
        min-height: 428px;
        max-width: 100%;
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
    }

    .homepage .symptom .symptom-home .symptom-home-img {
        max-width: 100%;
        width: 100%;
        text-align: right;
        position: relative;
    }

    .homepage .symptom .symptom-home .content {
        max-width: 818px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-content: center;
        color: #FFFFFF;
        padding-top: 73px;
        padding-left: 25px;
        padding-right: 25px;
        padding-bottom: 73px;
    }
}


@media (max-width:767px) {
    .homepage .hero-section {
        margin-top: 0;
        padding-top: 22px;
        background: url('/public/assets/images/INBRIJA_SWIMMER_A_FINAL 1.webp') no-repeat right bottom;
        background-size: 50%;
        display: block;
    }

    .homepage .hero-section .hero-section-content {
        position: static;
        display: block;
        text-align: left;
        max-width: 100%;
    }

    .homepage .hero-section .hero-section-image {
        max-width: 100%;
        display: none;
    }

    .homepage .hero-section .hero-section-content h1 {
        max-width: 500px;
        font-weight: 400;
        font-size: 36px;
        line-height: 110%;
        letter-spacing: -0.02em;
        margin-bottom: 12px;
        max-width: 372px;
    }

    .homepage .hero-section .hero-section-content h1 span {
        display: inline-block;
        line-height: 36px;
    }

    .homepage .symptom img {
        display: none;
    }

    .homepage .hero-section .hero-section-content h4 {
        font-size: 18px;
        line-height: 24px;
    }

    .homepage .hero-section .hero-section-content h6 {
        width: 150px;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 200px;
    }

    .homepage .hero-section .hero-section-content a {
        display: none !important;
    }

    .homepage .hero-section .hero-section-content::after {
        content: 'Actor portrayal';
        position: absolute;
        bottom: 32px;
        left: 20px;
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        text-align: right;
        letter-spacing: -0.02em;
    }

    .homepage .rachel-section {
        margin-bottom: 46px;
    }

    .homepage .rachel-section .rachel-intro-section h2 {
        font-weight: 400;
        font-size: 28px;
        line-height: 32px;
        text-align: center;
        color: #4B3F67;
        margin-bottom: 10px;
    }

    .homepage .rachel-section .rachel-intro-section h5 {
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        color: #4B3F67;
    }

    .homepage .rachel-section .rachel-video-section p {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 60px;
    }

    .homepage .rachel-section .rachel-video-section video {
        max-width: calc(100% + 40px);
        margin: 0 -20px !important;
    }

    .homepage .rachel-section .rachel-video-section {
        margin-bottom: 32px;
    }

    .homepage .rachel-section .see-more-inbrija-stories-section {
        background: url("/public/assets/images/storie-background-mobile.webp") no-repeat center center;
        padding: 55px 10% 0;
        background-size: 100% 100%;
        width: calc(100% + 40px);
        margin: 0 -20px;
    }

    .homepage .rachel-section .see-more-inbrija-stories-section h5 {
        font-size: 18px;
        line-height: 24px;
    }

    .homepage .rachel-video-middle-section {
        padding: 40px 0;
    }

    .homepage .rachel-video-middle-section .rachel-video-middle-section-content {
        flex-direction: column;
    }

    .homepage .rachel-video-middle-section .rachel-video-middle-section-content .rachel-video-middle-section-content-text {
        max-width: 100%;
        ;
    }

    .homepage .rachel-video-middle-section .rachel-video-middle-section-content .rachel-video-middle-section-content-text .rachel-quotes-msg p {
        font-size: 18px;
        line-height: 24px;
    }

    .homepage .rachel-video-middle-section .rachel-video-middle-section-content .rachel-video-middle-section-content-text .rachel-quotes-msg p.small {
        margin-top: 14px;
    }

    .homepage .rachel-video-middle-section .rachel-video-middle-section-content .rachel-video-middle-section-content-text .rachel-i-feel-back-on {
        margin-top: 30px;
    }

    .homepage .rachel-video-middle-section .rachel-video-middle-section-content .rachel-video-middle-section-content-text .rachel-i-feel-back-on p {
        font-size: 17.2196px;
        line-height: 20px;
        letter-spacing: 0.23em;
        margin-bottom: 17px;
    }

    .homepage .rachel-video-middle-section .rachel-video-middle-section-content .rachel-video-middle-section-content-text .rachel-i-feel-back-on p.i-feel-back-on {
        font-size: 47.9795px;
        line-height: 48px;
        margin-bottom: 30px;
    }

    .homepage .rachel-video-middle-section .rachel-video-middle-section-content .rachel-video-middle-section-content-video {
        max-width: 100%;
    }

    .homepage .safety-info-homepage h6 {
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 12px;
    }

    .homepage .safety-info-homepage ul {
        list-style-type: none;
        padding-left: 0;
        padding-bottom: 4px;
    }

    .homepage .safety-info-homepage li {
        font-size: 16px;
        position: relative;
        padding-left: 15px;
    }

    .homepage .safety-info-homepage li::after {
        content: "●";
        font-size: 8px;
        color: #4E4E4E;
        position: absolute;
        left: 5px;
        top: 0;
    }

    .homepage .safety-info-homepage h6.text-underline {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
    }

    .homepage .swimmer-section {
        padding: 24px 0 154px;
    }

    .homepage .swimmer-section .swimmer-section-contnent-text {
        max-width: 100%;
    }

    .homepage .swimmer-section .swimmer-section-contnent h3 {
        font-size: 28px;
        line-height: 32px;
    }

    .homepage .swimmer-section .swimmer-section-contnent p {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 12px;
    }

    .homepage .swimmer-section .swimmer-section-contnent p:last-child {
        max-width: 61.26%;
        margin: auto;
        margin-left: 0;
    }

    .homepage .symptom-home {
        min-height: auto;
    }

    .homepage .symptom .symptom-home {
        margin-top: 15px;
        min-height: 428px;
        max-width: 100%;
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
    }

    .homepage .symptom .symptom-home .symptom-home-img {
        max-width: 100%;
        width: 100%;
        text-align: right;
        position: relative;
    }

    .homepage .symptom .symptom-home .content {
        max-width: 818px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-content: center;
        color: #FFFFFF;
        padding-top: 50px;
        padding-left: 20px;
        padding-right: 23px;
        padding-bottom: 64px;
    }
    .homepage .symptom .symptom-home .content h3 {
        font-weight: 400;
        font-size: 28px;
        line-height: 32px;
    }
    .homepage .symptom .symptom-home .content p {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
    }
    .homepage .symptom .symptom-home .content .highlited-text {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
    }
    
}

@media (min-width:768px) and (max-width:px) {}

@media (max-width:520px) and (min-width:451px) {
    .homepage .hero-section {
        background-size: 60%;
    }
}

@media (max-width:450px) {
    .homepage .hero-section {
        background-size: 70%;
    }

    .homepage .jimmy-video-patient-name .jimmy-video-patient {
        position: absolute;
        margin-right: 0;
        bottom: 10px;
        left: 0px;
    }
}

@media (max-width: 767px) {

    .homepage .jimmy-stories-img {
        max-width: 100% !important;
        margin-bottom: 12px;
        margin-top: 0;
    }

    .homepage .jimmy-video-patient-name .jimmy-video-patient {
        bottom: 16px;
    }

    .homepage .jimmy-video-patient-name .jimmy-video-patient img {
        max-width: 44px;
        max-height: 44px;
        margin-top: 0;
    }

    .homepage .jimmy-video-patient-name .jimmy-video-patient span {
        padding: 6px 12px 6px 15px;
        font-size: 11.6255px;
        line-height: 14px;
        margin-top: 9px;
        letter-spacing: 0;
    }
}


.hero-section-homepage {
    font-style: normal;
    font-weight: 400;
    font-size: 64px;
    line-height: 75px;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    margin-top: 45.32px;
    text-align: left;
}

.now-font {
    font-family: 'Cigarettes and Coffee';
    color: #FFB423;
}

.header-text {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    display: flex;
    text-align: left;
}

.sm {
    font-size: 12px;
    margin-top: -3px;
    color: #FFB423;
}

.left-align {
    text-align: left;
}

.hero-section-button {
    width: 224.62px;
    height: 49.52px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: #000000;
    padding-top: 14px;
    display: block;
    cursor: pointer;
    padding-left: 10px;
}

.header-subtext {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #FFFFFF;
    text-align: left;
}

.brush-style {
    height: 53.93px;
    margin-top: -15px;
    color: #FFB423;
    text-align: -webkit-center;
    background: url("/public/assets/images/Brush Style Breaker.webp") no-repeat;
    background-size: cover;
}

.brush-style-2{
    height: 53.93px;
    margin-top: -20px;
    color: #FFB423;
    text-align: -webkit-center;
    background: url("/public/assets/images/brush2.webp") no-repeat;
    background-size: cover;
    z-index: 1000;
    position: relative;
}

.brush-style-thankyou {
    height: 53.93px;
    margin-top: -15px;
    color: #FFB423;
    text-align: -webkit-center;
    background: url("/public/assets/images/Brush Style Breaker.webp") no-repeat;
    background-size: cover;
    z-index: 0;
}

.homepage-richeal-container {
    margin-top: 51.06px;
    margin-bottom: 51.06px;
}

.homepage-richeal-container h1 {
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
    color: #4B3F67;
}

.homepage-richeal-container h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #4B3F67;
    margin-top: 16.87px;
}

.homepage-richeal-container p {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #5A5A5A;
    max-width: 978px;
    width: 100%;
    margin-bottom: 0px;
}

.mt-035 {
    margin-top: 35px;
}

.homepage-saperator {
    border-top: 4px solid #EAE7F1;
    margin-top: 35px;
    margin-bottom: 35px;
}

.contact-us-saperator {
    border-top: 4px solid #EAE7F1;
    margin-top: 20px;
    margin-bottom: 40px;
}

.richael-story-container {
    margin-top: 40px;
}

.richael-story {
    max-width: 857px;
    width: 100%;
    height: 151px;
    background-repeat: round;
}

.richael-story p {
    max-width: 693px;
    width: 100%;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #4B3F67;
    padding-top: 50px;
}

.richael-story button {
    padding: 12px 24px;
    width: 234px;
    height: 48px;
    color: #FFFFFF;
    border: none;
    background: #4B3F67;
    border-radius: 100px;
    margin-top: 12px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
}

.rachel-quote-container {
    background: #12151E
}

.rachel-quote {
    max-width: calc(100% - 640px -31px);
}

.rachel-video-container {
    max-width: 640px;
    width: 100%;
    height: auto;
    background: linear-gradient(252.44deg, #A687C9 0%, rgba(124, 105, 145, 0.4) 100%);
}

.rachel-story-image {
    max-width: 492px;
    width: 100%;
    height: 360px;
    opacity: 0px;
    float: left;
}

.symptom-return-container-mobile {
    display: none;
}

.pt-9 {
    padding-top: 90px;
}

.pt-9 {
    padding-bottom: 90px;
}

.text-underline {
    text-decoration: underline;
    cursor: pointer;
    text-decoration-skip-ink: none !important;
}

.error h1 {
    font-style: normal;
    font-weight: 300;
    font-size: 51px;
    line-height: 60px;
    color: #FFFFFF;
    text-align: left;
}

.error h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 42px;
    text-align: left;
    color: #4B3F67;
}

.error p {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    text-align: left;
    line-height: 24px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #53565A;
}

.error-padding {
    padding-top: 30px;
}

.error-margin {
    border-bottom: 4px solid #EAE7F1;
    padding-bottom: 106px;
    margin-bottom: 30px;
    margin-top: 70px;
}

.contact-margin {
    border-bottom: 4px solid #EAE7F1;
    padding-bottom: 73px;
    margin-bottom: 92px;
    margin-top: 72px;
}

.story-author {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: rgba(255, 255, 255, 0.6);
}

.alt-image {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.background-image-new {
    background: url("/public/assets/images/contactus-img.webp") no-repeat;
    width: 100%;
    height: 500px;
    position: relative;
}

.contact-us-background {
    background: url('/public/assets/images/contactus-img.webp') no-repeat center right;
    max-width: 1500px;
    margin: auto;
}

.thankyou {
    margin-top: 78px;
    margin-bottom: 100px;
}

.thankyou h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
    color: #4B3F67;
}

.thankyou p {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    margin-top: 16px;
    color: #000000;
}

.thankyou button {
    height: auto;
    background: #4B3F67;
    border-radius: 100px;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 12px;
    margin-top: 36px;
    font-style: normal;
    font-weight: 700;
    border: none;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: -0.02em;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #FFFFFF;
    padding-bottom: 12px;
}

.thankyou-menu-padding {
    padding-bottom: 80px;
}

.thankyou-contact {
    background: #685778;
    padding-top: 50px;
    padding-bottom: 50px;
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 42px;
    align-items: center;
    text-align: center;
    margin-top: 100px;
    color: #FFFFFF;
}

.talk-button-mobile {
    display: none;
}

.rachel-quote h6 {
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;
    letter-spacing: 0.23em;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-top: 31px;
    margin-bottom: 0px;
}



.rachel-quote button {
    border: 1px solid #F9C606;
    border-radius: 100px;
    align-items: center;
    background: transparent;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
    margin-top: 41px;
    color: #FFFFFF;
    padding: 12px 24px;
}

.before-after-img {
    width: 100%;
    max-width: 868px;
}

.play-video {
    float: left;
    margin-top: 255.4px;
    margin-left: 26.5px;
}

.primary-color {
    color: #7C6991;
    font-weight: 700;
    text-decoration: underline;
    text-decoration-skip-ink: none !important;
}

.safety-info-homepage {
    width: 100%;
    text-align: left;
    margin: 40px auto 40px;
}

.safety-info-homepage h6 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #7C6991;
    margin-bottom: 6px;

}

.safety-info-homepage h6.text-underline {
    font-size: 18px;
    text-decoration-skip-ink: none !important;
}

.safety-info-homepage h5 {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #4E4E4E;
    margin-bottom: 15px;
}

.safety-info-homepage ul {
    padding-left: 1.5em;
    margin-bottom: 0;
    padding-bottom: 8px;
}

.safety-info-homepage li::marker {
    color: #7C6991
}

.safety-info-homepage li {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #4E4E4E;
    margin-bottom: 6px;
}

.symptom-return-container {
    background: linear-gradient(282.03deg, #12151E 32.29%, #2A313A 112.48%);
    display: flex;
    gap: 20px;
    justify-content: center;
}

.symptom-return-container img {
    margin-top: 64px;
}

.symptom-return-container h1 {
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 42px;
    color: #FFFFFF;
    margin-bottom: 0px;
    margin-top: 87px;
    max-width: 757px;
    width: 100%;
    text-align: left;
}

.symptom-return-container p {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #FFFFFF;
    max-width: 757px;
    width: 100%;
    text-align: left;
    margin-bottom: 0px;
    margin-top: 12px;
}

.contact-acorda-font {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #4E4E4E
}

.contact-acorda-spacing {
    margin-top: 56px;
}

.contact-acorda-font-bold {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #7C6991;
    text-decoration: underline;
}

@media screen and (max-width:1400px) {

    .homepage-richeal-container {
        margin-top: 51.06px;
        margin-bottom: 51.06px;
    }

    .symptom-return-container {
        background: linear-gradient(282.03deg, #12151E 32.29%, #2A313A 112.48%);
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
    }

    .richael-story {
        width: 100%;
        height: auto;
    }

    .richael-story p {
        width: 100%;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: #4B3F67;
        padding-top: 50px;
    }

    .homepage-richeal-container h1 {
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 32px;
        text-align: center;
        color: #4B3F67;
    }

    .homepage-richeal-container h3 {
        font-style: normal;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        color: #4B3F67;
        margin-top: 16.87px;
    }

    .homepage-richeal-container p {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #5A5A5A;
        width: 100%;
        margin-bottom: 0px;
    }
}


@media (min-width:992px) and (max-width:1200px) {
    .safety-info-container {
        max-width: 960px;
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .rachel-quote-section {
        max-width: 960px;
        padding-top: 64px;
        padding-bottom: 72px;
    }

    .rachel-video-container {
        max-width: 960px;
        height: auto;
        background: linear-gradient(252.44deg, #A687C9 0%, rgba(124, 105, 145, 0.4) 100%);
    }

    .rachel-quote-text {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        width: 100%;
        line-height: 28px;
        text-align: center;
        letter-spacing: -0.02em;
        color: rgba(255, 255, 255, 0.6);
    }

    .rachel-quote h1 {
        font-size: 41.2992px;
        font-family: 'Cigarettes and Coffee';
        font-style: normal;
        font-weight: 400;
        font-size: 41.2992px;
        line-height: 61px;
        text-transform: uppercase;
        color: #FFB423;
        margin-top: 21px;
        margin-bottom: 0px;
    }
}

@media (min-width:768px) and (max-width:992px) {
    .safety-info-container {
        max-width: 720px;
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .rachel-quote-section {
        max-width: 720px;
        padding-top: 64px;
        padding-bottom: 72px;
        display: flex;
        flex-wrap: wrap-reverse;
        padding: 0px;
        gap: 41px;
        padding-top: 64px;
        padding-bottom: 72px;
    }

    .rachel-quote-text {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        width: 100%;
        line-height: 28px;
        text-align: center;
        letter-spacing: -0.02em;
        color: rgba(255, 255, 255, 0.6);
    }

    .rachel-video-container {
        max-width: 720px;
        height: auto;
        background: linear-gradient(252.44deg, #A687C9 0%, rgba(124, 105, 145, 0.4) 100%);
    }

    .rachel-quote h1 {
        font-size: 41.2992px;
        font-family: 'Cigarettes and Coffee';
        font-style: normal;
        font-weight: 400;
        line-height: 61px;
        text-transform: uppercase;
        color: #FFB423;
        margin-top: 21px;
        margin-bottom: 0px;
    }
    .homepage .hero-section .hero-section-content {
        max-width: 500px;
        width: 100%;
    }
    .homepage .hero-section .hero-section-content h1 {
        font-size: 48px;
        line-height: 55px;
    }
}

@media (min-width:576px) and (max-width:768px) {
    .safety-info-container {
        max-width: 540px;
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .rachel-quote-text {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        width: 100%;
        line-height: 28px;
        text-align: center;
        letter-spacing: -0.02em;
        color: rgba(255, 255, 255, 0.6);
    }

    .rachel-quote-section {
        max-width: 540px;
        padding-top: 64px;
        padding-bottom: 72px;
        display: flex;
        flex-wrap: wrap-reverse;
        padding: 0px;
        gap: 41px;
        padding-top: 64px;
        padding-bottom: 72px;
    }

    .rachel-video-container {
        max-width: 540px;
        height: auto;
        background: linear-gradient(252.44deg, #A687C9 0%, rgba(124, 105, 145, 0.4) 100%);
    }

    .rachel-quote h1 {
        font-size: 41.2992px;
        font-family: 'Cigarettes and Coffee';
        font-style: normal;
        font-weight: 400;
        line-height: 61px;
        text-transform: uppercase;
        color: #FFB423;
        margin-top: 21px;
        margin-bottom: 0px;
    }
}

@media (max-width:576px) {
    .safety-info-container {
        width: 100%;
        padding-left: 20px;
        padding-right: 25px;
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .swimming-image {
        width: 100%;
        height: 285px;
        margin-top: -100px;
    }


    .rachel-story-image {
        width: 100%;
        height: auto;
        opacity: 0px;
        padding-left: 20px;
        padding-right: 25px;
    }

    .error-margin {
        border-bottom: 4px solid #EAE7F1;
        padding-bottom: 70px;
        margin-bottom: 59px;
        margin-top: 70px;
    }

    .rachel-quote-text {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        width: 100%;
        line-height: 28px;
        padding-left: 20px;
        padding-right: 25px;
        text-align: center;
        letter-spacing: -0.02em;
        color: rgba(255, 255, 255, 0.6);
    }

    .rachel-quote-section {
        width: 100%;
        color: #FFFFFF;
        display: flex;
        flex-wrap: wrap-reverse;
        padding: 0px;
        gap: 41px;
        padding-top: 64px;
        padding-bottom: 72px;
    }

    .rachel-video-container {
        max-width: 100%;
        height: auto;
        background: linear-gradient(252.44deg, #A687C9 0%, rgba(124, 105, 145, 0.4) 100%);
    }

    .rachel-quote-text {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        width: 100%;
        line-height: 28px;
        text-align: center;
        letter-spacing: -0.02em;
        color: rgba(255, 255, 255, 0.6);
    }

    .rachel-quote h1 {

        font-size: 41.2992px;
        font-family: 'Cigarettes and Coffee';
        font-style: normal;
        font-weight: 400;
        line-height: 61px;
        text-transform: uppercase;
        color: #FFB423;
        margin-top: 21px;
        margin-bottom: 0px;
    }

    .homepage .hero-section .hero-section-content h6 {
        width: 140px;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 200px;
    }

}


@media (min-width:1200px) {

    .safety-info-container {
        margin-top: 40px;
        margin-bottom: 40px;
        max-width: 1109px
    }

    .rachel-quote-section {
        max-width: 1140px;
        color: #FFFFFF;
        display: flex;
        flex-direction: row;
        padding: 0px;
        gap: 41px;
        padding-top: 64px;
        padding-bottom: 72px;
    }

    .rachel-quote-text {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        width: 100%;
        line-height: 28px;
        text-align: center;
        letter-spacing: -0.02em;
        color: rgba(255, 255, 255, 0.6);
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .rachel-quote h1 {
        font-size: 61.2992px;
        font-family: 'Cigarettes and Coffee';
        font-style: normal;
        font-weight: 400;
        line-height: 61px;
        text-transform: uppercase;
        color: #FFB423;
        margin-top: 21px;
        margin-bottom: 0px;
    }


}

@media (max-width:767px) {
    .symptom-return-container-mobile {
        display: block;
        padding-top: 24px;
        padding-left: 27px;
        padding-right: 29px;
        background: linear-gradient(147.08deg, #090913 0%, #1B222A 100%)
    }

    .talk-button-mobile {
        display: block;
        font-style: normal;
        font-weight: 700;
        font-size: 19px;
        line-height: 20px;
        color: #000;
        padding-top: 25px;
        padding-bottom: 45px;
        text-decoration: none !important;
    }

    .brush-style-mobile {
        background: url('/public/assets/images/mobile-brush.webp');
    }

    .symptom-return-container-mobile h1 {
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 32px;
        text-align: left;
        color: #FFFFFF;

    }

    .symptom-return-container-mobile-text {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #FFFFFF;
        text-align: left;
    }

    .symptom-return-container-mobile p {
        width: 261px;
        height: 100px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        text-align: left;
        color: #FFFFFF;
        margin-top: 12px;
    }

    .header-text {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: #FFFFFF;
        text-align: left;
        display: flex;
    }

    .hero-section-homepage {
        font-style: normal;
        font-weight: 400;
        font-size: 36px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-top: 45.32px;
        text-align: left;
    }

    .hero-section-button {
        width: 224.62px;
        height: 49.52px;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.02em;
        color: #000000;
        padding-top: 14px;
        padding-left: 10px;
        display: none;
        cursor: pointer;
    }

    .header-subtext {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #FFFFFF;
        text-align: left;
        width: 153px;
        float: left;
        margin-top: 20px;
    }

    .swimming-image {
        width: 100%;
        height: 285px;
        margin-top: -100px;
    }

    .symptom-return-container {
        display: none;
    }
}

@media (min-width:768px) and (max-width:1100px) {
    .homepage .hero-section .hero-section-image {
       position: absolute;
       bottom: 0;
    }
    .homepage .hero-section .hero-section-image img{
        height: 100% !important;
    }
    .video-image-badge {
        bottom: -10px;
    }
}

@media (min-width:1316px) {
    .rachel-quote-section {
        max-width: 1315px;
        color: #FFFFFF;
        display: flex;
        flex-direction: row;
        padding: 0px;
        gap: 41px;
        padding-top: 64px;
        padding-bottom: 72px;
    }

}

@media (min-width:1500px) {
    .homepage .symptom .symptom-home .symptom-home-img {
        visibility: hidden;
    }

    .symptom:before {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        background: url('/public/assets/website_images/symptom-img-maxwidth.webp') no-repeat left center;
        background-size: contain;
    }

    .homepage .symptom {
        background: linear-gradient(282.03deg, #12151E 32.29%, #2A313A 112.48%);
        display: flex;
        position: relative;
    }
}