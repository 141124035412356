.saving-and-support-page .flex-start.menu-section .dropdown:nth-child(5) .menu {
    font-weight: 600;
    color: #fff
}

.saving-and-support-page .flex-start.menu-section .dropdown:nth-child(5) .menu:after {
    display: block;
}


.saving-and-support-page .hero-section-saving{
    background: linear-gradient(147.08deg, #090913 0%, #1B222A 100%);
}
.saving-and-support-page .hero-section {
    margin-top: 15px;
    min-height: 428px;
    max-width: 100%;
    display: flex;
    width: 100%;
    gap: 0px !important;
}

.saving-and-support-page .hero-section .hero-section-image {
    max-width: 600px;
    width: 100%;
    text-align: left;
    position: relative;
    padding-bottom: 10px;
}

.saving-and-support-page .hero-section .hero-section-image .desktop-image {
    display: block;
    min-width: auto;
    width: 100%;
    height: 100%;
}

.saving-and-support-page .hero-section .hero-section-image .mobile-image {
    display: none;
    width: 100%;
    height: 100%;
}

.saving-and-support-page .hero-section .hero-section-image .actor_portrayal {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    letter-spacing: -0.02em;
    position: absolute;
    right: 0;
    bottom: 27px;
}

.saving-and-support-page .hero-section .hero-section-content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-content: center;
    padding-right: 20px;
    padding-bottom: 20px;
}

.saving-and-support-page .hero-section .hero-section-content h4 {
    font-weight: 300;
    font-size: 26px;
    line-height: 124%;
    color: #FFFFFF;
    margin-bottom: 12px;
    text-align: left;
}

.saving-and-support-page .hero-section .hero-section-content h1 {
    font-weight: 300;
    font-size: 51px;
    line-height: 60px;
    color: #FFFFFF;
    margin-bottom: 0px;
    text-align: left;
}

.saving-and-support-page .hero-section .hero-section-content h1 span {
    font-family: 'Cigarettes and Coffee';
    color: #FFB423;
    font-weight: 400;
    line-height: 49px;
}

.saving-and-support-page .saving-program-container {
    padding: 74px 0 44px;
}

.saving-and-support-page .saving-program-container .saving-program-container-title-section {
    margin-bottom: 90px;
}

.saving-and-support-page .saving-program-container .saving-program-container-title-section h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 42px;
    color: #7C6991;
    margin-bottom: 18px;
}

.saving-and-support-page .saving-program-container .saving-program-container-title-section p {
    font-size: 18px;
    line-height: 24px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #747474;
    margin-bottom: 0;
}

.saving-and-support-page .saving-program-container .saving-program-container-content-section ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.saving-and-support-page .saving-program-container .saving-program-container-content-section li {
    display: flex;
    gap: 56px;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 44px;
}

.saving-and-support-page .saving-program-container .saving-program-container-content-section .saving-program-container-content-section-image {
    width: 128px;
}

.saving-and-support-page .saving-program-container .saving-program-container-content-section .saving-program-container-content-section-image-call {
    margin-top: 30px;
    display: flex;
    gap: 0px !important;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-bottom: 0px;
    align-items: center;
}

.saving-and-support-page .saving-program-container .saving-program-container-content-section .saving-program-container-content-section-image-call .call {
    margin-top: 10px;
}

.saving-and-support-page .saving-program-container .saving-program-container-content-section .saving-program-container-content-section-image-call .call a {
    text-decoration: underline;
    text-decoration-skip-ink: none !important;
}

.saving-and-support-page .saving-program-container .saving-program-container-content-section .saving-program-container-content-section-text {
    max-width: calc(100% - 128px - 56px);
    width: 100%;
    padding-top: 12px;
}

.saving-and-support-page .saving-program-container .saving-program-container-content-section .saving-program-container-content-section-text h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #4B3F67;
    margin-bottom: 5px;
    text-align: left;
}

.saving-and-support-page .saving-program-container .saving-program-container-content-section .saving-program-container-content-section-text h3 a {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #4B3F67;
    margin-bottom: 5px;
    text-align: left;
    text-decoration: none;
}

.saving-and-support-page .saving-program-container .saving-program-container-content-section .saving-program-container-content-section-text p {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #747474;
    margin-bottom: 8px;
    text-align: left;
    font-feature-settings: 'pnum' off, 'lnum' off;
}

.saving-and-support-page .saving-program-container .saving-program-container-content-section .saving-program-container-content-section-text p small {
    font-size: 13px;
}

.saving-and-support-page .saving-program-container .saving-program-container-content-section .saving-program-container-content-section-text p:last-child {
    margin-bottom: 0;
}

.saving-and-support-page .saving-program-container .saving-program-container-content-section .saving-program-container-content-section-text p.disclaimer-text {
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: #747474;
    margin-top: 50px;
    margin-bottom: 0;
    text-align: left;
}

.saving-and-support-page .prescription-support-container {
    background: #F5F3F7;
    padding: 0 0 18px;
}

.saving-and-support-page .prescription-support-container .prescription-support-container-title-section {
    padding-bottom: 40px;
}

.saving-and-support-page .prescription-support-container .prescription-support-container-title-section h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    padding-top: 70px;
    line-height: 42px;
    color: #4B3F67;
    margin-bottom: 44px;
    text-align: left;
}

.saving-and-support-page .prescription-support-container .prescription-support-container-title-section h5 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #4B3F67;
    margin-bottom: 18px;
    text-align: left;
}

.saving-and-support-page .prescription-support-container .prescription-support-container-title-section p {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #747474;
    margin-bottom: 0px;
    text-align: left;
}

.saving-and-support-page .prescription-support-container .prescription-support-container-title-section hr {
    border-top: 4px solid #EBE9EC;
    max-width: 100%;
    opacity: 1;
    margin: 40px 0;
}

.saving-and-support-page .prescription-support-container .prescription-support-container-content-section .container>ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    max-width: 940px !important;
    width: 100% !important;
}

.saving-and-support-page .prescription-support-container .prescription-support-container-content-section .container>ul>li {
    display: flex;
    gap: 56px;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 48px;
}

.saving-and-support-page .prescription-support-container .prescription-support-container-content-section li .prescription-support-container-content-section-image {
    width: 128px;
}

.saving-and-support-page .prescription-support-container .prescription-support-container-content-section li .prescription-support-container-content-section-text {
    max-width: calc(100% - 128px - 56px);
    width: 100%;
    padding-top: 12px;
}

.saving-and-support-page .prescription-support-container .prescription-support-container-content-section li .prescription-support-container-content-section-text h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #4B3F67;
    margin-bottom: 5px;
    text-align: left;
}

.saving-and-support-page .prescription-support-container .prescription-support-container-content-section li .prescription-support-container-content-section-text p {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #4B3F67;
    text-align: left;
    margin-bottom: 0;
}

.saving-and-support-page .prescription-support-container .prescription-support-container-content-section li .prescription-support-container-content-section-text ul {
    margin-bottom: 0;
    padding-left: 1.5em;
    margin-top: 7px;
    list-style-type: disc;
    margin-bottom: 20px;
}

.saving-and-support-page .prescription-support-container .prescription-support-container-content-section li .prescription-support-container-content-section-text ul li {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #4B3F67;
    text-align: left;
    margin-bottom: 4px;
}

.saving-and-support-page .prescription-support-container .prescription-support-container-content-section li .prescription-support-container-content-section-text ul li::marker {
    color: #7C6991
}

.saving-and-support-page .prescription-support-container .prescription-support-container-content-section li .prescription-support-container-content-section-text .extra-info {
    padding: 14px 24px;
    background: #fff;
    border-radius: 8px;
}

.saving-and-support-page .prescription-support-container .prescription-support-container-content-section li .prescription-support-container-content-section-text .extra-info p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #4B3F67;
    letter-spacing: .01em;
    margin-bottom: 0;
}

.saving-and-support-page .prescription-support-container .prescription-support-container-content-section li .prescription-support-container-content-section-text .extra-info p a {
    font-weight: 700;
    color: #4B3F67 !important;
    text-decoration: underline !important;
    display: inline-block;
    text-decoration-skip-ink: none !important;
    font-feature-settings: 'pnum' on, 'lnum' on;
}

.saving-and-support-page .nurse-support-container {
    padding: 74px 20px 36px;
    max-width: 904px;
    margin: auto;
}

.saving-and-support-page .nurse-support-container .nurse-support-contianer-wrap {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 51px;
}

.saving-and-support-page .nurse-support-container .nurse-support-contianer-wrap .nurse-support-contiainer-wrap-image {
    max-width: 174px;
    width: 100%;
    position: relative;
    top: -20px;
}

.saving-and-support-page .nurse-support-container .nurse-support-contianer-wrap .nurse-support-contiainer-wrap-image p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    margin-top: 5px;
    letter-spacing: 0.01em;
    color: #4B3F67;
}

.saving-and-support-page .nurse-support-container .nurse-support-contianer-wrap .nurse-support-contiainer-wrap-content {
    max-width: calc(100% - 174px - 51px);
    width: 100%;
}

.saving-and-support-page .nurse-support-container .nurse-support-contianer-wrap .nurse-support-contiainer-wrap-content h3 {
    font-weight: 300;
    font-size: 26px;
    line-height: 124%;
    color: #4B3F67;
    margin-bottom: 20px;
    text-align: left;
}

.saving-and-support-page .nurse-support-container .nurse-support-contianer-wrap .nurse-support-contiainer-wrap-content .call-toll-free {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px
}



.saving-and-support-page .nurse-support-container .nurse-support-contianer-wrap .nurse-support-contiainer-wrap-content .call-toll-free * {
    display: block;
}

.saving-and-support-page .nurse-support-container .nurse-support-contianer-wrap .nurse-support-contiainer-wrap-content .call-toll-free .content-text {
    font-weight: 400;
    font-size: 36px;
    line-height: 42px;
    color: #4B3F67;
    max-width: calc(100% - 200px - 10px);
    width: 100%;
}

.saving-and-support-page .nurse-support-container .nurse-support-contianer-wrap .nurse-support-contiainer-wrap-content .call-toll-free .content-number {
    text-align: center;
}

.saving-and-support-page .nurse-support-container .nurse-support-contianer-wrap .nurse-support-contiainer-wrap-content .call-toll-free .content-number a {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    text-transform: uppercase;
    color: #4B3F67 !important;
    text-decoration: none !important;
    background: url('/public/assets/images/design-button-background.webp') no-repeat center center;
    background-size: cover;
    width: 200px;
    height: 64px;
    margin-top: -16px;
    display: flex;
    justify-content: center;
    padding-top: 23px;
    font-feature-settings: 'pnum' on, 'lnum' on;
}

.saving-and-support-page .nurse-support-container .nurse-support-contianer-wrap .nurse-support-contiainer-wrap-content .call-toll-free .content-number a:hover {
    background: url('/public/assets/images/hover-image.webp') no-repeat center center
}

.saving-and-support-page .nurse-support-container .nurse-support-contianer-wrap .nurse-support-contiainer-wrap-content .call-toll-free .content-number>span {
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    color: #4B3F67;
    margin: 10px auto 0;
    max-width: 150px;
}

.saving-and-support-page .nurse-support-container .nurse-support-contianer-wrap .nurse-support-contiainer-wrap-content .call-toll-free .content-number>span small {
    font-size: 10px;
    display: inline;
}

@media (max-width:1154px){
    .saving-and-support-page .hero-section .hero-section-image .actor_portrayal {
        bottom: 15px;
    }
    .saving-and-support-page .hero-section .hero-section-image {
        top: 15px;
    }
}

@media (max-width:1150px) and (min-width:1024px) {
    .saving-and-support-page .hero-section .hero-section-image {
        max-width: 450px;
    }
}

@media (max-width:1050px) and (min-width:900px) {
    .saving-and-support-page .hero-section .hero-section-content h1 {
        font-size: 42px;
        line-height: 50px;
    }

    .saving-and-support-page .hero-section .hero-section-content h1 span {
        line-height: 42px;
    }

    .saving-and-support-page .hero-section {
        /* min-height: 418px; */
        min-height: auto;
        gap: 0px;
    }

    .saving-and-support-page .hero-section .hero-section-image img {
        min-width: 100%;
        width: 100%;
        height: 100%;
    }
    .saving-and-support-page .hero-section {
        min-height: auto;
        padding: 30px 0px;
        gap: 0px;
    }
}

@media (max-width:920px) and (min-width:820px) {
    .saving-and-support-page .hero-section {
        min-height: auto;
        padding: 30px 0px;
        gap: 0px;
    }
.saving-and-support-page .hero-section .hero-section-content {
    max-width: 500px;
    width: auto;
}
}

@media (max-width:820px) and (min-width:768px) {
    .saving-and-support-page .hero-section {
        min-height: auto;
        padding: 30px 0px;
        gap: 0px;
    }
.saving-and-support-page .hero-section .hero-section-content {
    max-width: 450px;
    width: auto;
}
.saving-and-support-page .hero-section .hero-section-image {
    top: 15px;
}
}

@media (max-width:899px) and (min-width:768px) {
    .saving-and-support-page .hero-section .hero-section-content h1 {
        font-size: 36px;
        line-height: 40px;
    }

    .saving-and-support-page .hero-section .hero-section-content h1 span {
        line-height: 36px;
    }
}


@media (max-width:892px) and (min-width:768px) {
    .saving-and-support-page .nurse-support-container .nurse-support-contianer-wrap {
        gap: 20px;
    }

    .saving-and-support-page .nurse-support-container .nurse-support-contianer-wrap .nurse-support-contiainer-wrap-content {
        max-width: calc(100% - 174px - 20px);
    }

    .saving-and-support-page .nurse-support-container .nurse-support-contianer-wrap .nurse-support-contiainer-wrap-content h3 {
        font-size: 24px;
    }

    .saving-and-support-page .nurse-support-container .nurse-support-contianer-wrap .nurse-support-contiainer-wrap-content .call-toll-free {
        gap: 0;
        justify-content: flex-start;
    }

    .saving-and-support-page .nurse-support-container .nurse-support-contianer-wrap .nurse-support-contiainer-wrap-content .call-toll-free .content-text {
        font-size: 28px;
        width: auto;
        margin-right: 10px;
    }
}

@media (max-width:767px) {
    .saving-and-support-page .hero-section {
        flex-direction: column-reverse;
        margin-top: 44px;
        padding: 0;
        min-height: auto;
        gap: 0;
    }

    .saving-and-support-page .hero-section .hero-section-content {
        display: block;
        max-width: 100%;
        padding: 20px 20px;
    }

    .saving-and-support-page .hero-section .hero-section-content h4 {
        font-weight: 300;
        font-size: 22px;
        line-height: 124%;
        margin-bottom: 8px;
    }

    .saving-and-support-page .hero-section .hero-section-content h1 {
        font-size: 32px;
        line-height: 38px;
    }

    .saving-and-support-page .hero-section .hero-section-content h1 span {
        line-height: 32px;
    }

    .saving-and-support-page .hero-section .hero-section-image .actor_portrayal {
        left: 20px;
        right: auto;
        bottom: 31px !important;
    }

    .saving-and-support-page .hero-section .hero-section-image img {
        width: 80%;
    }

    .saving-and-support-page .saving-program-container {
        padding: 43px 0 20px;
    }

    .saving-and-support-page .saving-program-container .saving-program-container-title-section h2 {
        font-size: 28px;
        line-height: 32px;
    }

    .saving-and-support-page .saving-program-container .saving-program-container-title-section p {
        font-size: 16px;
        line-height: 20px;
    }

    .saving-and-support-page .saving-program-container .saving-program-container-title-section {
        margin-bottom: 41px;
    }

    .saving-and-support-page .saving-program-container .saving-program-container-content-section li {
        display: block;
        margin-bottom: 34px;
    }

    .saving-and-support-page .saving-program-container .saving-program-container-content-section .saving-program-container-content-section-image {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 18px;
        margin-bottom: 22px;
    }

    .saving-and-support-page .saving-program-container .saving-program-container-content-section .saving-program-container-content-section-image-call img {
        width: 50%;
    }

    .saving-and-support-page .saving-program-container .saving-program-container-content-section .saving-program-container-content-section-image-call {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 18px;
        margin-bottom: 22px;
    }

    .saving-and-support-page .saving-program-container .saving-program-container-content-section .saving-program-container-content-section-image-call {
        margin-top: 0px;
        display: flex;
        gap: 0px !important;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-bottom: 0px;
        align-items: center;
    }

    .saving-and-support-page .saving-program-container .saving-program-container-content-section .saving-program-container-content-section-image img {
        width: 59px;
    }

    .saving-and-support-page .saving-program-container .saving-program-container-content-section .saving-program-container-content-section-image h3 {
        max-width: calc(100% - 59px - 19px);
        width: 100%;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: #4B3F67;
        margin-bottom: 0;
        text-align: left;
    }

    .saving-and-support-page .saving-program-container .saving-program-container-content-section .saving-program-container-content-section-text {
        padding-top: 0;
        max-width: 100%;
    }

    .saving-and-support-page .saving-program-container .saving-program-container-content-section .saving-program-container-content-section-text p {
        font-size: 16px;
        line-height: 20px;
    }

    .saving-and-support-page .saving-program-container .saving-program-container-content-section .saving-program-container-content-section-text p.disclaimer-text {
        margin-top: 40px;
    }

    .saving-and-support-page .prescription-support-container {
        padding: 43px 0 16px;
    }

    .saving-and-support-page .prescription-support-container .prescription-support-container-title-section {
        padding-bottom: 45px;
    }

    .saving-and-support-page .prescription-support-container .prescription-support-container-title-section h2 {
        font-size: 28px;
        line-height: 32px;
        text-align: center;
        color: #4B3F67;
        margin-bottom: 18px;
    }

    .saving-and-support-page .prescription-support-container .prescription-support-container-title-section h5 {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 18px;
    }

    .saving-and-support-page .prescription-support-container .prescription-support-container-title-section p {
        font-size: 16px;
        line-height: 20px;
        color: #747474;
    }

    .saving-and-support-page .prescription-support-container .prescription-support-container-title-section hr,
    .saving-and-support-page .prescription-support-container .prescription-support-container-content-section li .prescription-support-container-content-section-image {
        width: 70px;
    }

    .saving-and-support-page .prescription-support-container .prescription-support-container-content-section .container>ul>li {
        display: flex;
        margin-bottom: 40px;
    }

    .saving-and-support-page .prescription-support-container .prescription-support-container-content-section .container>ul>li {
        display: flex;
        gap: 10px;
        align-items: flex-start;
        justify-content: left;
        flex-wrap: nowrap;
        margin-bottom: 48px;
    }

    .saving-and-support-page .prescription-support-container .prescription-support-container-content-section li .prescription-support-container-content-section-text {
        max-width: 100%;
        padding: 0;
    }

    .saving-and-support-page .prescription-support-container .prescription-support-container-content-section li .prescription-support-container-content-section-text h3 {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 6px;
    }

    .saving-and-support-page .prescription-support-container .prescription-support-container-content-section li .prescription-support-container-content-section-text>p {
        display: none;
    }

    .saving-and-support-page .prescription-support-container .prescription-support-container-content-section li .prescription-support-container-content-section-text ul {
        margin-bottom: 22px;
        margin-top: 0;
        padding-left: 0;
        list-style-type: none;
    }

    .saving-and-support-page .prescription-support-container .prescription-support-container-content-section li .prescription-support-container-content-section-text ul li {
        font-size: 18px;
        line-height: 24px;
        color: #4B3F67;
        position: relative;
        padding-left: 20px;
    }

    .saving-and-support-page .prescription-support-container .prescription-support-container-content-section li .prescription-support-container-content-section-text ul li::before {
        content: '●';
        position: absolute;
        left: 0;
        width: 20px;
        text-align: center;
        font-size: 8px;
    }

    .saving-and-support-page .prescription-support-container .prescription-support-container-content-section li .prescription-support-container-content-section-text .extra-info p a {
        display: inline-block;
    }

    .saving-and-support-page .nurse-support-container {
        padding: 54px 20px;
        margin: 0;
        max-width: 100%;
    }

    .saving-and-support-page .nurse-support-container .nurse-support-contianer-wrap {
        display: block;
        text-align: center;
    }

    .saving-and-support-page .nurse-support-container .nurse-support-contianer-wrap .nurse-support-contiainer-wrap-image {
        max-width: 154px;
        margin: auto;
        margin-bottom: 13px;
    }

    .saving-and-support-page .nurse-support-container .nurse-support-contianer-wrap .nurse-support-contiainer-wrap-content {
        max-width: 100%;
        width: 100%;
    }

    .saving-and-support-page .nurse-support-container .nurse-support-contianer-wrap .nurse-support-contiainer-wrap-content h3 {
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        margin-bottom: 13px;
    }

    .saving-and-support-page .nurse-support-container .nurse-support-contianer-wrap .nurse-support-contiainer-wrap-content .call-toll-free {
        display: block;
    }

    .saving-and-support-page .nurse-support-container .nurse-support-contianer-wrap .nurse-support-contiainer-wrap-content .call-toll-free .content-text {
        font-size: 28px;
        line-height: 32px;
        max-width: 100%;
        text-align: center;
        margin: auto;
        margin-bottom: 14px;
    }

    .saving-and-support-page .nurse-support-container .nurse-support-contianer-wrap .nurse-support-contiainer-wrap-content .call-toll-free .content-number a {
        margin: auto;
        margin-bottom: 13px;
        font-weight: 700;
        font-size: 23.2771px;
        line-height: 28px;
        padding-top: 20px;
    }

    .saving-and-support-page .hero-section .hero-section-image img {
        min-width: 100%;
        width: 100%;
        height: 100%;
    }

    .saving-and-support-page .hero-section .hero-section-image .desktop-image {
        display: none;
    }

    .saving-and-support-page .hero-section .hero-section-image .mobile-image {
        display: block;
        max-width: 200px;
        width: 100%;
        height: 100%;
    }
}

@media (min-width:1500px) {
    .saving-and-support-page .hero-section .hero-section-image {
        visibility: hidden;
    }

    .saving-and-support-page .hero-section-saving:before {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        background: url('/public/assets/images/saving-support-background (1).webp') no-repeat left center;
        background-size: contain;
    }

    .saving-and-support-page .hero-section-saving {
        position: relative;
    }
}

@media (max-width:767px) and (min-width:600px) {
    .saving-and-support-page .hero-section .hero-section-image {
        visibility: hidden;
    }

    .saving-and-support-page .hero-section-saving:before {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        bottom: 0;
        background: url('/public/assets/images/background-saving-mobile.webp') no-repeat bottom right;
        background-size: contain;
    }

    .saving-and-support-page .hero-section-saving {
        position: relative;
        min-height: 650px;
        height: 100%;
    }
}

@media (max-width:600px) and (min-width:500px) {
    .saving-and-support-page .hero-section .hero-section-image {
        visibility: hidden;
    }

    .saving-and-support-page .hero-section-saving:before {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        bottom: 0;
        background: url('/public/assets/images/background-saving-mobile.webp') no-repeat bottom right;
        background-size: contain;
    }

    .saving-and-support-page .hero-section-saving {
        position: relative;
        min-height: 600px;
        height: 100%;
    }
}

@media (max-width:500px) and (min-width:390px) {
    .saving-and-support-page .hero-section .hero-section-image {
        visibility: hidden;
    }

    .saving-and-support-page .hero-section-saving:before {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0px;
        background: url('/public/assets/images/background-saving-mobile.webp') no-repeat bottom right;
        background-size: contain;
    }

    .saving-and-support-page .hero-section-saving {
        position: relative;
        min-height: 550px;
        height: 100%;
    }
}

@media (max-width:450px) {
    .saving-and-support-page .hero-section-saving {
        position: relative;
        min-height: 500px;
        height: 100%;
    }
}

@media (max-width:390px) {
    .saving-and-support-page .hero-section .hero-section-image {
        visibility: hidden;
    }

    .saving-and-support-page .hero-section-saving:before {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0px;
        background: url('/public/assets/images/background-saving-mobile.webp') no-repeat bottom right;
        background-size: contain;
    }

    .saving-and-support-page .hero-section-saving {
        position: relative;
        min-height: 550px;
        height: 100%;
    }
.saving-and-support-page .hero-section-saving {
    position: relative;
    min-height: 516px;
    height: 100%;
}
}

@media (min-width:1024px) and (max-width:1155px) {
    .saving-and-support-page .hero-section .hero-section-image .desktop-image {
        display: block;
        min-width: 500px;
        width: 100%;
        height: 100%;
        padding-right: 80px;
    }
    .saving-and-support-page .hero-section {
        min-height: auto;
        padding: 30px 0px;
        gap: 50px !important;
    }
}