.contact-us-page .hero-section {
    padding: 82.5px 0;
}

.contact-us-page .hero-section h1 {
    font-weight: 300;
    font-size: 51px;
    line-height: 60px;
    color: #FFFFFF;
    margin-bottom: 0;
    text-align: left;
}

.contact-us-page .contact-intro-section {
    padding-top: 72px;
}

.contact-us-page .contact-content-section {
    background: url('/public/assets/images/contact-image.webp') no-repeat right center;
    background-size: 40%;
}

.contact-us-page .contact-intro-section h2 {
    font-weight: 400;
    font-size: 36px;
    line-height: 42px;
    color: #4B3F67;
    margin-bottom: 0;
    padding-bottom: 22px;
    text-align: left;
    border-bottom: 4px solid #EAE7F1;
}

.contact-us-page .contact-content-section .telephone-number {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 15px;
}

.contact-us-page .contact-content-section .telephone-number img {
    margin-left: -25px;
}

.contact-us-page .contact-content-section .telephone-number .telephone * {
    display: block;
    text-align: left;
}

.contact-us-page .contact-content-section .telephone-number .telephone span {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #4B3F67;
    margin-bottom: 5px;
}

.contact-us-page .contact-content-section .telephone-number .telephone a {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #4B3F67;
    text-decoration: none;
    display: inline-flex;
    text-decoration: underline;
    text-decoration-skip-ink: none;
    font-feature-settings: 'pnum' on, 'lnum' on;
}

.contact-us-page .contact-content-section .telephone-number .telephone a span {
    white-space: nowrap;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #4B3F67;
    margin-left: 10px;
    text-decoration: none;
    font-feature-settings: 'pnum' on, 'lnum' on;
}

.contact-us-page .contact-content-section .more-information {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #4E4E4E;
    text-align: left;
    max-width: 575px;
    margin-top: 25px;
    margin-bottom: 30px;
}

.contact-us-page .contact-content-section .more-information a {
    color: #7C6991 !important;
    text-decoration: underline !important;
    font-weight: 700;
    white-space: nowrap;
}

.contact-us-page .contact-content-section .address-box {
    padding-bottom: 73px;
    border-bottom: 4px solid #EAE7F1;
    margin-bottom: 92px;
}

.contact-us-page .contact-content-section .address-box h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #4B3F67;
    text-align: left;
    margin-bottom: 11px;
}

.contact-us-page .contact-content-section .address-box address {
    margin-bottom: 0;
    padding: 0;
    text-align: left;
}

.contact-us-page .contact-content-section .address-box address p {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #4E4E4E;
    margin-bottom: 8px;
}

.contact-us-page .contact-content-section .address-box address p:last-child {
    margin-bottom: 0;
}

@media (max-width:767px) {
    .contact-us-page .contact-content-section {
        background: none;
    }

}

@media (max-width:400px) {
    .contact-us-page .contact-content-section .telephone-number .telephone a {
        font-size: 14px;
    }

    .contact-us-page .contact-content-section .telephone-number .telephone a span {
        font-size: 14px;
        margin-left: 0px;
    }

}

@media (max-width:500px) {
    .contact-us-page .contact-content-section .telephone-number .telephone a {
        display: block;
    }

    .contact-us-page .contact-content-section .telephone-number .telephone a span {
        margin-left: 0px;
    }
}

@media (min-width:1500px) {
    .contact-us-page .contact-content-section {
        background: none;
        position: relative;
    }

    .contact-us-page .contact-content-section:before {
        background: url('/public/assets/images/contact-image.webp') no-repeat right center;
        background-size: 40%;
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        z-index: -1;
    }
}