.isi-container-main {
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: left;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.isi-container-main h5 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #4B3F67;
  margin-bottom: 8px;
}

.isi-container-main h5.mb12 {
  margin-bottom: 12px;
}

.isi-container-main p {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #4E4E4E;
  margin-bottom: 12px;
}

.isi-container-main p.letter-spacing {
  letter-spacing: -0.01em;
}

.isi-container-main b {
  font-weight: 700;
}

.isi-container-main ul {
  padding-left: 1.3em;
  font-size: 18px;
  line-height: 24px;
  color: #4E4E4E;
  margin-bottom: 10px;
}

.isi-container-main li {
  margin-bottom: 4px;
}

.isi-container-main li::marker {
  color: #7C6991;
}

.isi-container-main p.link {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  color: #7C6991;
}

.isi-container-main p.link a {
  color: #7C6991 !important;
  text-decoration: underline;
  text-decoration-skip-ink: none !important;
}

.isi-container-main p.link a:hover {
  color: #7C6991;
  text-decoration: none;
}

.isi-frame-sticky {
  background: #fff;
  padding: 16px 0 0;
  margin: auto;
  position: fixed;
  bottom: 0;
  width: 100%;
  box-shadow: 0 10px 16px black;
  z-index: 1000;
}

.isi-frame-sticky h5 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #4B3F67;
  margin-bottom: 8px;
}

.isi-frame-sticky p {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #4E4E4E;
  margin-bottom: 16px;
}

.isi-frame-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.isi-frame-container .isi-frame-sticky-full {
  width: 80%;
  margin-right: 24px;
  text-align: left;
}

.isi-frame-container .isi-frame-sticky-left {
  max-width: calc(100%/2 - 56px - 15px);
  width: 100%;
  margin-right: 24px;
  text-align: left;
}

.isi-frame-container .isi-frame-sticky-left sup {
  top: -5px
}

.isi-frame-container .isi-frame-sticky-right {
  margin-right: 8px;
}

.isi-frame-container .see-more {
  width: 110px;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  cursor: pointer;
  color: #4B3F67;
  cursor: pointer;
}

@media (max-width:1299px) {
  .isi-frame-container .isi-frame-sticky-left {
    max-width: calc(100%/2 - 56px - 7px);
    margin-right: 8px;
  }

}


@media (max-width:767px) {
  .isi-frame-sticky {
    padding: 8px 0 0;
    margin: auto;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1000;
  }

  .isi-container-main h5,
  .isi-frame-sticky h5 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 4px;
  }

  .isi-frame-sticky h5 sup {
    font-size: .6em;
  }

  .isi-frame-sticky p,
  .isi-container-main p,
  .isi-container-main li {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 4px;
  }

  .isi-container-main p b,
  .isi-container-main li b {
    color: #676767;
  }

  .isi-container-main ul {
    padding-left: 1.1em;
    margin-bottom: 20px;
  }

  .isi-container-main li {
    margin-bottom: 4px;
  }

  .isi-frame-container .isi-frame-sticky-left {
    max-width: 100%;
    text-align: left;
    margin-right: 0;
  }

  .isi-frame-container .see-more {
    position: absolute;
    top: 10px;
    right: 15px;
  }

  .isi-container-main p.letter-spacing {
    letter-spacing: normal;
  }

  .isi-container-main p.link {
    font-size: 16px;
    line-height: 20px;
  }

  .isi-container-main p.padding-mobile {
    margin-bottom: 22px;
  }
}