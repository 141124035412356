.what-are-off-periods .hero-container {
    padding-top: 40px;
}

.what-are-off-periods .flex-start.menu-section .dropdown:nth-child(2) .menu {
    font-weight: 600;
    color: #fff
}

.what-are-off-periods .flex-start.menu-section .dropdown:nth-child(2) .menu:after {
    display: block;
}

.what-are-off-periods .hero-section {
    margin-top: 15px;
    max-width: 100%;
    width: 100%;
    max-width: 1250px;
    margin: auto;
    margin-top: 15px;
    position: relative;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.what-are-off-periods .hero-section .hero-section-image {
    max-width: 65%;
    width: 100%;
    text-align: center;
    position: relative;
    right: 0;
}

.hero-section-image .off-period-hero-image {
    max-width: 696px;
    width: 100%;
    max-height: 417px;
    height: 100%;
}

.what-are-off-periods .hero-section .hero-section-image .actor_portrayal {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    letter-spacing: -0.02em;
    position: absolute;
    right: 0;
    bottom: 29px;
}

.what-are-off-periods .hero-section .hero-section-content {
    max-width: 818px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-content: center;
    padding-right: 0px;
    padding-bottom: 20px;
    position: absolute;
    height: 100%;
    left: 0;
}

.what-are-off-periods .hero-section .hero-section-content h4 {
    font-weight: 300;
    font-size: 26px;
    line-height: 124%;
    color: #FFFFFF;
    margin-bottom: 12px;
    text-align: left;
    max-width: 400px;
}

.what-are-off-periods .hero-section .hero-section-content h1 {
    font-weight: 300;
    font-size: 51px;
    line-height: 60px;
    color: #FFFFFF;
    margin-bottom: 20px;
    text-align: left;
}

.what-are-off-periods .hero-section .hero-section-content h1 span {
    font-family: 'Cigarettes and Coffee';
    color: #FFB423;
    font-weight: 400;
    line-height: 49px;
}

.what-are-off-periods .pd-symptom-container {
    padding-top: 93px;
}

.what-are-off-periods .pd-symptom-container h1 {
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
    color: #4B3F67;
    margin-bottom: 24px;
}

.what-are-off-periods .pd-symptom-container p {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #4E4E4E;
    max-width: 99%;
    margin: auto;
}

.what-are-off-periods .pd-symptom-container p b {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #7C6991;
    max-width: 99%;
    margin: auto;
}

.what-are-off-periods .pd-symptom-container span {
    color: #7C6991;
    font-weight: 700;
}

.what-are-off-periods .separator {
    border-top: 4px solid #EAE7F1;
    margin-top: 56px;
    margin-bottom: 53.22px;
}

.what-are-off-periods .pd-symptom-container h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: left;
    color: #4B3F67;
    margin-bottom: 14px;
}

.what-are-off-periods .symptom-list {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 40px;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.what-are-off-periods .symptom-list li {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    text-align: left;
    color: #747474;
    margin-bottom: 26px;
    width: calc(100% / 2 - 40px);
    position: relative;
    display: flex;
    align-items: flex-start;
}

.what-are-off-periods .symptom-list li:before {
    width: 20px;
    position: relative;
    content: '•';
    font-size: 18px;
}

.what-are-off-periods .non-motor {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: #4E4E4E;
    text-align: left;
}

.what-are-off-periods .spotting-symptom-container {
    margin: 35px auto 95px;
}

.what-are-off-periods .spotting-symptom {
    max-width: 1376px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}


.what-are-off-periods .spotting-symptom img {
    width: 42%;
}

.what-are-off-periods .spotting-symptom .text-container {
    margin-left: 60px;
    width: calc(100% - 42% - 60px);
    margin-top: 20px;
}


.what-are-off-periods .spotting-symptom p {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    color: #4B3F67;
    margin-bottom: 12px;
    text-align: left;
}

.what-are-off-periods .spotting-symptom h1 {
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 42px;
    color: #4B3F67;
    margin-bottom: 15px;
    text-align: left;
}

.what-are-off-periods .spotting-symptom h6 {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #747474;
    margin-bottom: 0;
    margin-right: 50px;
    text-align: left;
}

.what-are-off-periods .spotting-symptom h1 b {
    font-weight: 700;
    color: #4B3F67;
}

.what-are-off-periods .spotting-symptom h6 span {
    font-size: 20px;
    position: relative;
    top: 2px;
}

.what-are-off-periods .reasons-container {
    background: #EAE7F1;
    padding: 73px 0 36px;
}

.what-are-off-periods .reasons-container h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 124%;
    text-align: center;
    color: #4B3F67;
    margin-bottom: 36px;
}

.what-are-off-periods .reason-points {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 29px;
    text-align: left;
    justify-content: space-between;
    flex-wrap: wrap;
}

.what-are-off-periods .reason-points img {
    width: 26%;
}

.what-are-off-periods .reason-points .reason-points-text {
    width: calc(100% - 26% - 29px);
}

.what-are-off-periods .reason-points-text ul {
    padding-left: 1.5em;
    margin-bottom: 0;
}

.what-are-off-periods .reason-points-text ul li::marker {
    color: #7C6991;
}

.what-are-off-periods .reason-points h6 {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #4B3F67;
    margin-bottom: 16px;
    letter-spacing: -.02em;
}

.what-are-off-periods .reason-points li {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #56565A;
    margin-bottom: 6px;
    letter-spacing: -0.01em;
}

.what-are-off-periods .reason-points li span {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #4B3F67;
    margin-bottom: 6px;
    letter-spacing: -0.01em;
}

.what-are-off-periods .reason-points-2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 29px;
    justify-content: space-between;
    text-align: left;
}

.what-are-off-periods .safety-info-offperiods {
    max-width: 868px;
    width: 100%;
    text-align: left;
    margin: 53px auto 69px;

}

.what-are-off-periods .safety-info-offperiods h6 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #7C6991;
    margin-bottom: 6px;

}

.what-are-off-periods .safety-info-offperiods h6.text-underline {
    font-size: 18px;
    margin-bottom: 0;
}

.what-are-off-periods .safety-info-offperiods h5 {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #4E4E4E;
    margin-bottom: 15px;
}

.what-are-off-periods .safety-info-offperiods ul {
    padding-left: 1.5em;
    margin-bottom: 0;
    padding-bottom: 8px;
}

.what-are-off-periods .safety-info-offperiods li {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #4E4E4E;
    margin-bottom: 6px;
}

.what-are-off-periods .safety-info-offperiods li::marker {
    color: #7C6991
}


.what-are-off-periods .symptom-video {
    background: linear-gradient(282.03deg, #12151E 32.29%, #2A313A 112.48%);
    padding: 91px 0;
}

.what-are-off-periods .symptom-video-container {
    display: flex;
    justify-content: space-between;
    max-width: 1256px;
    width: 100%;
    align-items: center;
    margin: auto;
    gap: 82px;
}

.what-are-off-periods {
    .symptom-video-container-content {
        max-width: 80%;
        width: 100%;
        text-align: center;
    }
}

.what-are-off-periods .symptom-video-container-content .comma {
    max-height: 13px;
    height: 100%;
    position: relative;
    top: -12px;
}

.what-are-off-periods .symptom-video-container-content .comma-down {
    max-height: 13px;
    height: 100%;
    position: relative;
    top: -5px;
}

.what-are-off-periods .symptom-video-container-content h1 {
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 42px;
    margin-bottom: 0px;
    text-align: center;
    color: #FFFFFF;
}

.what-are-off-periods .symptom-video-container-content h2 {
    font-family: 'Cigarettes and Coffee' !important;
    font-weight: 400;
    font-size: 48px;
    line-height: 48px;
    color: #FFB423 !important;
    margin-bottom: 0px;
    margin-top: 12px;
    text-align: center;
    text-transform: uppercase;
}

.what-are-off-periods .symptom-video-container-content h6 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 0px;
    color: #FFFFFF;
}

.what-are-off-periods .symptom-video-container-content h3 {
    font-family: 'Cigarettes and Coffee';
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 124%;
    margin-left: 2px;
    text-align: center;
    color: #FFB423;
    margin-top: 25px;
    margin-bottom: 0;
}

.what-are-off-periods .symptom-video-container-content p {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 124%;
    text-align: center;
    margin-top: 25px;
    color: #EEEEEE;
    width: 100%;
    margin-bottom: 0px;
}

.what-are-off-periods .symptom-video-container-content p span {
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 124%;
    text-align: center;
    margin-top: 25px;
    color: #EEEEEE;
    width: 100%;
    margin-bottom: 0px;
}

.what-are-off-periods .symptom-video-container-content .patient-name {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 124%;
    text-align: center;
    margin-top: 12px;
    color: #EEEEEE;
    width: 100%;
    margin-bottom: 0px;
}

.what-are-off-periods .symptom-video-container-content button {
    max-width: 230px;
    height: 48px;
    width: 100%;
    align-items: center;
    border: 1px solid #F9C606;
    border-radius: 100px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    background: none;
    margin-top: 12px;
}

.what-are-off-periods .symptom-video-container-content button:hover {
    color: #FFB423;
}

.what-are-off-periods .symptom-video-container-video-mobile {
    display: none;
}

.what-are-off-periods .stephanie-img {
    max-width: 646px;
    width: 100%;
}

@media (max-width:1270px) {
    .what-are-off-periods .hero-section {
        max-width: 100% !important;
    }

    .what-are-off-periods .hero-section .hero-section-content {
        left: 20px;
    }
}

@media (max-width:991px) and (min-width:768px) {
    .what-are-off-periods .hero-section .hero-section-content {
        max-width: 500px;
    }
}

@media (max-width:1345px) {
    .what-are-off-periods .spotting-symptom {
        padding-right: 20px;
    }

    .what-are-off-periods .symptom-video-container {
        gap: 30px;
    }
}

@media (min-width:768px) and (max-width:990px) {
    .what-are-off-periods .safety-info-offperiods {
        margin: 61px auto 54px;
        width: 100%;
        padding: 0 20px;
    }
}

@media (max-width:767px) {
    .what-are-off-periods .pd-symptom-container {
        margin-top: 32px;
    }

    .what-are-off-periods .pd-symptom-container h1 {
        font-size: 28px;
        line-height: 32px;
    }

    .what-are-off-periods .pd-symptom-container p {
        max-width: 100%;
        font-size: 16px;
        line-height: 20px;
    }

    .what-are-off-periods .separator {
        margin: 40px 0;
    }

    .what-are-off-periods .pd-symptom-container h3 {
        margin-bottom: 20px;
    }

    .what-are-off-periods .symptom-list {
        display: block;
        margin-bottom: 40px;
    }

    .what-are-off-periods .symptom-list li {
        width: 100%;
        margin-bottom: 10px;
    }

    .what-are-off-periods .spotting-symptom-container {
        margin: 30px auto 50px;
    }

    .what-are-off-periods .spotting-symptom {
        display: block;
        padding: 0 20px;
    }

    .what-are-off-periods .spotting-symptom img {
        max-width: 85%;
        width: 100%;
        margin-bottom: 25px;
    }

    .what-are-off-periods .spotting-symptom .text-container {
        margin: 0;
        width: 100%;
    }

    .what-are-off-periods .spotting-symptom p {
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 12px;
    }

    .what-are-off-periods .spotting-symptom h1 {
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 15px;
    }

    .what-are-off-periods .spotting-symptom h6 {
        font-size: 16px;
        line-height: 20px;
        margin-right: 0;
    }

    .what-are-off-periods .reasons-container {
        padding: 40px 0;
    }

    .what-are-off-periods .reasons-container h1 {
        font-size: 28px;
        line-height: 32px;
        font-weight: 600;
        margin-bottom: 0;
    }

    .what-are-off-periods .reason-points {
        flex-direction: column;
        gap: 0px;
    }

    .what-are-off-periods .reason-points img {
        max-width: 250px;
        width: 100%;
    }

    .what-are-off-periods .reason-points .reason-points-text {
        width: 100%;
        margin-top: -20px;
    }

    .what-are-off-periods .reason-points h6 {
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0;
    }

    .what-are-off-periods .reason-points li {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 6px;
        letter-spacing: 0;
    }

    .what-are-off-periods .reason-points-2 {
        flex-direction: column-reverse;
    }

    .what-are-off-periods .safety-info-offperiods {
        margin: 61px auto 54px;
        width: 100%;
        padding: 0 20px;
    }

    .what-are-off-periods .safety-info-offperiods h6,
    .what-are-off-periods .safety-info-offperiods h5 {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 0;
    }

    .what-are-off-periods .safety-info-offperiods h5 {
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: #7C6991;
        margin-bottom: 10px;
    }

    .what-are-off-periods .safety-info-offperiods li {
        font-size: 16px;
        line-height: 24px;
        padding-bottom: 10px;
    }

    .what-are-off-periods .safety-info-offperiods h6.text-underline {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }

    .what-are-off-periods .symptom-video {
        padding: 40px 0;
    }

    .what-are-off-periods .symptom-video-container {
        padding: 0 20px;
    }

    .what-are-off-periods .symptom-video-container-content {
        max-width: 100%;
    }

    .what-are-off-periods .symptom-video-container-content h1 {
        margin-bottom: 30px;
        font-size: 28px;
        line-height: 32px;
        text-align: left;
    }

    .what-are-off-periods .symptom-video-container-video-mobile {
        margin: 0 -20px;
        margin-bottom: 30px;
    }

    .what-are-off-periods .symptom-video-container-content h3 {
        margin-bottom: 0;
    }

    .what-are-off-periods .symptom-video-container-content p {
        font-size: 18px;
        margin-bottom: 11px;
    }

    .what-are-off-periods .symptom-video-container-content button {
        padding: 15.5px 24px 13.5px;
        height: auto;
        max-width: 100%;
        display: inline-block;
        width: auto;
        margin-top: 19px;
    }

    .what-are-off-periods .symptom-video-container-video-mobile {
        display: block;
    }

    .what-are-off-periods .symptom-video-container-video-desktop {
        display: none;
    }

    .what-are-off-periods .hero-section {
        margin-top: 0;
        padding-top: 73px;
    }

    .what-are-off-periods .hero-section .hero-section-content {
        position: static;
        display: block;
        text-align: left;
    }

    .what-are-off-periods .hero-section .hero-section-image {
        max-width: 100%;
        display: none;
    }

    .what-are-off-periods .hero-section .hero-section-content h1 {
        max-width: 350px;
        margin-bottom: 13px;
        margin-left: 0;
    }

    .what-are-off-periods .hero-section .hero-section-content h1 span {
        display: inline-block;
    }

    .what-are-off-periods .hero-section .hero-section-content h4 {
        margin-bottom: 173px;
        max-width: 225px;
        margin-left: 0;
    }

    .what-are-off-periods .hero-section {
        background: url('/public/assets/images/Photographer 1.webp') no-repeat right bottom;
        background-size: contain;
    }

    .what-are-off-periods .hero-section .hero-section-content::after {
        content: 'Actor portrayal';
        position: absolute;
        bottom: 32px;
        left: 20px;
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        text-align: right;
        letter-spacing: -0.02em;
    }

    .what-are-off-periods .inbrija-stories-img .symptom-video-patient-name .symptom-video-patient img {
        max-width: 52px;
        max-height: 52px;
        position: relative;
        top: -2px;
    }

    .what-are-off-periods .inbrija-stories-img .symptom-video-patient-name .symptom-video-patient span {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.02em;
    }

    .what-are-off-periods .symptom-video-container-content p.ll {
        font-weight: 400;
        margin-top: 12px !important;
    }

    .what-are-off-periods .pd-symptom-container p b {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        color: #4E4E4E;
        max-width: 99%;
        margin: auto;
    }

}

@media (max-width:410px) {
    .what-are-off-periods .hero-section .hero-section-content h1 {
        font-size: 42px;
        line-height: 48px;
        max-width: 100%;
    }

    .what-are-off-periods .hero-section .hero-section-content h1 span {
        line-height: 42px;
    }
}

@media (min-width:1500px) {
    .what-are-off-periods .spotting-symptom img {
        visibility: hidden;
    }

    .what-are-off-periods .spotting-symptom-container:before {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        background: url('/public/assets/website_images/return-symptom.webp') no-repeat left center;
        background-size: contain;
    }

    .what-are-off-periods .spotting-symptom-container {
        margin: 30px auto 50px;
        position: relative;
    }
}