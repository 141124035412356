.common-questions-page .flex-start.menu-section .dropdown:nth-child(7) .menu {
    font-weight: 600;
    color: #fff
}

.common-questions-page .flex-start.menu-section .dropdown:nth-child(6) .menu:after {
    display: block;
}

.common-questions-page .inbrija-stories-header {
    padding-top: 55px;
    padding-bottom: 50px;
    text-align: left;
}


.common-questions-page .inbrija-stories-header h1 {
    font-style: normal;
    font-weight: 300 !important;
    font-size: 51px;
    line-height: 60px;
    color: #FFFFFF;
    margin-bottom: 12px;
    max-width: 88.3%;
}

.common-questions-page sup.sm-black {
    font-size: 18px;
    vertical-align: text-top;
    color: #4B3F67;
    margin-top: -20px;
    line-height: 42px;
    font-weight: 400;
}

.common-questions-page .inbrija-stories-header h1 span {
    font-family: 'Cigarettes and Coffee';
    color: #FFB423;
    font-weight: 400;
    line-height: 51px;
}

.common-questions-page .inbrija-stories-header h6 {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    margin-top: 0;
    margin-bottom: 0;
    color: #FFFFFF;
    max-width: 59.5%;
}

.common-questions-page .ask-peter {
    margin: 60px auto 80px;
}

.common-questions-page .ask-peter .saving-heading {
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 42px;
    color: #4B3F67;
    margin-bottom: 12px;
}

.common-questions-page .ask-peter .saving-text {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #747474;
    margin-bottom: 40px;
}

.common-questions-page .faq-container {
    width: 100%;
    margin-top: 100px;
    margin-bottom: 80px;
}

.common-questions-page .faq-group {
    margin-bottom: 65px;
}

.common-questions-page .faq-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
}

.common-questions-page .faq-heading h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 42px;
    color: #7C6991;
    margin-bottom: 0;
}

.common-questions-page .faq-accordion {
    background: #EAE7F1;
    border-radius: 10px;
    padding: 24px 40px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
}

.common-questions-page .faq-accordion:hover {
    background: none;
    border: 2px solid #EAE7F1;
}


.common-questions-page .faq-accordion h6 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #4B3F67;
    margin-bottom: 0px;
    max-width: 780px;
}

.common-questions-page .faq-accordion-open h6 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #4B3F67;
    margin-bottom: 20px;
}

.common-questions-page .faq-open {
    border-radius: 10px;
    padding: 24px 40px 8px;
    text-align: left;
    margin-top: 20px;
    border: 2px solid #EAE7F1;
    border-radius: 10px;
}

.common-questions-page .answer-font,
.common-questions-page .bold {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: left;
    color: #4E4E4E;
    margin-bottom: 12px;
}

.common-questions-page .answer-font small {
    font-size: 13px;
}

.common-questions-page .bold {
    font-weight: 700;
}

.common-questions-page .faq-open ul {
    margin-bottom: 12px;
    padding-left: 1.5em;
    padding-bottom: 0px;
    margin-top: -10px;
}

.common-questions-page .faq-open li {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #4E4E4E;
    margin-bottom: 4px;
}

.common-questions-page .faq-open li::marker {
    color: #7C6991
}

.common-questions-page .answer-image {
    display: flex;
    justify-content: space-between;
    /* gap: 20px; */
    align-items: flex-start;
}

.common-questions-page .answer-image-content {
    margin-left: 20px;
}

.common-questions-page .faq-extra {
    text-align: left;
}

.common-questions-page .faq-extra div {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 16px;
}

.common-questions-page .faq-extra .faq-a {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-decoration-line: underline;
    text-decoration-skip-ink: none !important;
    color: #7C6991;
    margin-top: 16px;
    text-align: left !important;
    cursor: pointer;
    margin-bottom: 16px;
}

.common-questions-page .faq-extra .faq-a:last-child {
    margin-bottom: 0;
}

.common-questions-page .faq-accordion-open p {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 24px !important;
    text-align: left !important;
    color: #4E4E4E !important;
    margin-bottom: 0rem !important;
}



.common-questions-page .faq-heading div {
    cursor: pointer;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-decoration-line: underline;
    text-decoration-skip-ink: none !important;
    color: #7C6991;
}

.common-questions-page .faq-heading h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 42px;
    color: #7C6991;
}

.common-questions-page .faq-accordion-open {
    margin-bottom: 20px;
    border-bottom: 2px solid #EAE7F1;
    text-align: left;
    display: flex;
    justify-content: space-between;
}

.common-questions-page .ask-peter-width {
    max-width: 868px;
    width: 100%;
}

.common-questions-page .inbrija-msg {
    background: url('/public/assets/images/inbrija-msg-background.webp') no-repeat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.01em;
    color: #FFFFFF;
    max-width: 271.04px;
    width: 100%;
    padding: 8px 15px;
    background-size: cover;
    text-align: left;
    height: 48px;
    display: flex;
    align-items: center;
    margin: 7px auto 0;
}

.common-questions-page .inbrija-msg.show_desktop {
    display: flex;
}

.common-questions-page .inbrija-msg.show_mobile {
    display: none;
}

.pointer {
    cursor: pointer;
}

.bold {
    font-weight: bold;
}

.common-questions-page .still-have-ques {
    background: url('/public/assets/images/common-ques-background.webp') center no-repeat;
    background-size: contain;
    padding-top: 111px;
    padding-bottom: 50px;
    max-width: 1211px;
    width: 100%;
    margin: 0 auto 64px;
}

.common-questions-page .still-have-ques .still-have-ques-text {
    max-width: 80%;
    margin: auto;
    margin-left: 12%;
}

.common-questions-page .still-have-ques h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
    color: #4B3F67;
    margin-bottom: 0;
}

.common-questions-page .still-have-ques h2 {
    font-style: normal;
    font-weight: 300;
    font-size: 26px;
    line-height: 33px;
    text-align: center;
    color: #4B3F67;
    margin-bottom: 16px;
}


.common-questions-page .still-have-ques-mobile {
    padding-top: 10px;
    padding-bottom: 50px;
    max-width: 1211px;
    width: 100%;
    margin: 0 auto 64px;
}

.common-questions-page .still-have-ques-mobile .still-have-ques-text-mobile {
    max-width: 80%;
    margin: auto;
    margin-left: 12%;
}

.common-questions-page .still-have-ques-mobile h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
    color: #4B3F67;
    margin-top: 49px;
    margin-bottom: 0;
}

.common-questions-page .still-have-ques-mobile h2 {
    font-style: normal;
    font-weight: 300;
    font-size: 26px;
    line-height: 33px;
    text-align: center;
    color: #4B3F67;
    margin-bottom: 16px;
}

.common-questions-page .call-toll-free {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: #4B3F67;
    max-width: 264px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16.5px;
}

.common-questions-page .call-toll-free .primary-button {
    background: #4B3F67;
    border-radius: 100px;
    border: none;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
    text-transform: capitalize;
    color: #FFFFFF !important;
    text-decoration: none !important;
    padding: 12.5px 24px 12.5px;
    font-feature-settings: 'pnum' on, 'lnum' on;
}

.common-questions-page .call-toll-free .primary-button:hover {
    background: #7C6991
}

.common-questions-page .still-have-ques-mobile .small-call {
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    color: #4B3F67;
}

.common-questions-page .still-have-ques-mobile .small-call small {
    font-size: 10px;
}

.common-questions-page .still-have-ques-text .small-call {
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    color: #4B3F67;
}


.common-questions-page .contentWrapper {
    display: flex;
    overflow: hidden;
    width: 100%;
    gap: 3px;
}

.common-questions-page .contentWrapper .ask-peter-slider-item {
    margin-top: 2px;
}

.call-inbrija-support {
    max-width: 1211px;
    width: 100%;
    margin-bottom: 64px;
}

.common-questions-page .faq-open {
    font-feature-settings: 'pnum' on, 'lnum' on;
}

.common-questions-page .faq-open a {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-decoration-line: underline;
    text-decoration-skip-ink: none !important;
    color: #7C6991;
}

.common-questions-page .ask-peter-slider .ask-peter-slider-main {
    justify-content: space-evenly;
}

.common-questions-page .peter-video {
    max-width: 868px;
    width: 100%;
}

.common-questions-page .close-sign {
    margin-bottom: 20px;
}

.common-questions-page .peter-video-background {
    position: relative;
}

.common-questions-page .peter-video-content {
    position: absolute;
    z-index: 100;
    gap: 250px;
    display: flex;
}

.common-questions-page .peter-video-content .peter-play {
    text-align: left;
    padding-left: 40px;
}

.common-questions-page .peter-video-content .peter-video-logo {
    width: 20%;
}

.common-questions-page .peter-video-content .peter-video-logo-pause {
    width: 10%;
    margin-left: 15px;
}

.common-questions-page .peter-video-content .peter-video-logo-pause-desktop {
    width: 20%;
    margin-left: 25px;
}

.common-questions-page .common-questions-br {
    display: block;
}


.common-questions-page .peter-text-container {
    text-align: left;
}

.common-questions-page .peter-video-content-text {
    background: #FFFFFF;
    border-radius: 8px;
    padding: 8px 14px 8px 14px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    margin-top: 16px;
    color: #000000;
    width: max-content;
    text-align: left;
}

.common-questions-page .peter-video-content-text a {
    color: #4B3F67 !important;
}

.common-questions-page .peter-logo-img {
    max-width: 80px;
    width: 100%;
    padding-top: 20px;
}

.peter-video-modal {
    max-width: 984px !important;
    margin: auto !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    width: 100%;
}

.peter-video-modal-background {
    background: #EAE7F1;
    border-radius: 17.1714px;
    padding: 23px 50px 32px 50px !important;
}

.peter-logo {
    max-width: 239px;
    width: 100%;
    padding-left: 41px;
    padding-bottom: 32px;
}

.peter-multiple-videos-container {
    display: flex;
    gap: 25px;
    align-items: center;
    align-self: center;
    margin-top: 20px;
}

.peter-multiple-video {
    max-width: 800px;
    width: 100%;
}

.peter-swipe {
    max-width: 16px;
    width: 100%;
    max-height: 35px;
    height: 100%;
}

.peter-video-title {
    display: flex;
    justify-content: space-between;
}

.peter-video-title p {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 0px;
    color: #4B3F67;
    padding: 0px 40px;
    min-width: 210px;
}

.peter-video-instructions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    padding: 0px 40px;
}

.peter-video-inst {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    margin-top: 10px;
    text-decoration-line: underline;
    text-decoration-skip-ink: none !important;
    color: #4B3F67;
}

.peter-video-inst-safety {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    margin-top: 10px;
    text-decoration-line: underline;
    text-decoration-skip-ink: none !important;
    color: #4B3F67;
    padding-left: 40px;
}

@media (max-width:990px) {
    .common-questions-page .common-questions-br {
        display: none;
    }
}

@media (max-width:990px) and (min-width:767px) {
    .peter-video-modal-background {
        background: #EAE7F1;
        border-radius: 17.1714px;
        padding: 20px 20px 20px 20px;
    }
}

@media (max-width:767px) and (min-width:576px) {
    .common-questions-page .still-have-ques {
        display: none;
    }

    .peter-video-modal-background {
        background: #EAE7F1;
        border-radius: 17.1714px;
        padding: 20px 20px 20px 20px;
    }

}

@media (max-width:576px) and (min-width:500px) {
    .common-questions-page .still-have-ques {
        display: none;
    }


    .peter-video-modal-background {
        background: #EAE7F1;
        border-radius: 17.1714px;
        padding: 20px 20px 20px 20px;
    }

}

@media (max-width:500px) and (min-width:400px) {
    .common-questions-page .still-have-ques {
        display: none;
    }


    .peter-video-modal-background {
        background: #EAE7F1;
        border-radius: 17.1714px;
        padding: 20px 20px 20px 20px;
    }

}

@media (max-width:400px) {
    .common-questions-page .still-have-ques {
        display: none;
    }

    .peter-video-modal-background {
        background: #EAE7F1;
        border-radius: 17.1714px;
        padding: 10px 10px 10px 10px;
    }

    .peter-multiple-videos-container {
        display: flex;
        gap: 5px;
        align-items: center;
        align-self: center;
        margin-top: 20px;
    }
}


@media (max-width:767px) {

    .common-questions-page .still-have-ques {
        display: none;
    }

    .common-questions-page .still-have-ques {
        display: none;
    }

    .peter-logo {
        max-width: 239px;
        width: 100%;
        padding-left: 41px;
        padding-bottom: 32px;
    }

    .common-questions-page .peter-video-content .peter-play {
        text-align: left;
        padding-left: 5px;
    }

    .peter-swipe {
        max-width: 16px;
        width: 100%;
        max-height: 35px;
        height: 100%;
    }

    .peter-video-title {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }

    .peter-video-title p {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 0px;
        color: #4B3F67;
        padding: 0px 10px;
    }

    .peter-video-instructions {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        padding: 0px 10px;
        flex-wrap: wrap;
    }

    .peter-video-inst {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        margin-top: 10px;
        text-decoration-line: underline;
        text-decoration-skip-ink: none !important;
        color: #4B3F67;
    }

    .peter-multiple-video {
        max-width: 800px;
        width: 80%;
    }

    .peter-video-inst-safety {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        margin-top: 10px;
        text-decoration-line: underline;
        text-decoration-skip-ink: none !important;
        color: #4B3F67;
        padding-left: 10px;
    }

}

@media (max-width:787px) {
    .common-questions-page .inbrija-stories-header {
        padding: 40px 0 60px;
    }

    .common-questions-page .inbrija-stories-header h1 {
        font-size: 32px;
        line-height: 38px;
        max-width: 100%;
        margin-bottom: 8px;
    }

    .common-questions-page .inbrija-stories-header h1 span {
        font-size: 32px;
        line-height: 38px;
    }

    .common-questions-page .inbrija-stories-header h6 {
        font-size: 20px;
        line-height: 20px;
        max-width: 100%;
    }

    .common-questions-page .ask-peter .saving-heading {
        font-size: 28px;
        line-height: 32px;
        max-width: 310px;
        margin: auto;
        margin-bottom: 12px;
    }

    .common-questions-page .ask-peter .saving-text {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 60px;
    }

    .common-questions-page sup.sm-black {
        font-size: 14px;
        line-height: 0;
        position: relative;
        top: 12px;
    }

    .common-questions-page .ask-peter-slider {
        margin: 0 -20px;
    }

    .common-questions-page .faq-heading h1 {
        font-size: 28px;
        line-height: 32px;
        text-align: left;
    }

    .common-questions-page .faq-accordion h6 {
        font-size: 18px;
        line-height: 24px;
        margin-right: 10px;
    }

    .common-questions-page .faq-extra .faq-a {
        font-size: 18px;
        line-height: 24px;
        font-weight: 400;
    }

    .common-questions-page .faq-extra br {
        display: none;
    }

    .common-questions-page .faq-extra span.faq-a {
        font-weight: 700;
    }

    .common-questions-page .answer-image {
        flex-direction: column;
    }

    .common-questions-page .ask-peter-slider .right-arrow-slider {
        order: 2
    }

    .common-questions-page .ask-peter-slider .left-arrow-slider {
        order: 1;
        margin-right: 18px;
    }

    .common-questions-page .ask-peter-slider .ask-peter-width {
        order: 0;
        width: 100%;
        margin-bottom: 18px;
    }

    .common-questions-page .ask-peter-slider .ask-peter-slider-main {
        flex-wrap: wrap;
        justify-content: center;
    }

    .common-questions-page .inbrija-msg {
        font-size: 8px;
        line-height: 12px;
        font-weight: 600;
    }

    .common-questions-page .inbrija-msg.show_desktop {
        display: none;
    }

    .common-questions-page .inbrija-msg.show_mobile {
        display: flex;
    }
}