.about-inbrija .flex-start.menu-section .dropdown:nth-child(3) .menu {
    font-weight: 600;
    color: #fff
}

.about-inbrija .flex-start.menu-section .dropdown:nth-child(3) .menu:after {
    display: block;
}

.about-inbrija .hero-section {
    margin-top: 30px;
    min-height: 428px;
    max-width: 100%;
    width: 100%;
}

.about-inbrija .cine-css {
    width: max-content;
    max-width: 780px;
}

.about-inbrija .hero-section .hero-section-image {
    max-width: 558px;
    width: 100%;
    text-align: left;
    position: relative;
    padding-bottom: 10px;
}

.about-inbrija .hero-section .hero-section-image .actor_portrayal {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    letter-spacing: -0.02em;
    position: absolute;
    left: 10%;
    bottom: 33px;
}

.about-inbrija .hero-section .hero-section-content {
    max-width: 818px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-content: center;
    padding-right: 20px;
    padding-bottom: 20px;
}

.about-inbrija .hero-section .hero-section-content h1 {
    font-weight: 400;
    font-size: 54px;
    line-height: 63px;
    letter-spacing: -0.02em;
    margin-bottom: 0px;
    text-align: left;
    max-width: 698px;
    margin-bottom: 17px;
}

.about-inbrija .hero-section .hero-section-content h1 span {
    font-family: 'Cigarettes and Coffee';
    line-height: 54px;
    letter-spacing: 0.08em;
    color: #FFB423;
    display: inline-block;
}

.about-inbrija .hero-section .hero-section-content h4 {
    font-weight: 300;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 7px;
    margin-bottom: 0;
    text-align: left;
    max-width: 720px;
}

.about-inbrija .hero-section .hero-section-content h4 b {
    font-weight: 700;
}

.about-inbrija .hero-section .hero-section-content h4 img {
    height: 56px;
    width: 56px;
}

.about-inbrija .hero-section .hero-section-content h4 span {
    max-width: calc(100% - 56px - 7px);
}

.about-inbrija .about-inbrija-intro-section {
    padding: 45px 20px;
}

.about-inbrija .about-inbrija-intro-section h3 {
    font-weight: 400;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #4B3F67;
    max-width: 1000px;
    margin: auto;
    margin-bottom: 20px;
}

.about-inbrija .about-inbrija-intro-section p {
    font-weight: 400;
    font-size: 19px;
    line-height: 26px;
    text-align: center;
    color: rgba(0, 0, 0, 0.64);
    width: 100%;
    margin: auto;
    margin-bottom: 8px;
}

.about-inbrija .about-inbrija-intro-section p:last-child {
    margin-bottom: 0;
}

.about-inbrija .about-inbrija-rescue-section {
    background: #EAE7F1;
    padding: 69px 0;
}

.about-inbrija .about-inbrija-rescue-section .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
}

.about-inbrija .about-inbrija-rescue-section .about-inbrija-rescue-section-left {
    max-width: 424px;
    margin: auto;
    margin-left: 0;
    width: 100%;
}

.about-inbrija .about-inbrija-rescue-section .about-inbrija-rescue-section-left h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 42px;
    color: #4B3F67;
    margin-bottom: 12px;
    text-align: left;
}

.about-inbrija .about-inbrija-rescue-section .about-inbrija-rescue-section-left p {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #4B3F67;
    text-align: left;
}

.about-inbrija .about-inbrija-rescue-section .about-inbrija-rescue-section-right {
    max-width: 424px;
    margin: auto;
    margin-right: 0;
    width: 100%;
    background: url("/public/assets/images/rescue-bg.webp") no-repeat center center;
    background-size: contain;
    padding: 10px 0;
}

.about-inbrija .about-inbrija-rescue-section .about-inbrija-rescue-section-right .about-inbrija-rescue-section-right-splash {
    max-width: 476px;
    margin: auto;
    width: 100%;
    margin-left: 17px;
}

.about-inbrija .about-inbrija-rescue-section .about-inbrija-rescue-section-right p {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 0px;
    text-align: center;
    color: #685778;
    margin-top: 16px;
}

.about-inbrija .about-inbrija-rescue-section .about-inbrija-rescue-section-right h3 {
    margin-bottom: 0;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 124%;
    text-align: center;
    letter-spacing: 0.03em;
    margin-top: 65px;
    color: #4B3F67;
}

.about-inbrija .safety-info {
    text-align: left;
    margin: 42px auto 38px;

}

.about-inbrija .safety-info h6 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #7C6991;
    margin-bottom: 12px;

}

.about-inbrija .safety-info h5 {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #4E4E4E;
    margin-bottom: 12px;
}

.about-inbrija .safety-info h6.text-underline {
    font-size: 18px;
    margin-bottom: 0;
}

.about-inbrija .br-inhaler {
    display: block;
}

.about-inbrija .safety-info p {
    font-size: 18px;
    line-height: 24px;
    color: #4E4E4E;
    margin-bottom: 12px;
}

.about-inbrija .safety-info ul {
    padding-left: 1.5em;
    margin-bottom: 12px;
}

.about-inbrija .safety-info ul li {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #4E4E4E;
    margin-bottom: 8px;
}

.about-inbrija .safety-info ul li::marker {
    color: #7C6991 !important;
}

.about-inbrija .safety-info ul li:last-child {
    margin-bottom: 0;
}

.about-inbrija .inbrija-anywhere-section {
    padding: 101px 0 80px;
    background: #11141D;
    position: relative;
}

.about-inbrija .inbrija-anywhere-section::after {
    content: '';
    background: url('/public/assets/images/inbrija-anywhere-bg.webp') no-repeat;
    background-size: contain;
    height: 40px;
    width: 100%;
    position: absolute;
    bottom: -1px;
    left: 0;
}

.about-inbrija .inbrija-anywhere-section .inbrija-anywhere-section-container {
    max-width: 1310px;
    width: 100%;
    margin: auto;
    margin-left: 0;
    margin-right: 0;
    padding-left: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
}

.about-inbrija .inbrija-anywhere-section .inbrija-anywhere-section-container-left {
    max-width: calc(100% - 416px);
    width: 100%;
    text-align: left;
    padding-left: 29px;
    position: relative;
    margin-top: 20px;
}

.about-inbrija .inbrija-anywhere-section .inbrija-anywhere-section-container-left h3 {
    font-weight: 400;
    font-size: 48px;
    line-height: 56px;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-bottom: 12px;
}

.about-inbrija .inbrija-anywhere-section .inbrija-anywhere-section-container-left::before {
    content: '';
    position: absolute;
    width: 24px;
    height: 24px;
    left: 0;
    top: 0;
}

.about-inbrija .inbrija-anywhere-section .inbrija-anywhere-section-container-left h3 span {
    color: #FFB423;
    font-family: 'Cigarettes and Coffee';
}

.about-inbrija .inbrija-anywhere-section .inbrija-anywhere-section-container-left p {
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: -0.02em;
    color: rgba(255, 255, 255, 0.6);
    margin-bottom: 33px;
}


.about-inbrija .inbrija-anywhere-section .inbrija-anywhere-section-container-left h6 {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    margin-top: 42px;
    letter-spacing: 0.03em;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #FFFFFF;
    width: 563px;
}

.about-inbrija .inbrija-anywhere-section .inbrija-anywhere-section-container-left a {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    margin-top: 42px;
    letter-spacing: 0.03em;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #FFFFFF;
    text-decoration: underline;
}


.about-inbrija .inbrija-anywhere-section .inbrija-anywhere-section-container-left img {
    position: relative;
    top: -5px;
    max-height: 12px;
}

.about-inbrija .inbrija-anywhere-section .inbrija-anywhere-section-container-left p:last-child {
    margin-bottom: 0;
}

.about-inbrija .inbrija-anywhere-section .inbrija-anywhere-section-container-right {
    max-width: 416px;
    width: 100%;
}

.about-inbrija .inbrija-anywhere-section .inbrija-anywhere-section-container-right .hand-image {
    max-width: 360px;
    width: 100%;
}

.about-inbrija .inbrija-anywhere-section .inbrija-anywhere-section-container-right figure {
    margin-bottom: 0;
    right: 0;
    text-align: -webkit-right;
    position: relative;
    max-width: 812px;
    width: 100%;
}

.about-inbrija .inbrija-anywhere-section .inbrija-anywhere-section-container-right figcaption {
    position: absolute;
    bottom: 114px;
    right: 93px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    letter-spacing: -0.02em;
    color: #FFFFFF;
}

.about-inbrija .inbrija-anywhere-video-section {
    width: 972px;
    max-width: 100%;
    margin: auto;
    /* top: 42px; */
    position: relative;
    margin-top: -180px;
    margin-bottom: 76px;
}

.about-inbrija .inbrija-anywhere-video-section .video-buttons {
    margin-top: 35px;
    max-width: 412px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 35px;
}

.about-inbrija .inbrija-anywhere-video-section .video-buttons a {
    display: block;
    padding: 12px 24px;
    background: #4B3F67;
    border-radius: 100px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: #FFFFFF !important;
    text-decoration: none !important;
}

.about-inbrija .inbrija-anywhere-video-section .video-buttons a:hover {
    background: #7C6991;
}

.about-inbrija .inbrija-anywhere-video-section .video-buttons button {
    display: block;
    padding: 12px 24px;
    background: #4B3F67;
    border-radius: 100px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
    border: none;
    color: #FFFFFF !important;
    text-decoration: none !important;
}

.about-inbrija .inbrija-anywhere-video-section .video-buttons button:hover {
    background: #7C6991;
}

.about-inbrija .inbrija-return-section {
    background: #4B3F67;
    padding: 46px 0;
}

.about-inbrija .inbrija-return-section .inbrija-return-section-container {
    max-width: 906px;
    margin: auto;
    width: 100%;
    text-align: center;
}

.about-inbrija .inbrija-return-section .inbrija-return-section-container h3 {
    font-weight: 600;
    font-size: 26px;
    line-height: 124%;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 12px;
}

.about-inbrija .inbrija-return-section .inbrija-return-section-container p {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #FFFFFF;
    margin-bottom: 0;
}

.about-inbrija .inbrija-orally-inhaled-section-top {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    padding: 70px 0;
    border-bottom: 4px solid #EAE7F1;
    gap: 32px;
}

.about-inbrija .inbrija-orally-inhaled-section-top-image {
    width: 101px;
}

.about-inbrija .inbrija-orally-inhaled-section-top-content {
    width: calc(100% - 101px - 32px);
}

.about-inbrija .inbrija-orally-inhaled-section-top-content h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #4B3F67;
    margin-bottom: 12px;
    text-align: left;
}

.about-inbrija .inbrija-orally-inhaled-section-top-content ul {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #4E4E4E;
    padding-left: 1.5em;
    margin-bottom: 0;
}

.about-inbrija .inbrija-orally-inhaled-section-top-content li {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #4E4E4E;
    margin-bottom: 8px;
    text-align: left;
}

.about-inbrija .inbrija-orally-inhaled-section-top-content li:last-child {
    margin-bottom: 0;
}

.about-inbrija .inbrija-orally-inhaled-section-top-content li::marker {
    color: #7C6991
}

.about-inbrija .inbrija-orally-inhaled-section-top-content ul ul {
    margin-top: 8px;
}

.about-inbrija .inbrija-orally-inhaled-section-bottom {
    padding: 50px 0 50px;
}

.about-inbrija .inbrija-orally-inhaled-section-bottom .inbrija-orally-inhaled-section-bottom-items {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
}

.about-inbrija .inhale_image {
    max-width: 110px;
    width: 100%;
}

.about-inbrija .inbrija-orally-inhaled-section-bottom .inbrija-orally-inhaled-section-bottom-items .inbrija-orally-inhaled-section-bottom-item {
    max-width: 464px;
    width: 100%;
}

.about-inbrija .inbrija-orally-inhaled-section-bottom .inbrija-orally-inhaled-section-bottom-items .inbrija-orally-inhaled-section-bottom-item h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #4B3F67;
    margin-bottom: 12px;
    margin-top: 36px;
}

.about-inbrija .inbrija-orally-inhaled-section-bottom .inbrija-orally-inhaled-section-bottom-items .inbrija-orally-inhaled-section-bottom-item p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.02em;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #4E4E4E;
    margin-bottom: 8px;
}

.about-inbrija .inbrija-orally-inhaled-section-bottom .inbrija-orally-inhaled-section-bottom-items .inbrija-orally-inhaled-section-bottom-item p:last-child {
    margin-bottom: 0;
}

.about-inbrija .practice-banner-section {
    max-width: 1210px;
    width: 100%;
    margin: auto;
    height: 314px;
    background: url("/public/assets/images/common-ques-background.webp") no-repeat center center;
    background-size: 100% 100%;
    margin-bottom: 85px;
}

.about-inbrija .practice-banner-content {
    position: relative;
    top: 45px;
    text-align: center;
    padding-top: 50px;
    left: 50px;
    margin-left: 30%;
    max-width: 720px;
    text-align: left;
    width: 100%;
}

.about-inbrija .practice-banner-content h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 124%;
    color: #4B3F67;
    text-align: left;
    max-width: 660px;
    margin-bottom: 12px;
}

.about-inbrija .practice-banner-content p {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: left;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #4B3F67;
    margin-bottom: 16px;
}

.about-inbrija .practice-banner-content a {
    padding: 14.5px 24px;
    background: #4B3F67;
    border-radius: 100px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
    border: none;
    color: #FFFFFF !important;
    text-decoration: none !important;
    display: inline-block;
}

.about-inbrija .practice-banner-content a:hover {
    background: #7C6991;
}

.about-inbrija .ddg-section {
    background: rgba(124, 105, 145, .08);
    padding: 44px 0;
}

.about-inbrija .ddg-section .ddg-section-container {
    max-width: 1406px;
    margin: auto;
    width: 100%;
    padding: 0 20px;
    display: flex;
    align-self: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.about-inbrija .ddg-section .ddg-section-container .ddg-section-left {
    max-width: calc(100% - 381px);
    width: 100%;
    margin: auto;
    margin-left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 23px;
    gap: 20px;
    flex-wrap: wrap;
}

.about-inbrija .ddg-section .ddg-section-container .ddg-section-left .ddg-section-left-image {
    max-width: 330px;
}

.about-inbrija .ddg-section .ddg-section-container .ddg-section-left-content {
    max-width: calc(100% - 330px - 20px);
    width: 100%;
    text-align: left;
}

.about-inbrija .ddg-section .ddg-section-container .ddg-section-left-content h3 {
    font-weight: 400;
    font-size: 36px;
    line-height: 42px;
    color: #4B3F67;
    margin-bottom: 12px;
    text-align: left;
}

.about-inbrija .ddg-section .ddg-section-container .ddg-section-left-content p {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #4B3F67;
    text-align: left;
}

.about-inbrija .ddg-section .ddg-section-container .ddg-section-left-content a {
    background: #4B3F67;
    border-radius: 100px;
    padding: 14.5px 24px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: #FFFFFF !important;
    display: inline-block;
    text-decoration: none !important;
    display: inline-flex;
    align-items: center;
    gap: 10px;
}

.about-inbrija .ddg-section .ddg-section-container .ddg-section-left-content a:hover {
    background: #7C6991;
}

.about-inbrija .ddg-section .ddg-section-container .ddg-section-left-content a.show_mobile {
    display: none;
}

.about-inbrija .ddg-section .ddg-section-container .ddg-section-right {
    max-width: 381px;
    width: 100%;
    margin: auto;
    padding-left: 23px;
    border-left: 4px solid #EAE7F1;
}

.about-inbrija .ddg-section .ddg-section-container .ddg-section-right h5 {
    font-weight: 300;
    font-size: 26px;
    line-height: 124%;
    text-align: center;
    color: #4B3F67;
    margin-bottom: 16px;
}

.about-inbrija .ddg-section .ddg-section-container .ddg-section-right h3 {
    font-weight: 700;
    font-size: 51px;
    line-height: 54px;
    text-align: center;
    color: #685778;
    margin-bottom: 24px;
}

.about-inbrija .ddg-section .ddg-section-container .ddg-section-right h3 span {
    display: inline-block;
}

.about-inbrija .ddg-section .ddg-section-container .ddg-section-right a {
    background: #4B3F67;
    border-radius: 100px;
    padding: 14.5px 24px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    display: inline-block;
}

.about-inbrija .ddg-section .ddg-section-container .ddg-section-right a:hover {
    background: #7C6991;
}

.about-inbrija .ddg-section .ddg-section-container .ddg-section-right p {
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    margin-top: 33px;
    margin-bottom: 0;
}

.about-inbrija-modal {
    background: url('/public/assets/images/about-inbrija-modal.webp') no-repeat;
    border: none !important;
    background-size: cover;
}

.about-inbrija-ddg .surprised-symptom-modal {
    padding: 18px 48px 32px 48px;
    text-align: center;
}

.about-inbrija-ddg .surprised-symptom-modal .fill-own {
    font-style: italic;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.02em;
    color: #4B3F67;
}

.about-inbrija-ddg .surprised-symptom-modal .download-ddg-font {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
    margin-top: 2px;
    color: #4B3F67;
    text-decoration: none !important;
}

.about-inbrija-ddg .surprised-symptom-modal .download-ddg-font a {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
    margin-top: 2px;
    color: #4B3F67;
    text-decoration: none !important;
}


.about-inbrija-ddg .surprised-symptom-modal .custom-ddg {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
    margin-top: 107px;
    text-align: center;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    color: #4B3F67;
}

.about-inbrija-ddg .surprised-symptom-modal .symptom-return-font {
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 42px;
    color: #7C6991
}

.about-inbrija-ddg .surprised-symptom-modal .radio-buttons {
    max-width: 554px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 32px;
}

.about-inbrija-ddg .surprised-symptom-modal .radio-buttons .radio {
    display: flex;
    justify-content: left;
    margin-bottom: 144px;
}

.about-inbrija-ddg .surprised-symptom-modal .radio-buttons .radio .radio-css {
    width: 24px !important;
    height: 24px !important;
}

.about-inbrija-ddg .surprised-symptom-modal .radio-buttons .radio label {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    margin-left: 12px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #4E4E4E;
}

.about-inbrija-ddg .surprised-symptom-modal {
    text-align: -webkit-center;
}

.about-inbrija-ddg .surprised-symptom-modal .back-next {
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.about-inbrija-ddg .surprised-symptom-modal .back-next .back {
    border: 2px solid #53565A;
    border-radius: 100px;
    padding: 15px 30px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #53565A;
    background: none;
    opacity: 0.5;
}

.about-inbrija-ddg .surprised-symptom-modal .back-next .next {
    background: #7C6991;
    border-radius: 100px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
    border: none;
    padding: 15px 30px;
    color: #FFFFFF;
}

.about-inbrija-ddg .surprised-symptom-modal .back-next .next:hover {
    background: #685778;
}

.about-inbrija-ddg .surprised-symptom-modal .back-next .bullet {
    width: 10px;
    height: 10px;
    background: #7C6991;
    transform: rotate(-90deg);
    border-radius: 50%;
}

.about-inbrija-ddg .surprised-symptom-modal .back-next .bullet2 {
    width: 10px;
    height: 10px;
    background: #D6D3DD;
    transform: rotate(-90deg);
    border-radius: 50%;
}

.about-inbrija-ddg .surprised-symptom-modal .back-next .bullets {
    max-width: 70px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.about-inbrija-ddg .surprised-symptom-modal .symptom-return-modal .ddg {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    color: #4B3F67;
    margin-top: 39px;
}

.about-inbrija-ddg .surprised-symptom-modal .symptom-return-modal .symptoms {
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
    margin-top: 29px;
    color: #7C6991;
}

.about-inbrija-ddg .surprised-symptom-modal .symptom-return-modal .check-symptom {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #7C6991;
}

.about-inbrija-ddg .surprised-symptom-modal .symptom-return-modal .options {
    margin-top: 18px;
}

.about-inbrija-ddg .surprised-symptom-modal .symptom-return-modal .options .check {
    height: 24px !important;
    width: 24px !important;
    vertical-align: top;

}

.about-inbrija-ddg .surprised-symptom-modal .symptom-return-modal .options label {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    margin-left: 12px;
    text-align: left;
    color: #4E4E4E;
}


.about-inbrija-ddg .surprised-symptom-modal .symptom-return-modal .checkboxes_inb {
    display: flex;
    justify-content: left;
    gap: 120px;
}

.about-inbrija-ddg .surprised-symptom-modal .symptom-return-modal .activities-checkboxes {
    display: flex;
    justify-content: left;
    gap: 120px;
    padding-left: 30px;
    max-width: 1019px;
    width: 100%;
}

.about-inbrija-ddg .surprised-symptom-modal .symptom-return-modal .activities-checkboxes .margin-left {
    padding-left: 22px;
}

.about-inbrija-ddg .surprised-symptom-modal .symptom-return-modal .checkmargin1 {
    margin-top: 38px;
}

.about-inbrija-ddg .surprised-symptom-modal .symptom-return-modal .checkmargin2 {
    margin-top: 20px;
    margin-bottom: 42px;
}

.about-inbrija-ddg .thankyou-modal {
    padding-top: 93px;
    padding-bottom: 17px;
}

.about-inbrija-ddg .thankyou-modal h1 {
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
    margin-bottom: 0px;
    color: #7C6991;
}

.about-inbrija-ddg .thankyou-modal h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
    margin-bottom: 0px;
    color: #7C6991;
    /* margin-top: 49px; */
    margin-top: 32px;
}

.about-inbrija-ddg .thankyou-modal p {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 0px;
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #4E4E4E;
    margin-top: 12px;
}

.about-inbrija .inbox-modal button .svg-mail {
    padding-right: 8px;
    height: 28px;
    width: 28px;
}

.about-inbrija-ddg .thankyou-modal input {
    background: #FFFFFF;
    width: 365px;
    margin-top: 31px;
    border: none;
    border-bottom: 1px solid #7C6991 !important;
}

.about-inbrija-ddg .thankyou-modal .a-margin {
    margin-top: 63px !important;
}

.about-inbrija-ddg .thankyou-modal a {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-decoration-line: underline;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #7C6991;

}

.about-inbrija-ddg .thankyou-modal .thankyou-buttons {
    display: flex;
    gap: 20px;
    justify-content: center;
    /* margin-top: 15px; */
    margin-top: 26px;
}

.about-inbrija .thankyou-modal .thankyou-buttons button svg {
    margin-right: 8px;
}

.about-inbrija-ddg .thankyou-modal .thankyou-buttons button {
    background: #7C6991;
    border-radius: 100px;
    padding: 12px 24px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    text-transform: none !important;
    gap: 8px;
}
.about-inbrija-ddg .thankyou-modal .thankyou-buttons button svg{
    margin-right: 8px;
}

.about-inbrija-ddg .thankyou-modal .thankyou-buttons button:hover {
    background: #7C6991;
}

.about-inbrija-ddg .ddg-email-modal {}

.about-inbrija-ddg .ddg-email-modal h2 {
    color: #4B3F67;
    text-align: center;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin-top: 31px;
    margin-bottom: 0px;
}

.about-inbrija-ddg .ddg-email-modal h1 {
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
    margin-bottom: 0px;
    color: #7C6991;
    margin-top: 64px;
}

.about-inbrija-ddg .ddg-email-modal p {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
    margin-top: 15px;
    margin-bottom: 0px;
    color: #4E4E4E;
}

.about-inbrija-ddg .ddg-email-modal .date-input input {
    background: #FFFFFF;
    margin-top: 16px;
    border: none;
    border: 1px solid #4e4e4e !important;
    padding: 12px 12px 10px;
    max-width: 490px;
    width: 100%;
    background: #fff url('/public/assets/images/calender-icon.png') no-repeat center right;
    background-size: 15px;
    background-position: 95%;
    border-radius: 10px;
}

.about-inbrija-ddg .ddg-email-modal .date-input {
    display: flex;
    justify-content: center;
    position: relative;
}

.datepicker-header .datepicker-controls .btn {
    border-color: transparent;
    font-weight: bold;
    background: #4B3F67 !important;
}

.about-inbrija-ddg .ddg-email-modal .date-input .cal-input {
    width: 60px;
    margin-top: 31px;
    border: none;
    border-bottom: 1px solid #7C6991 !important;
    padding: 12px 12px 10px;
    background: #fff url('/public/assets/images/calender-icon.png') no-repeat center right;
    background-size: 25px;
}

.about-inbrija-ddg .ddg-email-modal .content {
    background: #F5F5F5 !important;
    padding-top: 32px !important;
    padding-bottom: 32px !important;
    margin-top: 25.5px;
}

.about-inbrija-ddg .ddg-email-modal .content p {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 0px;
    text-align: left;
    padding-left: 60px;
    padding-right: 0px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #4E4E4E;
}

.about-inbrija-ddg .ddg-email-modal .content li {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    text-align: left;
    color: #747474;
    width: calc(100% / 3 - 45px);
    position: relative;
    margin-top: 20px;
    align-items: flex-start;
}

.about-inbrija-ddg .ddg-email-modal .content ul {
    display: flex;
    /* margin-top: 20px; */
    align-items: flex-start;
    justify-content: space-between;
    padding-left: 80px;
    padding-right: 70px;
}

.about-inbrija-ddg .ddg-email-modal .content p span {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 0px;
    text-align: left;
    text-decoration: underline;
    padding-right: 0px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #4E4E4E;
}

.about-inbrija-ddg .ddg-email-modal .input-field input {
    padding: 12px 24px 12px 24px;
    background: #FFFFFF;
    border: 1px solid #7C6991;
    border-radius: 10px;
    width: 100%;
    margin-top: 15px;
}

.about-inbrija-ddg .ddg-email-modal .input-field {
    max-width: 490.29px;
    width: 100%;
}

.about-inbrija-ddg .ddg-email-modal .input-field .required {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
    margin-top: 15px;
    margin-bottom: 0px;
    color: #4E4E4E;
    text-align: left;
    margin-top: 10px;
}


.about-inbrija-ddg .ddg-email-modal .email-buttons {
    display: flex;
    justify-content: space-between;
    padding: 31px 50px 24px 50px;
}

.about-inbrija-ddg .ddg-email-modal .email-buttons .email-back {
    border: 2px solid #53565A;
    border-radius: 100px;
    padding: 15px 30px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #53565A;
    background: none;
    opacity: 0.5;
}

.about-inbrija-ddg .ddg-email-modal .email-buttons .email-next {
    background: #7C6991;
    border-radius: 100px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
    border: none;
    padding: 15px 30px;
    color: #FFFFFF;
}

.about-inbrija-ddg .ddg-email-modal .email-buttons .email-next:hover {
    background: #685778;
}

.about-inbrija-ddg .inbox-modal {
    padding-top: 103.07px;
    padding-bottom: 17px;
}

.about-inbrija-ddg .inbox-modal h1 {
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
    margin-bottom: 0px;
    color: #7C6991;
}

.about-inbrija-ddg .inbox-modal p {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 0px;
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #4E4E4E;
    margin-top: 12px;
}


.about-inbrija .inbox-modal svg {
    margin-right: 8px;
}

.about-inbrija-ddg .inbox-modal button {
    background: #7C6991;;
    border-radius: 100px;
    padding: 12px 24px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;
    border: none;
    color: #FFFFFF;
    margin-top: 27px;
}

.about-inbrija-ddg .inbox-modal button svg{
    margin-right: 8px;
}

.about-inbrija-ddg .inbox-modal button:hover {
    background: #7C6991;
}

.about-inbrija-ddg .inbox-modal .a-padding {
    margin-top: 69px;
    text-align: center;
}

.about-inbrija-ddg .inbox-modal .a-padding a {
    margin-bottom: 0px;
    margin-top: 69px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-decoration-line: underline;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #7C6991;

}

.about-inbrija-ddg .ddg-email-modal .input-field .required.error {
    color: #780000;
}

.about-inbrija-ddg .ddg-email-modal .input-field input.error {
    border-color: #780000;
}

.about-inbrija-ddg .ddg-email-modal .input-field input.error::placeholder {
    color: #780000;
    opacity: 1;
}

.about-inbrija-ddg .ddg-email-modal .input-field input.error:-ms-input-placeholder {
    color: #780000;

}

.about-inbrija-ddg .ddg-email-modal .input-field input.error::-ms-input-placeholder {
    color: #780000;
}

.about-inbrija .ddg-saving-desktop {
    background: #4B3F67;
    margin-top: 80px;
}

.about-inbrija .ddg-saving {
    padding-top: 59px;
    padding-bottom: 33px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1230px;
    width: 100%;
}

.about-inbrija .ddg-saving button {
    background: #7C6991;
    border-radius: 100px;
    padding: 12px 24px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    border: none;
}

.about-inbrija .ddg-saving button:hover {
    background: #685778;
}

.about-inbrija .ddg-saving-left {
    display: flex;
    align-items: center;
    /* gap: 28px; */
}


.about-inbrija .ddg-saving-left img {
    max-width: 97.5px;
    width: 100%;
    height: auto;
    margin-top: 8px;
}

.about-inbrija .ddg-saving-left .ddg-saving-content {
    text-align: left;
    margin-left: 28px;
}


.about-inbrija .ddg-saving-left .ddg-saving-content h6 {
    margin-bottom: 0px;
    font-style: normal;
    font-weight: 300;
    font-size: 26px;
    line-height: 124%;
    color: #FFFFFF;
}

.about-inbrija .ddg-saving-left .ddg-saving-content h2 {
    margin-bottom: 0px;
    font-style: normal;
    font-weight: 700;
    font-size: 51px;
    line-height: 54px;
    margin-top: 8px;
    color: #FFFFFF;
}

.about-inbrija .ddg-saving-left .ddg-saving-content p {
    margin-bottom: 0px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    margin-top: 40px;
    color: #FFFFFF;
}

.about-inbrija .ddg-saving-left .ddg-saving-content p sup {
    margin-bottom: 0px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 8px;
    margin-top: 40px;
    color: #FFFFFF;
    top: -3px;
}

.about-inbrija .ddg-saving-left .ddg-saving-content sup {
    top: -20px;
    width: 14px;
    font-family: 'Arial';
    font-weight: 700;
    font-size: 20px;
}

.about-inbrija .about-ddg {
    /* background-color: #F4F2F7; */
    background: linear-gradient(72deg, #4B3F67 11.86%, #5D4C83 93.49%);
}

/* about-ddg-new */

.about-inbrija .about-ddg .ddg-blank {
    display: flex;
    align-items: center;
    padding-top: 93px;
    padding-bottom: 43px;
}

.about-inbrija .about-ddg .ddg-blank .ddg-blank-content {
    max-width: 534px;
    width: 100%;
    text-align: left;
}

.about-inbrija .about-ddg .ddg-blank .ddg-blank-content .buttons-blank-ddg {
    display: flex;
    gap: 24px;
}

.about-inbrija .about-ddg .ddg-blank .ddg-blank-content .buttons-blank-ddg button {
    display: flex;
    height: 48px;
    padding: 12px 24px;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    background-color: #7C6991;
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.32px;
    border: none;
    border-radius: 100px;
}

.about-inbrija .about-ddg .ddg-blank .ddg-blank-content h1 {
    color: #FFF;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 24px;
}

.about-inbrija .about-ddg .ddg-blank .ddg-blank-content p {
    color: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 24px;
}

.about-inbrija .practice-banner-section .img-practice-banner {
    display: none;
}

.about-inbrija .ddg-appointment {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 75.5px;

}

.about-inbrija .ddg-appointment .ddg-appointment-content {
    padding-left: 177px;
}

.about-inbrija .ddg-appointment .ddg-appointment-content h1 {
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 42px;
    color: #4B3F67;
    text-align: left;
    margin-bottom: 0px;
}

.about-inbrija .ddg-appointment .ddg-appointment-content p {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    max-width: 706px;
    width: 100%;
    margin-top: 24px;
    letter-spacing: -0.01em;
    color: #4B3F67;
    text-align: left;
    margin-bottom: 0px;
}

.about-inbrija .ddg-appointment .ddg-appointment-img img {
    max-width: 412px;
    width: 100%;
}

.about-inbrija .ddg-appointment .ddg-appointment-img p {
    font-style: italic;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 0px;
    letter-spacing: -0.02em;
    color: #4B3F67;
}

.about-inbrija .ddg-appointment .ddg-appointment-img h6 {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    margin-top: 2px;
    margin-bottom: 0px;
    letter-spacing: -0.02em;
    color: #4B3F67;
    /* text-decoration: underline; */
}

.about-inbrija .ddg-appointment .ddg-appointment-img h6:hover {
    color: #7C6991;
}



.about-inbrija .ddg-appointment-form {
    /* margin-top: 82px; */
    padding-bottom: 90px;
}

.about-inbrija .about-ddg .ddg-questions-heading h1 {
    color: #FFF;

    text-align: center;
    margin-bottom: 12px;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.about-inbrija .about-ddg .ddg-questions-heading p {
    color: #FFF;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 31px;
}

.about-inbrija .ddg-appointment-form .ddg-appointment-form-container {
    border-radius: 8px;
    background: #FFF;
    padding: 32px;
}

.about-inbrija .ddg-appointment-form .saperation {
    border: 4px solid #EAE7F1;
}

.about-inbrija .ddg-appointment .steps-ddg {}

.about-inbrija .ddg-appointment-form .steps-ddg .step1 h6 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 0px;
    text-align: center;
    color: #4B3F67;
    margin-top: 66px;
}

.about-inbrija .ddg-appointment-form .steps-ddg .step1 h1 {
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 42px;
    color: #7C6991;
    margin-bottom: 0px;
    /* margin-top: 16px; */
    margin-top: 102px;
}

.about-inbrija .ddg-appointment-form .steps-ddg .step1 p {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    margin-top: 8px;
    color: #7C6991;
}

.about-inbrija .ddg-appointment-form .steps-ddg .step1 .options {
    margin-top: 18px;
}

.about-inbrija .ddg-appointment-form .steps-ddg .step1 .options .check {
    height: 24px !important;
    width: 24px !important;
    vertical-align: top;
}

.about-inbrija .ddg-appointment-form .steps-ddg .step1 .options label {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    margin-left: 12px;
    text-align: left;
    color: #4E4E4E;
}

.about-inbrija .ddg-appointment-form .steps-ddg .step1 .options .checkboxes_inb {
    display: flex;
    /* justify-content: left;
    gap: 120px; */
    justify-content: flex-start;
}

.about-inbrija .ddg-appointment-form .steps-ddg .step1 .options .checkboxes_inb .margin-120 {
    margin-left: 120px;
    margin-top: 0px;
}

.about-inbrija .ddg-appointment-form .steps-ddg .step1 .options .checkmargin1 {
    margin-top: 30px;
}

.about-inbrija .ddg-appointment-form .steps-ddg .step1 .options .checkmargin2 {
    margin-top: 20px;
}

.about-inbrija .ddg-appointment-form .steps-ddg .step1 .options .activities-checkboxes {
    display: flex;
    justify-content: flex-start;
    /* gap: 120px; */
    padding-left: 30px;
    max-width: 1019px;
    width: 100%;
}

.about-inbrija .ddg-appointment-form .steps-ddg .step1 .options .activities-checkboxes .margin-120 {
    margin-left: 120px;
    margin-top: 0px;
}


.about-inbrija .ddg-appointment-form .steps-ddg .step1 .options .activities-checkboxes .margin-left {
    padding-left: 14px;
}

.about-inbrija .ddg-appointment-form .steps-ddg .step1 .options .checkmargin1 {
    margin-top: 38px;
}

.about-inbrija .ddg-appointment-form .steps-ddg .step1 .options .checkmargin2 {
    margin-top: 20px;
    margin-bottom: 42px;
}


.about-inbrija .ddg-appointment-form .steps-ddg .step1 .radio-buttons {
    max-width: 554px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 32px;
}

.about-inbrija .ddg-appointment-form .steps-ddg .step1 .radio-buttons .radio {
    display: flex;
    justify-content: left;
    margin-bottom: 100px;
}

.about-inbrija .ddg-appointment-form .steps-ddg .step1 .radio-buttons .radio .radio-css {
    width: 24px !important;
    height: 24px !important;
}

.about-inbrija .ddg-appointment-form .steps-ddg .step1 .radio-buttons .radio label {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    margin-left: 12px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #4E4E4E;
}

.about-inbrija .ddg-appointment-form .steps-ddg .step1 .back-next {
    display: flex;
    justify-content: space-between;
    /* padding-bottom: 40px; */
    align-items: center;
}

.about-inbrija .ddg-appointment-form .steps-ddg .step1 .back-next button {
    background: #7C6991;
    border-radius: 100px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
    border: none;
    padding: 15px 30px;
    color: #FFFFFF;
}

.about-inbrija .ddg-appointment-form .steps-ddg .step1 .back-next button:hover {
    background-color: #685778;
}

.about-inbrija .ddg-appointment-form .steps-ddg .step1 .back-next .back {
    border: 1px solid #7C6991 !important;
    border-radius: 100px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
    background: none;
    padding: 15px 30px;
    color: #7C6991;
}

.about-inbrija .ddg-appointment-form .steps-ddg .step1 .back-next .back-none {
    border-radius: 100px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
    background: none;
    padding: 15px 30px;
    color: #7C6991;
    width: 150px;
}

.about-inbrija .ddg-appointment-form .steps-ddg .step1 .margintop {
    margin-top: 40px;
}

.about-inbrija .ddg-appointment-form .steps-ddg .step1 .back-next .bullet {
    width: 10px;
    height: 10px;
    background: #7C6991;
    transform: rotate(-90deg);
    border-radius: 50%;
}

.about-inbrija .ddg-appointment-form .steps-ddg .step1 .back-next .bullet2 {
    width: 10px;
    height: 10px;
    background: #D6D3DD;
    transform: rotate(-90deg);
    border-radius: 50%;
}

.about-inbrija .ddg-appointment-form .steps-ddg .step1 .back-next .bullets {
    max-width: 70px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.about-inbrija .thankyou-step h1 {
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
    margin-top: 50px;
    text-transform: uppercase;
    margin-bottom: 0px;
    color: #7C6991;
    margin-top: 40px;
}

.about-inbrija .thankyou-step h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
    margin-bottom: 0px;
    color: #7C6991;
    margin-top: 32px;
}

.about-inbrija .thankyou-step p {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 0px;
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #4E4E4E;
    margin-top: 12px;
}

.about-inbrija .thankyou-step .date-input input {
    background: #FFFFFF;
    margin-top: 31px;
    border: none;
    border-bottom: 1px solid #7C6991 !important;
    padding: 12px 12px 10px;
    max-width: 377px;
    width: 100%;
    background: #fff url('/public/assets/images/calender-icon.png') no-repeat center right;
    background-size: 15px;
    background-position: 95%;
}

.about-inbrija .thankyou-step .date-input {
    display: flex;
    justify-content: center;
    position: relative;
}

.datepicker-header .datepicker-controls .btn {
    border-color: transparent;
    font-weight: bold;
    background: #4B3F67 !important;
}

.about-inbrija .thankyou-step .date-input .cal-input {
    width: 60px;
    margin-top: 31px;
    border: none;
    border-bottom: 1px solid #7C6991 !important;
    padding: 12px 12px 10px;
    background: #fff url('/public/assets/images/calender-icon.png') no-repeat center right;
    background-size: 25px;
}


.about-inbrija .thankyou-step .a-margin {
    margin-top: 63px !important;
    padding-bottom: 51px;
}

.about-inbrija .thankyou-step a {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-decoration-line: underline;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #7C6991;
}

.about-inbrija .thankyou-step .thankyou-buttons {
    display: flex;
    /* gap: 20px; */
    justify-content: center;
    margin-top: 50px;
}

.about-inbrija .thankyou-step .thankyou-buttons .about_dynDDG {
    margin-left: 20px;
    display: flex;
    gap: 5px;
    justify-content: center;
}

.about-inbrija .thankyou-step button .svg-mail {
    padding-right: 8px;
    height: 28px;
    width: 28px;
}

.about-inbrija .thankyou-step button {
    background: #7c6991;
    border-radius: 100px;
    padding: 12px 24px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    border: none;
}

.about-inbrija .thankyou-step button:hover {
    background: #4B3F67;
}

.datepicker-view {
    align-items: stretch;
    width: auto !important;
}

.datepicker-dropdown {
    max-width: 377px !important;
    width: 100% !important;
}

.about-inbrija .copay-section {
    background: #4B3F67;
    padding: 40px 0 25px;
    text-align: -webkit-center;
}

.about-inbrija .copay-section .container-copay {
    max-width: 1024px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.about-inbrija .copay-section .container-copay .o-copay-section {
    display: flex;
}

.about-inbrija .copay-section .container-copay .o-copay-section .o-copay-section-content {
    margin-left: 28px;
    /* align-items: center;
    display: flex;
    flex-direction: column;
    text-align: left; */
    text-align: left;
}

.about-inbrija .copay-section .container-copay .o-copay-section .o-copay-section-content p {
    margin-bottom: 11px;
    color: #FFF;
    font-size: 26px;
    font-style: normal;
    font-weight: 300;
    line-height: 124%;
}

.about-inbrija .copay-section .container-copay .o-copay-section .o-copay-section-content p sup{
    font-family: Arial;
    font-size: 20px;
    font-weight: 400;
    top: -12px;
    line-height:32.24px;

}
.about-inbrija .copay-section .container-copay .o-copay-section .o-copay-section-content small sup{
    font-family: Arial;
    font-size: 7px;
    font-weight: 400;
    top: -4px;
    line-height:32.24px;
}

.about-inbrija .copay-section .container-copay .o-copay-section .o-copay-section-content p b {
    font-weight: 700;
}

.about-inbrija .copay-section .container-copay .o-copay-section .o-copay-section-content small {
    color: rgba(255, 255, 255, 0.80);
    font-family: Raleway;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left !important;
    margin-bottom: 0;
}

.about-inbrija .copay-section .container-copay .o-copay-section img {
    width: 69px;
    height: 54px;
}

.about-inbrija .copay-section .container-copay button {
    border-radius: 100px;
    background: #594A7D;
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.32px;
    padding: 12px 24px;
    border: none;
    height: fit-content;
}



@media (max-width:1400px) {
    .about-inbrija .cine-css {
        width: max-content;
        max-width: 780px;
        position: relative;
        left: -55px;
    }

}

@media (max-width:1024px) {
    .about-inbrija .ddg-section {
        padding: 56px 0 50px;
    }

    .about-inbrija .ddg-section .ddg-section-container .ddg-section-left {
        max-width: 100%;
        display: block;
        padding-right: 0;
        border-bottom: 4px solid #EAE7F1;
        padding-bottom: 60px;

    }

    .about-inbrija .ddg-section .ddg-section-container .ddg-section-left .ddg-section-left-image {
        max-width: 330px;
        margin: auto;
        margin-bottom: 20px;
    }

    .about-inbrija .ddg-section .ddg-section-container .ddg-section-left-content {
        max-width: 100%;
    }

    .about-inbrija .ddg-section .ddg-section-container .ddg-section-left-content p {
        margin-bottom: 33px;
    }

    .about-inbrija .ddg-section .ddg-section-container .ddg-section-left-content p.tt {
        font-weight: 500;
        font-size: 15px;
        line-height: 16px;
        letter-spacing: 0.07em;
        text-transform: uppercase;
        color: #4B3F67;
        margin-bottom: 12px;
    }

    .about-inbrija .ddg-section .ddg-section-container .ddg-section-left-content h3 {
        font-weight: 400;
        font-size: 36px;
        line-height: 42px;
        color: #4B3F67;
    }

    .about-inbrija .ddg-section .ddg-section-container .ddg-section-left-content a.show_desktop {
        display: none !important;
    }

    .about-inbrija .ddg-section .ddg-section-container .ddg-section-left-content a.show_mobile {
        display: flex;
        margin-bottom: 12px;
        text-align: center;
        align-items: center;
        gap: 10px;
        justify-content: center;
    }

    .about-inbrija .ddg-section .ddg-section-container .ddg-section-left-content a.show_mobile:last-child {
        margin-bottom: 0;
    }

    .about-inbrija .ddg-section .ddg-section-container .ddg-section-right {
        padding-left: 0;
        border: none;
        margin-top: 48px;
    }

    .about-inbrija .ddg-section .ddg-section-container .ddg-section-right a {
        display: none;
    }

    .about-inbrija .ddg-section .ddg-section-container .ddg-section-right p {
        margin: 24px 0 0px;
    }

    .about-inbrija .ddg-appointment .ddg-appointment-content {
        padding-left: 0px;

    }

    .about-inbrija .ddg-appointment-form .steps-ddg .step1 .options .checkboxes_inb {
        display: flex;
        justify-content: left;
        /* gap: 20px; */
        flex-direction: column;
        text-align-last: left;
    }

    .about-inbrija .ddg-appointment-form .steps-ddg .step1 .options .activities-checkboxes {
        display: flex;
        justify-content: left;
        flex-direction: column;
        /* gap: 20px; */
        padding-left: 0px;
        width: 100%;
        text-align-last: left;
    }

    .about-inbrija .ddg-appointment-form .steps-ddg .step1 .options .activities-checkboxes .margin-left {
        padding-left: 0px;
    }

    .about-inbrija .ddg-appointment-form .steps-ddg .step1 .options .checkboxes_inb .margin-120 {
        margin-left: 0px;
        margin-top: 20px;
    }

    .about-inbrija .ddg-appointment-form .steps-ddg .step1 .options .activities-checkboxes .margin-120 {
        margin-left: 0px;
        margin-top: 20px;
    }

    .about-inbrija .copay-section .container-copay .o-copay-section {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .about-inbrija .copay-section .container-copay {
        /* padding-right: 20px !important;
        padding-left: 20px !important; */
        padding: 0px 24px;
    }

    .about-inbrija .copay-section .container-copay button {
        margin-top: 24px;
    }

    .about-inbrija .copay-section .container-copay .o-copay-section .o-copay-section-content {
        margin-left: 0px;
        margin-top: 24px;
    }

    .about-inbrija .about-ddg .ddg-blank {
        flex-direction: column;
        padding: 48px 0px;
    }

    .about-inbrija .about-ddg .ddg-blank .ddg-blank-content {
        max-width: 100%;
    }

    .about-inbrija .about-ddg .ddg-blank .ddg-blank-content .buttons-blank-ddg {
        flex-direction: column;
    }

    .about-inbrija .about-ddg .ddg-blank .ddg-blank-content .buttons-blank-ddg button {
        place-content: center;
    }
    .about-inbrija .about-ddg {
        padding: 0px 24px;
    }

    #blank-ddg-id{
        padding: 0 !important;
    }
}

@media (min-width:1025px) and (max-width:1100px) {
    .about-inbrija .practice-banner-content {
        margin-left: 25%;
    }

    .about-inbrija .ddg-section .ddg-section-container .ddg-section-left .ddg-section-left-image {
        max-width: 200px;
    }

    .about-inbrija .ddg-section .ddg-section-container .ddg-section-left-content {
        max-width: calc(100% - 200px - 20px);
    }
}

@media (min-width:991px) and (max-width:1280px) {
#about_DDG_img{
    width: 50%;
}
}

@media (min-width:768px) and (max-width:1024px) {
    .about-inbrija .practice-banner-content {
        margin-left: 22%;
    }

    .about-inbrija .practice-banner-content h3 {
        font-size: 22px;
    }

    .about-inbrija .practice-banner-content p {
        font-size: 16px;
        line-height: 18px;
    }

    /* .about-inbrija .inbrija-anywhere-video-section .video-buttons {
        margin-bottom: 150px;
    } */

    .about-inbrija .inbrija-anywhere-video-section {
        top: 130px;
    }
}

@media (max-width:1090px) and (min-width:902px) {
    .about-inbrija .hero-section {
        min-height: auto;
    }

    .about-inbrija .hero-section .hero-section-content h1 {
        font-size: 42px;
        line-height: 46px;
    }

    .about-inbrija .hero-section .hero-section-content h1 span {
        line-height: 42px;
    }

    .about-inbrija .hero-section .hero-section-content h4 {
        font-size: 20px;
        line-height: 24px;
    }
}

@media (max-width:901px) and (min-width:768px) {
    .about-inbrija .hero-section {
        min-height: auto;
    }

    .about-inbrija .hero-section .hero-section-content h1 {
        font-size: 36px;
        line-height: 40px;
    }

    .about-inbrija .hero-section .hero-section-content h1 span {
        line-height: 40px;
    }

    .about-inbrija .hero-section .hero-section-content h4 {
        font-size: 16px;
        line-height: 20px;
    }
}

@media (max-width:991px) {
    .about-inbrija .inbrija-orally-inhaled-section-bottom .inbrija-orally-inhaled-section-bottom-items {
        display: block;
    }

    .about-inbrija .br-inhaler {
        display: none;
    }

    .about-inbrija .inbrija-orally-inhaled-section-bottom .inbrija-orally-inhaled-section-bottom-items .inbrija-orally-inhaled-section-bottom-item {
        margin: auto;
        margin-bottom: 40px;
    }

    .about-inbrija .inbrija-orally-inhaled-section-bottom .inbrija-orally-inhaled-section-bottom-items .inbrija-orally-inhaled-section-bottom-item:last-child {
        margin-bottom: 0;
    }

    .about-inbrija .practice-banner-section {
        background: none;
        position: relative;
        height: auto;
        margin-bottom: 0;
    }

    .about-inbrija-ddg .ddg-email-modal .content li {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
        text-align: left;
        color: #747474;
        width: calc(100% / 3 - 45px);
        position: relative;
        margin-top: 0px;
        align-items: flex-start;
    }


    /* .about-inbrija .practice-banner-section::before {
        background: url('/public/assets/images/practice-banner-mobile.webp') no-repeat center center;
        background-size: 100% 100%;
        height: 286px;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        content: '';
    } */

    .about-inbrija .practice-banner-content {
        max-width: 100%;
        padding: 0 20px;
        position: static;
        margin: 50px auto 0;
        margin-left: 0;
    }

    .about-inbrija .practice-banner-content h3,
    .about-inbrija .practice-banner-content p {
        margin: 0 auto 12px;
        max-width: 100%;
    }

    .about-inbrija .practice-banner-section .img-practice-banner {
        display: block;

    }

    .about-inbrija .practice-banner-section .img-practice-banner img {
        width: 100%;

    }

    .about-inbrija .copay-section .container-copay {
        flex-direction: column;
        justify-content: center;
    }

}

@media (min-width: 1000px) and (max-width: 1178px) {
    .about-inbrija .inbrija-anywhere-video-section {
        top: 80px;
    }

    .about-inbrija .inbrija-anywhere-video-section .video-buttons {
        margin-bottom: 120px;
    }
}

@media (min-width: 768px) and (max-width: 1000px) {
    .about-inbrija .inbrija-anywhere-video-section .video-buttons {
        margin-bottom: 170px;
    }
}

@media (max-width:767px) {
    .about-inbrija .hero-section {
        flex-direction: column-reverse;
        margin-top: 44px;
        padding: 0;
        min-height: auto;
        gap: 0;
    }

    .about-inbrija .ddg-saving-left .ddg-saving-content {
        margin-left: 0px;
    }

    .about-inbrija .hero-section .hero-section-content {
        display: block;
        max-width: 100%;
        padding: 0;
    }

    .about-inbrija .inbrija-anywhere-video-section .video-buttons .how-to-use-button {
        margin-bottom: 12px;
    }

    .about-inbrija .hero-section .hero-section-content h4 {
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.02em;
        padding: 0 20px 40px;
        gap: 20px;
        align-items: flex-start;
        background: #000;
    }

    .about-inbrija .hero-section .hero-section-content h4 img {
        height: auto;
    }

    .about-inbrija .hero-section .hero-section-content h4 span {
        max-width: calc(100% - 56px - 20px);
    }

    .about-inbrija .hero-section .hero-section-content h1 {
        font-size: 36px;
        line-height: 40px;
        padding: 0 20px;
    }

    .about-inbrija .hero-section .hero-section-content h1 span {
        line-height: 47px;
        font-size: 47px;
        display: block;
        margin-top: 19px;
    }

    .about-inbrija .hero-section .hero-section-content .show_mobile {
        position: relative;
    }

    .about-inbrija .hero-section .hero-section-content .show_mobile .actor_portrayal {
        left: 20px;
        right: auto;
        bottom: 31px !important;
        position: absolute;
    }

    .about-inbrija .about-inbrija-intro-section {
        padding: 60px 20px 40px;
    }

    .about-inbrija .about-inbrija-intro-section h3 {
        font-weight: 400;
        font-size: 28px;
        line-height: 32px;
    }

    .about-inbrija .about-inbrija-intro-section p {
        font-size: 16px;
        line-height: 20px;
    }

    .about-inbrija .about-inbrija-rescue-section {
        padding: 55px 0;
    }

    .about-inbrija .inbrija-anywhere-section .inbrija-anywhere-section-container-left h6 {
        width: 100%;
    }

    .about-inbrija .about-inbrija-rescue-section .about-inbrija-rescue-section-left {
        max-width: 100%;
        margin-bottom: 15px;
    }

    .about-inbrija .ddg-appointment-form .steps-ddg .step1 .back-next .back-none {
        border-radius: 100px;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        font-feature-settings: 'pnum' on, 'lnum' on;
        background: none;
        padding: 15px 30px;
        color: #7C6991;
        width: 50px;
    }

    .about-inbrija .about-inbrija-rescue-section .about-inbrija-rescue-section-left h3 {
        font-size: 28px;
        line-height: 32px;
    }

    .about-inbrija .about-inbrija-rescue-section .about-inbrija-rescue-section-left p {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
    }

    .about-inbrija .about-inbrija-rescue-section .about-inbrija-rescue-section-right {
        margin: auto;
        background-size: 100% 100%;
    }

    .about-inbrija .about-inbrija-rescue-section .about-inbrija-rescue-section-right .about-inbrija-rescue-section-right-splash {
        margin: auto;
    }

    .about-inbrija .about-inbrija-rescue-section .about-inbrija-rescue-section-right p {
        padding-top: 10px;
        margin-bottom: 10px;
    }

    .about-inbrija .safety-info {
        margin: 45px 0 51px;
    }

    .about-inbrija .safety-info h6 {
        padding-top: 12px;
        font-size: 20px;
        line-height: 24px;
    }

    .about-inbrija .inbrija-anywhere-section {
        padding: 0px 0 46px;
    }

    .about-inbrija .inbrija-anywhere-section .inbrija-anywhere-section-container {
        flex-direction: column-reverse;
        padding-left: 0;
    }

    .about-inbrija .inbrija-anywhere-section .inbrija-anywhere-section-container-right {
        max-width: 100%;
    }

    .about-inbrija .inbrija-anywhere-section .inbrija-anywhere-section-container-left {
        max-width: 100%;
        padding: 0 20px;
        margin-top: -20px;
        margin-left: 0;
        z-index: 1;
    }

    .about-inbrija .inbrija-anywhere-section .inbrija-anywhere-section-container-left img {
        max-width: 80%;
    }

    .about-inbrija .inbrija-anywhere-section .inbrija-anywhere-section-container-left h3 {
        font-size: 36px;
        line-height: 42px;
        letter-spacing: -0.02em;
        position: relative;
        margin-bottom: 31px;
    }

    .about-inbrija .inbrija-anywhere-section .inbrija-anywhere-section-container-left::before {
        display: none;
    }

    .about-inbrija .inbrija-anywhere-section .inbrija-anywhere-section-container-left h3::before {
        content: '';
        background-size: cover;
        position: absolute;
        left: 0;
        top: 5px;
        height: 20px;
        width: 20px;

    }

    .about-inbrija .inbrija-anywhere-section .inbrija-anywhere-section-container-left h3 span {
        position: relative;
        display: inline-block;
    }

    .about-inbrija .inbrija-anywhere-section .inbrija-anywhere-section-container-left p {
        margin-bottom: 16px;
    }

    .about-inbrija .inbrija-anywhere-section::after {
        display: none;
    }

    .about-inbrija .inbrija-anywhere-video-section {
        margin-top: 0;
        margin-bottom: 52px;
    }

    .about-inbrija .inbrija-anywhere-video-section .video-buttons a {
        font-size: 14px;
        line-height: 16px;
    }

    .about-inbrija .inbrija-anywhere-video-section .video-buttons {
        padding: 0 20px;
        margin-top: 30px;
    }

    .about-inbrija .inbrija-return-section {
        padding: 36px 0;
    }

    .about-inbrija .inbrija-return-section .inbrija-return-section-container {
        padding: 0 20px;
    }

    .about-inbrija .inbrija-return-section .inbrija-return-section-container h3 {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        max-width: 300px;
        margin: 0 auto 12px;
    }

    .about-inbrija .inbrija-return-section .inbrija-return-section-container p {
        font-size: 18px;
        line-height: 24px;
    }

    .about-inbrija .inbrija-orally-inhaled-section-top {
        padding: 40px 0 56px;
        display: block;
    }

    .about-inbrija .inbrija-orally-inhaled-section-top-image {
        margin: auto;
        margin-bottom: 32px;
    }

    .about-inbrija .inbrija-orally-inhaled-section-top-content h3 {
        font-size: 18px;
        line-height: 24px;
    }

    .about-inbrija .inbrija-orally-inhaled-section-top-content {
        width: 100%;
    }

    .about-inbrija .inbrija-orally-inhaled-section-top-content ul {
        list-style: none;
        padding-left: 0;
    }

    .about-inbrija .inbrija-orally-inhaled-section-top-content ul li {
        font-size: 16px;
        line-height: 20px;
        padding-left: 20px;
        position: relative;
        margin-bottom: 2px;
    }

    .about-inbrija .inbrija-orally-inhaled-section-top-content ul li::before {
        content: '•';
        left: 7px;
        top: 0;
        font-size: 30px;
        position: absolute;
        color: #7C6991 !important;
    }

    .about-inbrija .inbrija-orally-inhaled-section-top-content ul li ul li::before {
        content: '○';
        left: 7px;
        top: 0;
        font-size: 14px;
        position: absolute;
        color: #7C6991 !important;
    }

  
    .about-inbrija .inbrija-orally-inhaled-section-top-content ul ul {
        margin-top: 0;
    }

    .about-inbrija .inbrija-orally-inhaled-section-top-content ul li span {
        display: inline !important;
    }

    .about-inbrija .inbrija-orally-inhaled-section-bottom {
        padding: 50px 0 10px;
    }

    .about-inbrija .inbrija-orally-inhaled-section-bottom .inbrija-orally-inhaled-section-bottom-items .inbrija-orally-inhaled-section-bottom-item {
        max-width: 90%;
        margin: auto;
        margin-bottom: 40px;
    }

    .about-inbrija .inbrija-orally-inhaled-section-bottom .inbrija-orally-inhaled-section-bottom-items .inbrija-orally-inhaled-section-bottom-item h3 {
        font-size: 20px;
        line-height: 24px;
    }

    .about-inbrija .inbrija-orally-inhaled-section-bottom .inbrija-orally-inhaled-section-bottom-items .inbrija-orally-inhaled-section-bottom-item p {
        font-feature-settings: 'pnum' off, 'lnum' off;
    }

    .about-inbrija .safety-info-2 h6 {
        padding-top: 0;
        margin-top: 69px;
        border: none;
        margin-bottom: 0;
    }

    .about-inbrija .safety-info-2 h5 {
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: #4E4E4E;
        margin-top: 12px;
    }

    .about-inbrija .safety-info ul li {
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 4px;
    }

    .about-inbrija .safety-info ul {
        padding-left: 0;
        list-style: none;
    }

    .about-inbrija .safety-info ul li {
        padding-left: 20px;
        position: relative;
    }

    .about-inbrija .safety-info ul li::before {
        content: '•';
        left: 7px;
        top: 0;
        font-size: 30px;
        position: absolute;
        color: #7C6991 !important;
    }

    .about-inbrija .safety-info ul li::marker {
        color: #7C6991 !important;
    }

    .about-inbrija .safety-info h6.text-underline {
        margin-top: 0;
        font-weight: 700;
    }

    .about-inbrija .safety-info ul li b {
        font-weight: 700;
    }

    .about-inbrija .ddg-appointment {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
    }

    .about-inbrija .about-inbrija-rescue-section .about-inbrija-rescue-section-right h3 {
        margin-bottom: 0;
        text-align: center;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 124%;
        text-align: center;
        letter-spacing: 0.03em;
        color: #4B3F67;
    }

    .about-inbrija .about-inbrija-rescue-section .about-inbrija-rescue-section-right p {
        font-style: normal;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 0;
        text-align: center;
        color: #685778;
    }

    .about-inbrija .ddg-saving {
        padding-top: 0px;
        padding-bottom: 33px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
    }

    .about-inbrija .ddg-saving-left {
        display: flex;
        gap: 0px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .about-inbrija .ddg-saving-left .ddg-saving-content {
        text-align: center;
    }

    .about-inbrija .ddg-saving-left .ddg-saving-content h6 {
        margin-bottom: 0px;
        font-style: normal;
        font-weight: 300;
        font-size: 26px;
        line-height: 124%;
        color: #FFFFFF;
        margin-top: 32px;
    }

    .about-inbrija .ddg-saving-left .ddg-saving-content h2 {

        font-weight: 700;
        font-size: 40px;
        line-height: 54px;
    }

    .about-inbrija .ddg-saving-left .ddg-saving-content p {
        margin-bottom: 0px;
        font-family: 'Arial';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;
        margin-top: 8px;
        color: #FFFFFF;
    }

    .about-inbrija .ddg-saving .ddg-saving-right {
        width: 100%;
    }

    .about-inbrija .ddg-saving button {
        background: #7C6991;
        border-radius: 100px;
        padding: 12px 24px;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        border: none;
        width: 100%;
        margin-top: 36px;
    }

    .about-inbrija .ddg-appointment .ddg-appointment-img img {
        max-width: 412px;
        width: 100%;
        margin-top: 57px;
    }

    .about-inbrija .ddg-appointment-form .steps-ddg .step1 h6 {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 0px;
        text-align: left;
        color: #4B3F67;
        margin-top: 66px;
    }

    .about-inbrija .ddg-appointment-form .steps-ddg .step1 h1 {
        font-style: normal;
        font-weight: 400;
        font-size: 36px;
        line-height: 42px;
        color: #7C6991;
        margin-bottom: 0px;
        margin-top: 16px;
        text-align: left;
    }

    .about-inbrija .ddg-appointment-form .steps-ddg .step1 p {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        text-align: left;
        margin-top: 8px;
        color: #7C6991;
    }

    .about-inbrija .ddg-appointment-form .steps-ddg .step1 .radio-buttons .radio {
        display: flex;
        justify-content: left;
        margin-bottom: 20px;
    }

    .about-inbrija .ddg-appointment-form .steps-ddg .step1 .radio-buttons {
        max-width: 554px;
        display: flex;
        justify-content: left;
        flex-direction: column;
        width: 100%;
        margin-top: 32px;
    }

    .about-inbrija .ddg-appointment-form .steps-ddg .step1 .options .activities-checkboxes {
        display: flex;
        justify-content: left;
        flex-direction: column;
        /* gap: 20px; */
        flex-wrap: wrap;
        padding-left: 0px;
        width: 100%;
        text-align-last: left;
        text-align: left;
    }

    .about-inbrija .ddg-appointment-form .steps-ddg .step1 .options label {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 19px;
        font-feature-settings: 'pnum' on, 'lnum' on;
        margin-left: 5px;
        text-align: left;
        color: #4E4E4E;
        max-width: 100%;
        width: calc(100% - 30px);
    }

    .about-inbrija .ddg-appointment-form .steps-ddg .step1 .options .activities-checkboxes .margin-left {
        padding-left: 0px;
    }

    .about-inbrija-ddg .surprised-symptom-modal .symptom-return-modal .activities-checkboxes {
        display: flex;
        flex-wrap: wrap;
        justify-content: left;
        gap: 0px;
        text-align: left;
        padding-left: 0px;
        max-width: 1019px;
        width: 100%;
        flex-direction: column;
        text-align: left;
    }

    .about-inbrija-ddg .surprised-symptom-modal .symptom-return-modal .activities-checkboxes .margin-left {
        padding-left: 0px;
    }

    .about-inbrija-ddg .thankyou-modal .thankyou-buttons {
        display: flex;
        gap: 20px;
        justify-content: center;
        flex-direction: column;
        margin-top: 15px;
        align-items: center;
    }

    .about-inbrija .thankyou-step input {
        background: #FFFFFF;
        width: 100%;
        margin-top: 31px;
        border: none;
        border-bottom: 1px solid #7C6991 !important;
        padding: 12px 12px 10px;
    }

    .about-inbrija .inbrija-anywhere-video-section .video-buttons {
        margin-top: 35px;
        max-width: 100%;
        gap: 24px;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 35px;
    }

    .about-inbrija-ddg .ddg-email-modal .content ul {
        flex-wrap: wrap;
    }

    .about-inbrija-ddg .ddg-email-modal .content li {
        width: 100%;
    }

    .about-inbrija-ddg .ddg-email-modal .content ul {
        padding-left: 40px;
        padding-right: 20px;
    }

    .about-inbrija-ddg .ddg-email-modal .content p {
        padding-left: 20px;
        padding-right: 20px;
    }

    .about-inbrija .cine-css {
        width: 100%;
        max-width: 780px;
    }
    .about-inbrija-ddg .thankyou-modal .thankyou-buttons button {
        width: 100%;
    }
    .about-inbrija-ddg .thankyou-modal a {
        width: 100%;
    }

}

@media (max-width:401px) {
    .about-inbrija .inbrija-anywhere-video-section .video-buttons {
        display: block;
    }

    .about-inbrija .inbrija-anywhere-video-section .video-buttons a {
        margin-bottom: 12px;
    }

    .about-inbrija .inbrija-anywhere-video-section .video-buttons a:last-child {
        margin-bottom: 0;
    }

    .about-inbrija .hero-section .hero-section-content h1 {
        font-size: 36px;
        line-height: 40px;
        padding: 0 20px;
    }

    .about-inbrija .hero-section .hero-section-content h1 span {
        line-height: 38px;
        font-size: 38px;
        display: inline;
        margin-top: 9px;
    }

    .about-inbrija .hero-section .hero-section-content h4 {
        font-size: 20px;
        line-height: 24px;
        padding: 0 20px 20px;
        gap: 10px;
    }

}

@media (max-width:576px) {
    .about-inbrija-ddg .surprised-symptom-modal .download-ddg-font {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.02em;
        color: #4B3F67;
        text-decoration: none !important;
    }

    .about-inbrija-ddg .surprised-symptom-modal .custom-ddg {
        margin-top: 38px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0.07em;
        text-transform: uppercase;
        color: #4B3F67;
    }

    .about-inbrija-ddg .surprised-symptom-modal .symptom-return-font {
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 32px;
        color: #7C6991;
        margin-top: 16px;
    }

    .about-inbrija-ddg .surprised-symptom-modal .radio-buttons {
        max-width: 554px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        margin-top: 32px;
    }

    .about-inbrija-ddg .thankyou-modal input {
        width: 100%;
    }

    .about-inbrija-ddg .surprised-symptom-modal .radio-buttons .radio {
        display: block;
        margin-bottom: 20px;
    }

    .about-inbrija-ddg .surprised-symptom-modal .mobile-back-next {
        margin-top: 38px;
    }

    .about-inbrija-ddg .surprised-symptom-modal .symptom-return-modal .symptoms {
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 32px;
        text-align: center;
        color: #7C6991;
    }

    .about-inbrija-ddg .surprised-symptom-modal .symptom-return-modal .check-symptom {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 23px;
        text-align: center;
        color: #7C6991;
    }

    .about-inbrija-ddg .surprised-symptom-modal .symptom-return-modal .checkboxes_inb {
        display: flex;
        justify-content: left;
        gap: 20px;
        flex-direction: column;
    }

    .about-inbrija .ddg-appointment-form .steps-ddg .step1 .options .checkboxes_inb {
        display: flex;
        justify-content: left;
        /* gap: 20px; */
        flex-direction: column;
        text-align-last: left;
    }

    .about-inbrija-ddg .surprised-symptom-modal .symptom-return-modal .checkmargin1 {
        margin-top: 12px;
    }

    .about-inbrija-ddg .surprised-symptom-modal .symptom-return-modal .checkmargin2 {
        margin-top: 0px;
        margin-bottom: 41px;
    }

    .about-inbrija-ddg .thankyou-modal .thankyou-buttons {
        display: block;
        gap: 0px;
        justify-content: center;
        margin-top: 0px;
        width: 100%;
    }

    .about-inbrija-ddg .thankyou-modal {
        padding-top: 16px;
        padding-bottom: 21px;
    }

    .about-inbrija-ddg .thankyou-modal h1 {
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 32px;
        text-align: center;
        margin-bottom: 0px;
        color: #7C6991;
    }

    .about-inbrija-ddg .thankyou-modal h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        font-feature-settings: 'pnum' on, 'lnum' on;
        margin-bottom: 0px;
        color: #7C6991;
        margin-top: 49px;
    }

    .about-inbrija-ddg .thankyou-modal p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 0px;
        text-align: center;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #4E4E4E;
        margin-top: 12px;
    }


    .about-inbrija-ddg .thankyou-modal .a-margin {
        margin-top: 33px !important;
    }

    .about-inbrija-ddg .thankyou-modal a {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #7C6991;
        

    }

    .about-inbrija-ddg .thankyou-modal .thankyou-buttons {
        display: block;
        gap: 0px;
        justify-content: center;
        margin-top: 0px;
    }

    .about-inbrija-ddg .thankyou-modal .thankyou-buttons button {
        background: #7c6991;
        border-radius: 100px;
        padding: 12px 24px;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        width: 100%;
        line-height: 16px;
        text-decoration: none !important;
        text-align: initial;
        display: inline-flex;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        text-transform: none !important;
        gap: 8px;

        /* background: ;
    border-radius: 100px;
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -.02em;
    line-height: 16px;
    padding: 12px 24px; */
    }

    .about-inbrija-ddg .ddg-email-modal {
        padding: 84px 15px 22px 15px;
    }

    .about-inbrija-ddg .ddg-email-modal h1 {
        font-style: normal;
        font-weight: 400;
        font-size: 36px;
        line-height: 110%;
        text-align: center;
        margin-bottom: 0px;
        color: #7C6991;
    }

    .about-inbrija-ddg .ddg-email-modal p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        font-feature-settings: 'pnum' on, 'lnum' on;
        margin-top: 15px;
        margin-bottom: 0px;
        color: #4E4E4E;
    }

    .about-inbrija-ddg .ddg-email-modal .email-buttons {
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
        padding: 0px;
    }

    .about-inbrija-ddg .inbox-modal {
        padding-top: 40px;
        padding-bottom: 16px;
    }

    .about-inbrija-ddg .inbox-modal h1 {
        font-style: normal;
        font-weight: 400;
        font-size: 36px;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        margin-bottom: 0px;
        color: #7C6991;
    }

    .about-inbrija-ddg .inbox-modal p {
        font-style: normal;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #4E4E4E;
        margin-top: 15px;
    }


    .about-inbrija .inbox-modal svg {
        margin-right: 8px;
    }

    .about-inbrija-ddg .inbox-modal button {
        background: #7C6991;;
        border-radius: 100px;
        padding: 20px 24px;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.02em;
        border: none;
        color: #FFFFFF;
        margin-top: 27px;
    }

    .about-inbrija-ddg .inbox-modal .a-padding {
        margin-top: 30px;
        text-align: center;
    }

    .about-inbrija .ddg-appointment-form .steps-ddg .step1 .options .activities-checkboxes {
        display: flex;
        justify-content: left;
        flex-direction: column;
        gap: 20px;
        padding-left: 0px;
        width: 100%;
        text-align-last: left;
    }
    .about-inbrija .thankyou-step .thankyou-buttons {
        gap: 20px;
        flex-wrap: wrap;
    }
    .about-inbrija .thankyou-step button {
        width: 100%;
    }
    .about-inbrija .thankyou-step a {
        width: 100%;
    }
    .about-inbrija .thankyou-step .thankyou-buttons .about_dynDDG {
        margin-left: 0;
    }
}

@media (max-width:400px) {
    .about-inbrija .inbrija-anywhere-video-section .video-buttons button {
        display: flex;
        padding: 12px 24px;
        background: #4B3F67;
        border-radius: 100px;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.02em;
        border: none;
        color: #FFFFFF !important;
        text-decoration: none !important;
        width: 100%;
        justify-content: center;
    }

    .about-inbrija .ddg-appointment-form .steps-ddg .step1 .options .activities-checkboxes {
        display: flex;
        justify-content: left;
        flex-direction: column;
        gap: 20px;
        flex-wrap: wrap;
        padding-left: 0px;
        width: 100%;
        text-align-last: left;
        text-align: left;
    }

    .about-inbrija .ddg-appointment-form .steps-ddg .step1 .options label {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 19px;
        font-feature-settings: 'pnum' on, 'lnum' on;
        margin-left: 5px;
        text-align: left;
        color: #4E4E4E;
        max-width: 100%;
        width: calc(100% - 30px);
    }
}

@media (min-width:1310px) {
    .about-inbrija .inbrija-anywhere-section .inbrija-anywhere-section-container-right {
        visibility: hidden;
    }

    .about-inbrija .inbrija-anywhere-section:before {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        background: url('/public/assets/images/boxer_background.webp') no-repeat right center;
        background-size: contain;
    }

    .about-inbrija .inbrija-anywhere-section {
        position: relative;
    }
}