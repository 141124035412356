.header-container {
  background: linear-gradient(270.01deg, #15273C 0%, #12151F 100.34%);
  color: white;
  text-align: -webkit-center;
}

.header-width {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 0px;
  padding-right: 0px;
  gap: 169px;
  padding-top: 13px;
  padding-bottom: 13px;
  text-align: center;
  max-width: 1312px;
  width: 100%;
  margin: auto;
  gap: 8px;
}

.header-menu-width {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  text-align: center;
  width: 100%;
  max-width: 1344px;
  left: 4.44%;
  gap: 52px;
  margin: auto;
}

.p-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.header-menu-container {
  background: linear-gradient(147.08deg, #090913 0%, #1B222A 100%);
  color: white;
  text-align: -webkit-center;
}

.residence-text {
  font-family: 'Arial';
  font-style: italic;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.02em;
  color: #FFFFFF;

  text-align: justify;
}

.flex {
  display: flex;
  justify-content: flex-end;
}

.website-links {
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: -0.02em;
  text-decoration-line: underline;
  text-decoration-skip-ink: none !important;
  color: #FFFFFF;
  display: block;
}

.website-links:hover {
  color: #BFB5C8;
}

.website-links-mobile {
  background: none;
  border: none;
  color: #FFFFFF;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: -0.02em;
  text-decoration-line: underline;
  text-decoration-skip-ink: none !important;
}

.website-links-mobile:hover {
  color: #BFB5C8 !important;
}

.mobile-menu {
  position: absolute;
  background: linear-gradient(147.08deg, #090913 0%, #1B222A 100%);
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1002;
  padding: 20px;
}

.mobile-menu p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: #BFB5C8;
  text-align: left;
}

.mobile-menu li {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: #BFB5C8;
  text-align: left;
  list-style: none;
}

.mobile-menu a {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: #BFB5C8;
  text-align: left;
  list-style: none;
  text-decoration: none;

}

.mt-015 {
  margin-top: 15px;
}

.updates-margin {
  margin-top: 71px;
}

.mobile-saperator {
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin-top: 15px;
  margin-bottom: 15px;
}

.mobile-update-saperator {
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin-top: 71px;
  margin-bottom: 15px;
}

.close-container {
  width: 52px;
  height: 52px;
  background: rgba(40, 50, 63, 0.4);
  border-radius: 50%;
}

.close-modal {
  width: 40px;
  height: 40px;
  background: #000;
  border-radius: 50%;
}

.off-period-margin {
  margin-left: -10%;
}

.about-inbrija-menu-margin {
  margin-left: -30%;
}

.use-inbrija-margin {
  margin-left: -13%;
}

.saving-support-margin {
  margin-left: -18%;
}

.resources-margin {
  margin-left: -40%;
}


.close-modal-left {
  width: 15px;
  position: absolute;
  height: 0px;
  margin-top: 19px;
  margin-left: 12px;
  text-align: center;
  border: 1px solid #ffffff;
  transform: rotate(-43.93deg);
  border-radius: 5px;
}

.left {
  width: 26.24px;
  position: absolute;
  height: 0px;
  margin-top: 24px;
  margin-left: 13px;
  text-align: center;
  border: 1.5px solid #ffffff;
  transform: rotate(-43.93deg);
  background: #fff;
  border-radius: 10px;
}


.close-modal-right {
  position: absolute;
  width: 15px;
  height: 0px;
  margin-top: 19px;
  margin-left: 12px;
  border: 1px solid #ffffff;
  transform: matrix(0.72, 0.69, 0.69, -0.72, 0, 0);
  border-radius: 5px;
}

.right {
  position: absolute;
  width: 26.24px;
  height: 0px;
  margin-top: 24px;
  margin-left: 13px;
  border: 1.5px solid #ffffff;
  transform: matrix(0.72, 0.69, 0.69, -0.72, 0, 0);
  background: #fff;
  border-radius: 10px;
}

.sign-up-mobile {
  width: 213px;
  height: 56px;
  left: 112px;
  bottom: 56px;
  background: rgba(40, 50, 63, 0.4);
  border-radius: 100px;
  border: none;
  color: #fff;
}

.saperator {
  margin-left: 16px;
  margin-right: 16px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #627189;
  display: block;
}

.website-links-noborder {
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: -0.02em;
  display: block;
  color: #fff !important;
}

.website-links-mobile-noborder {
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: -0.02em;
  padding-top: 12.5px;
  padding-bottom: 12.5px;
  display: block;
}

.text-decortion {
  text-decoration-line: underline;
  font-weight: 400;
  color: #fff !important;
  text-decoration-skip-ink: none !important;
}

.text-decortion-ques {
  text-decoration-line: underline;
  font-weight: 400;
  color: #fff !important;
  text-decoration-skip-ink: none !important;
}

.text-decortion-ques:hover {
  color: #BFB5C8 !important;
}


.question {
  color: #A1B3C8;
  text-decoration-line: none;
}

.logo {
  width: 190px;
  cursor: pointer;
  height: 90.55px;
  margin-top: 33px;
}

.row-margin {
  margin-left: 0px;
  margin-right: 0px;
}

.text-left {
  text-align: left;
}

.menu-section {
  text-align: left;
  display: block;
}

.collapse-menu {
  display: none;
}

.menu {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: #BFB5C8;
  margin-top: 56px;
  margin-right: 36px;
  cursor: pointer;
  width: max-content;
  position: relative;
  text-decoration: none !important;
}

.menu:hover {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: #fff;
  margin-top: 56px;
  margin-right: 36px;
}

.menu:after {
  position: absolute;
  bottom: -4px;
  width: 100%;
  background: url('/public/assets/images/active-menu-image.webp') no-repeat center center;
  content: '';
  height: 4px;
  background-size: 100%;
  left: 0;
  display: none
}

.flex-start {
  display: flex;
  justify-content: flex-start;
}

.menu-text-decoration {
  text-decoration: none !important;
}

.drop-close {
  max-width: 12px;
  width: 100%;
}

.sign-up-button {
  color: #fff;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #FFFFFF;
  border: none;
  background: rgba(40, 50, 63, 0.4);
  border-radius: 100px;
  width: 125px;
  padding: 0px !important;
  height: 51px;
  margin-top: 40px;

}

.sign-up-button:hover {
  color: #fff;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #FFFFFF;
  border: none;
  background: #28323F;
  border-radius: 100px;
  width: 125px;
  height: 51px;
  margin-top: 40px;
}

.dropbtn {
  background-color: #04AA6D;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}


.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background: #1B212B;
  min-width: 215px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 10px;
  margin-top: 10px;
}

.dropdown-content .open {
  display: block !important;
  background: #1B212B;
  min-width: 215px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 10px;
  margin-top: 10px;
}

.dropdown-content a {
  color: #BFB5C8;
  padding: 17.5px 12px;
  text-decoration: none;
  display: block;
  text-align: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: -0.02em;
}

.dropdown-content a:hover {
  color: #FFF;
}

.dropdown-content a:last-child {
  border-bottom: none;
}

.dropdown-content a:hover {
  color: #EAE7F1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:focus .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}

.dropdown:hover img {
  transform: rotate(180deg);
}

.dropdown-menu {
  display: none;
  background: #1B212B !important;
  min-width: 215px !important;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2) !important;
  z-index: 10 !important;
  border-radius: 10px !important;
  margin-top: 0px !important;

}

.dropdown-menu.open {
  display: block;
  background: #1B212B !important;
  min-width: 215px !important;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2) !important;
  z-index: 1 !important;
  border-radius: 10px !important;
  margin-top: 10px !important;
}

.dropdown-menu li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.dropdown-menu li:last-child {
  border-bottom: none !important;
}


.hero-container {
  align-self: center;
  display: flex;
  justify-content: center;
}

.right-section {
  max-width: 431px;
  align-self: end;
  text-align: right;
  width: 100%;
}

.actor-potryal {
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  text-align: right;
  color: #fff;
  margin-top: -50px;
}

.text-highlight {
  color: #FFB423;
}


@media (min-width:768px) and (max-width:992px) {
  .hero-section {
    max-width: 720px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0px;
    gap: 32px;
    background-repeat: no-repeat;
    background-position: right;
  }

  .left-section {
    width: 100%;
    height: auto;
    margin-right: calc(100% - 600px -31px);
  }

  .right-section {
    width: 100%;
    height: auto;
    align-self: end;
    text-align: right;
  }
}

@media (max-width:576px) {
  .right-section {
    width: 100%;
    height: 80;
    background-repeat: no-repeat;
    background-position: inherit;
    margin-top: -80px;
  }

  .actor-potryal {
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    text-align: right;
    color: #fff;
  }
}

@media (min-width:992px) and (max-width:1200px) {
  .hero-section {
    max-width: 960px;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px;
    gap: 32px;
    background-repeat: no-repeat;
    background-position: right bottom;
  }

  .left-section {
    width: 100%;
    height: auto;
    margin-right: calc(100% - 600px -31px);
  }

  .right-section {
    width: 100%;
    height: auto;
  }
}


@media (min-width:1200px) {
  .hero-section {
    max-width: 1500px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px;
    gap: 0px;
    background-repeat: no-repeat;
    background-position: right;
  }

  .right-section {
    width: 100%;
    height: auto;
  }

  .left-section {
    max-width: 596px;
    width: 100%;
    margin-right: calc(100% - 600px -31px);
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation : portrait) {
  .collapse-menu {
    display: none;
  }
}

@media screen and (max-width:1024px) {
  .menu-section {
    display: none;
  }

  .collapse-menu {
    display: block;
    text-align: end;
  }

  .saperator {
    display: none;
  }

  .website-links {
    display: none;
  }

  .website-links-noborder {
    display: none;
  }
}

@media screen and (max-width:1400px) {
  .header-width {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    text-align: center;
    width: 100%;
    padding-top: 13px;
    padding-bottom: 13px;
    padding-left: 20px;
    padding-right: 20px;
    gap: 0px;
  }

  .header-menu-width {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    text-align: center;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }

}

@media screen and (max-width:1200px) and (min-width:1024px) {
  .saperator {
    margin-left: 6px;
    margin-right: 6px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: #627189;
    display: block;
  }

  .header-menu-width {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    text-align: center;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (max-width:1400px) and (min-width:1025px) {
  .logo {
    width: 120px;
    height: auto;
  }
}

@media (max-width:1200px) and (min-width:1023px) {
  .header-menu-width {
    gap: 10px;
  }
}

@media screen and (max-width:1400px) and (min-width: 1024px) {
  .menu {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: #BFB5C8;
    margin-top: 56px;
    margin-right: 25px;
    cursor: pointer;
  }

  .menu:hover {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 19px;
    letter-spacing: -0.02em;
    margin-top: 56px;
    margin-right: 25px;
    cursor: pointer;
    color: #fff;
  }

  .sign-up-button {
    color: #fff;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    border: none;
    background: rgba(40, 50, 63, 0.4);
    border-radius: 100px;
    width: 100px;
    height: 51px;
    margin-top: 40px;
  }
}

@media screen and (max-width:1200px) and (min-width: 1024px) {
  .menu {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: #BFB5C8;
    margin-top: 56px;
    margin-right: 18px;
    cursor: pointer;
  }

  .menu:hover {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: #BFB5C8;
    margin-top: 56px;
    margin-right: 18px;
    cursor: pointer;
    color: #fff;
  }

  .sign-up-button {
    color: #fff;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    border: none;
    background: rgba(40, 50, 63, 0.4);
    border-radius: 100px;
    width: 80px;
    height: 51px;
    margin-top: 40px;
  }
}

@media (max-width:768px) {

  .left-section {
    width: 100%;
    height: auto;
    margin-right: calc(100% - 600px -31px);
  }

  .right-section {
    width: 100%;
  }

  .actor-potryal {
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    text-align: right;
    color: #fff;
  }

  .sign-up-mobile {
    font-weight: 700;
  }

  .mobile-menu img {
    margin-left: 5px;
  }

  .logo {
    margin-top: 20px;
  }

  .collapse-menu {
    padding-right: 10px;
  }

  .close-container {
    position: relative;
    right: -10px;
  }

  .mobile-menu .mobile-saperator {
    margin-top: 0 !important;
  }
}

.hidden {
  height: 1px !important;
  width: 1px !important;
  border: none !important;
  overflow: hidden !important;
  padding: 0 !important;
  margin: -1px !important;
  white-space: nowrap !important;
}

#onetrust-close-btn-container {
  display: none;
}